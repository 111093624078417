import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { fixPathSlash } from "../../../../../app/tools"
import styles from "./taskcard.module.scss"
import styled from "styled-components"
import DeleteConfirmation from "./DeleteConfirmation"
import { deleteAccess, getAccess } from "../../../../../app/store/feedSlice"
import { useDispatch } from "react-redux"
import { FeedTask } from "../../../../../app/interfaces/feed"
import { timeFromNow } from "../../../../../app/tools/task"
import MiniChart from "./MiniChart"

interface TaskCardProp {
    task: FeedTask
}

const TaskTypeLabel = styled.span<{ taskType: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #516380;
    text-align: center;
    height: 1.4rem;
    font-size: 0.8rem;
    font-weight: 400;
    border-radius: 3px;
    margin-left: 0.7rem;
    padding: 0.05rem 0.6rem;
    background-color: ${props => props.taskType === 1 ? "#FFD9DF" : "#FEEEC6"};
`

const TaskCard = ({ task }: TaskCardProp) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const path = fixPathSlash(history.location.pathname)

    const [showMenu, setShowMenu] = useState(false)
    const [showWarning, setShowWarning] = React.useState(false)

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!showMenu) {
            document.addEventListener('click', closeMenu)
        }
        setShowMenu(true)
        event.stopPropagation()
    }

    const closeMenu = () => {
        setShowMenu(false)
        document.removeEventListener('click', closeMenu)
    }

    const deleteFeed = () => {
        dispatch(deleteAccess(task.task_id))
        setTimeout(()=>{
            dispatch(getAccess())
        }, 500)
        setShowWarning(false)
    }

    const selectTask = () => {
        history.push(`${path}/${task.task_id}`)
    }

    const handleDeleteFeedClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setShowWarning(true)
        event.stopPropagation()
    }

    return (
        <div className={styles.taskCardGridContainer} onClick={selectTask}>
            <div className={styles.gridItem} >
                <div>
                    <span>{task.task_name} </span>
                    <TaskTypeLabel taskType={task.task_type}>
                        {task.task_type === 1 ? "Brand" : "KOL"}
                    </TaskTypeLabel>
                </div>
                <div>
                    {task.task_type === 0 && (task.line ? (
                        <>
                            <p><span>{task.line.points[0].count}</span> threads from last 24 hrs</p>
                            <p>
                                <span className={task.line.delta > 0 ? styles.higher : styles.lower}>
                                    <i className={`fas fa-long-arrow-alt-${task.line.delta > 0 ? "up" : "down"}`} />
                                    <span className={styles.percentageNumber}>{Math.abs(task.line.delta)}%</span>
                                </span>
                                <span> {task.line.delta > 0 ? "higher" : "lower"} than average</span>
                            </p>
                        </>
                    ) : <p>No data available yet</p>
                    )}

                    {task.task_type === 1 && (task.line ? (
                        <p><span>{task.total_threads}</span> total threads</p>
                    ) : <p>No data available yet</p>
                    )}
                     {task.task_type === 2 && (task.line ? (
                        <p><span>{task.total_threads}</span> total threads</p>
                    ) : <p>No data available yet</p>
                    )}
                </div>
            </div>
            <div className={styles.gridItem}>
                {
                    task.task_type === 0 && task.line && task.line.points && task.line.points.length > 1 && (
                        <>
                            <MiniChart data={task.line.points} />
                            <p>Last 7 days</p>
                        </>
                    )
                }
            </div>
            <div className={styles.gridItem}>
                <p>{task.last_edit_time ? timeFromNow(new Date(task.last_edit_time)) : "-"}</p>
            </div>
            <div className={styles.gridItem}>
                <p>{task.last_edit_user}</p>
            </div>

            <div className={styles.gridItem}>
                <div className={styles.dropdownContainer} >
                    <div className={styles.dropdownButton} onClick={handleClick}>
                        <i className={"fas fa-ellipsis-v " + styles.dropdownButtonIcon} />
                    </div>
                    {showMenu && (
                        <div
                            className={styles.dropdownMenu}
                        >
                            <div className={styles.dropdownItem} onClick={handleDeleteFeedClick}>
                                <div className={styles.dropdownItemContent}>
                                    <i className={"far fa-trash-alt " + styles.dropdownItemContentIcon} />
                                    <p>Delete brand</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <DeleteConfirmation show={showWarning} cancel={() => { setShowWarning(false) }} deleteFeed={deleteFeed} />
        </div>
    )
}

export default TaskCard
