import { Industry, Language } from "../interfaces/misc"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { store, RootState } from "."
import uf from "usefuljs"
import { removeUser } from "./userSlice"

const industryData = [
    {
        "id": 1,
        "industry": "Accounting"
    },
    {
        "id": 127,
        "industry": "Airlines"
    },
    {
        "id": 2,
        "industry": "Alternative Medicine"
    },
    {
        "id": 3,
        "industry": "Animation"
    },
    {
        "id": 4,
        "industry": "Apparel and Fashion"
    },
    {
        "id": 5,
        "industry": "Architecture and Planning"
    },
    {
        "id": 6,
        "industry": "Arts and Crafts"
    },
    {
        "id": 128,
        "industry": "Audio Media"
    },
    {
        "id": 7,
        "industry": "Automotive"
    },
    {
        "id": 8,
        "industry": "Aviation and Aerospace"
    },
    {
        "id": 129,
        "industry": "Banking"
    },
    {
        "id": 9,
        "industry": "Biotechnology"
    },
    {
        "id": 10,
        "industry": "Broadcasting"
    },
    {
        "id": 11,
        "industry": "Building Materials"
    },
    {
        "id": 12,
        "industry": "Business Supplies and Equipment"
    },
    {
        "id": 13,
        "industry": "Capital Markets"
    },
    {
        "id": 14,
        "industry": "Chemicals"
    },
    {
        "id": 15,
        "industry": "Civic and Social Organization"
    },
    {
        "id": 16,
        "industry": "Civil Engineering"
    },
    {
        "id": 130,
        "industry": "Cloud Services"
    },
    {
        "id": 17,
        "industry": "Commercial Real Estate"
    },
    {
        "id": 131,
        "industry": "Computer and Network Security"
    },
    {
        "id": 18,
        "industry": "Computer Hardware"
    },
    {
        "id": 19,
        "industry": "Computer Networking"
    },
    {
        "id": 132,
        "industry": "Computer Software"
    },
    {
        "id": 20,
        "industry": "Construction"
    },
    {
        "id": 133,
        "industry": "Consumer Electronics"
    },
    {
        "id": 134,
        "industry": "Consumer Goods"
    },
    {
        "id": 21,
        "industry": "Consumer Services"
    },
    {
        "id": 22,
        "industry": "Cosmetics and Skincare"
    },
    {
        "id": 23,
        "industry": "Dairy"
    },
    {
        "id": 24,
        "industry": "Defense and Space"
    },
    {
        "id": 25,
        "industry": "Design"
    },
    {
        "id": 26,
        "industry": "Digital and Online Media"
    },
    {
        "id": 135,
        "industry": "E-Commerce"
    },
    {
        "id": 27,
        "industry": "Education Management"
    },
    {
        "id": 28,
        "industry": "E-Learning"
    },
    {
        "id": 29,
        "industry": "Electrical/Electronic Manufacturing"
    },
    {
        "id": 30,
        "industry": "Entertainment"
    },
    {
        "id": 31,
        "industry": "Environmental Services"
    },
    {
        "id": 136,
        "industry": "E-Payment"
    },
    {
        "id": 32,
        "industry": "Events Services"
    },
    {
        "id": 33,
        "industry": "Executive Office"
    },
    {
        "id": 34,
        "industry": "Facility Services"
    },
    {
        "id": 35,
        "industry": "Farming"
    },
    {
        "id": 36,
        "industry": "Fine Art"
    },
    {
        "id": 37,
        "industry": "Fishery"
    },
    {
        "id": 38,
        "industry": "Food and Beverages"
    },
    {
        "id": 39,
        "industry": "Food Production"
    },
    {
        "id": 40,
        "industry": "Footwear"
    },
    {
        "id": 41,
        "industry": "Fund-Raising"
    },
    {
        "id": 42,
        "industry": "Furniture"
    },
    {
        "id": 43,
        "industry": "Gambling and Casinos"
    },
    {
        "id": 44,
        "industry": "Glass, Ceramics and Concrete"
    },
    {
        "id": 45,
        "industry": "Government Administration"
    },
    {
        "id": 46,
        "industry": "Government Relations"
    },
    {
        "id": 47,
        "industry": "Graphic Design"
    },
    {
        "id": 48,
        "industry": "Health, Wellness and Fitness"
    },
    {
        "id": 49,
        "industry": "Higher Education"
    },
    {
        "id": 50,
        "industry": "Hospital and Health Care"
    },
    {
        "id": 137,
        "industry": "Hospitality"
    },
    {
        "id": 138,
        "industry": "Hotel Network and Hospitality Services"
    },
    {
        "id": 51,
        "industry": "Human Resources"
    },
    {
        "id": 52,
        "industry": "Import and Export"
    },
    {
        "id": 53,
        "industry": "Individual and Family Services"
    },
    {
        "id": 54,
        "industry": "Industrial Automation"
    },
    {
        "id": 55,
        "industry": "Information Services"
    },
    {
        "id": 139,
        "industry": "Information Technology"
    },
    {
        "id": 140,
        "industry": "Insurance"
    },
    {
        "id": 56,
        "industry": "International Affairs"
    },
    {
        "id": 57,
        "industry": "International Trade and Development"
    },
    {
        "id": 58,
        "industry": "Investment Banking"
    },
    {
        "id": 141,
        "industry": "Investment Management"
    },
    {
        "id": 59,
        "industry": "Judiciary"
    },
    {
        "id": 60,
        "industry": "Law Enforcement"
    },
    {
        "id": 61,
        "industry": "Law Practice"
    },
    {
        "id": 62,
        "industry": "Legal Services"
    },
    {
        "id": 63,
        "industry": "Legislative Office"
    },
    {
        "id": 64,
        "industry": "Leisure, Travel and Tourism"
    },
    {
        "id": 65,
        "industry": "Libraries"
    },
    {
        "id": 142,
        "industry": "Logistics and Supply Chain"
    },
    {
        "id": 66,
        "industry": "Luxury Goods and Jewelry"
    },
    {
        "id": 67,
        "industry": "Machinery"
    },
    {
        "id": 68,
        "industry": "Management Consulting"
    },
    {
        "id": 69,
        "industry": "Maritime"
    },
    {
        "id": 71,
        "industry": "Marketing and Advertising"
    },
    {
        "id": 70,
        "industry": "Market Research"
    },
    {
        "id": 72,
        "industry": "Mechanical or Industrial Engineering"
    },
    {
        "id": 73,
        "industry": "Media Production"
    },
    {
        "id": 74,
        "industry": "Medical Devices"
    },
    {
        "id": 75,
        "industry": "Medical Practice"
    },
    {
        "id": 76,
        "industry": "Mental Health Care"
    },
    {
        "id": 77,
        "industry": "Military"
    },
    {
        "id": 78,
        "industry": "Mining and Metals"
    },
    {
        "id": 79,
        "industry": "Motion Pictures and Film"
    },
    {
        "id": 80,
        "industry": "Museums and Institutions"
    },
    {
        "id": 81,
        "industry": "Music"
    },
    {
        "id": 82,
        "industry": "Nanotechnology"
    },
    {
        "id": 83,
        "industry": "Newspapers"
    },
    {
        "id": 84,
        "industry": "Non-Profit Organization Management"
    },
    {
        "id": 85,
        "industry": "Oil and Energy"
    },
    {
        "id": 143,
        "industry": "Other Financial Services"
    },
    {
        "id": 86,
        "industry": "Other Internet Service"
    },
    {
        "id": 87,
        "industry": "Outsourcing/Offshoring"
    },
    {
        "id": 88,
        "industry": "Package/Freight Delivery"
    },
    {
        "id": 89,
        "industry": "Packaging and Containers"
    },
    {
        "id": 90,
        "industry": "Paper and Forest Products"
    },
    {
        "id": 91,
        "industry": "Performing Arts"
    },
    {
        "id": 92,
        "industry": "Pharmaceuticals"
    },
    {
        "id": 93,
        "industry": "Philanthropy"
    },
    {
        "id": 94,
        "industry": "Photography"
    },
    {
        "id": 95,
        "industry": "Plastics"
    },
    {
        "id": 96,
        "industry": "Political Organization"
    },
    {
        "id": 97,
        "industry": "Primary/Secondary Education"
    },
    {
        "id": 144,
        "industry": "Printing"
    },
    {
        "id": 98,
        "industry": "Professional Training and Coaching"
    },
    {
        "id": 99,
        "industry": "Program Development"
    },
    {
        "id": 100,
        "industry": "Public Policy"
    },
    {
        "id": 101,
        "industry": "Public Relations and Communications"
    },
    {
        "id": 102,
        "industry": "Public Safety"
    },
    {
        "id": 145,
        "industry": "Publishing"
    },
    {
        "id": 103,
        "industry": "Railroad Manufacture"
    },
    {
        "id": 104,
        "industry": "Ranching"
    },
    {
        "id": 105,
        "industry": "Real Estate"
    },
    {
        "id": 106,
        "industry": "Recreational Facilities and Services"
    },
    {
        "id": 107,
        "industry": "Religious Institutions"
    },
    {
        "id": 108,
        "industry": "Renewables and Environment"
    },
    {
        "id": 109,
        "industry": "Research"
    },
    {
        "id": 146,
        "industry": "Restaurants"
    },
    {
        "id": 110,
        "industry": "Retail"
    },
    {
        "id": 111,
        "industry": "Security and Investigations"
    },
    {
        "id": 112,
        "industry": "Semiconductors"
    },
    {
        "id": 113,
        "industry": "Shipbuilding"
    },
    {
        "id": 147,
        "industry": "Social Networks"
    },
    {
        "id": 148,
        "industry": "Sporting Goods"
    },
    {
        "id": 114,
        "industry": "Sports"
    },
    {
        "id": 115,
        "industry": "Staffing and Recruiting"
    },
    {
        "id": 149,
        "industry": "Supermarkets"
    },
    {
        "id": 150,
        "industry": "Telecommunications"
    },
    {
        "id": 116,
        "industry": "Textiles"
    },
    {
        "id": 117,
        "industry": "Think Tanks"
    },
    {
        "id": 118,
        "industry": "Tobacco"
    },
    {
        "id": 119,
        "industry": "Translation and Localization"
    },
    {
        "id": 151,
        "industry": "Transportation Netwrok"
    },
    {
        "id": 152,
        "industry": "Transportation/Railroad"
    },
    {
        "id": 120,
        "industry": "Utilities"
    },
    {
        "id": 121,
        "industry": "Venture Capital and Private Equity"
    },
    {
        "id": 122,
        "industry": "Veterinary"
    },
    {
        "id": 153,
        "industry": "Video Games"
    },
    {
        "id": 154,
        "industry": "Video Media"
    },
    {
        "id": 123,
        "industry": "Warehousing"
    },
    {
        "id": 155,
        "industry": "Web Services"
    },
    {
        "id": 124,
        "industry": "Wholesale"
    },
    {
        "id": 125,
        "industry": "Wine and Spirits"
    },
    {
        "id": 156,
        "industry": "Wireless and Internet Services"
    },
    {
        "id": 126,
        "industry": "Writing and Editing"
    }
]

const mediumsData = [
    "MEDIA",
    "FORUM",
    "BLOG",
]

const languageData = [
    {
        "lang_abbr": "en",
        "name": "English",
        "region": "Australia",
        "iso": "au"
    },
    {
        "lang_abbr": "en",
        "name": "English",
        "region": "UK",
        "iso": "gb"
    },
    {
        "lang_abbr": "en",
        "name": "English",
        "region": "Hong Kong",
        "iso": "hk"
    },
    {
        "lang_abbr": "en",
        "name": "English",
        "region": "USA",
        "iso": "us"
    },
    {
        "lang_abbr": "es",
        "name": "Spanish",
        "region": "Mexico",
        "iso": "mx"
    },
    {
        "lang_abbr": "pt",
        "name": "Portuguese",
        "region": "Portugal",
        "iso": "pt"
    },
    {
        "lang_abbr": "zh-s",
        "name": "Simplified Chinese",
        "region": "China",
        "iso": "cn"
    },
    {
        "lang_abbr": "zh-t",
        "name": "Traditional Chinese",
        "region": "Hong Kong",
        "iso": "hk"
    },
    {
        "lang_abbr": "zh-t",
        "name": "Traditional Chinese",
        "region": "Taiwan",
        "iso": "tw"
    }
]

const sitesData = [
    "01ICON",
    "141HongKong",
    "881903",
    "AAstocks",
    "Allin Media",
    "am730",
    "Anue",
    "AP News",
    "AppleDaily",
    "Asia Asset",
    "Asia Times",
    "BabyDiscuss",
    "BabyKingdom",
    "Bastille",
    "Bauhinia",
    "BBC",
    "BeautyExchange",
    "Big Fun Trip",
    "Blockchain",
    "Blog City",
    "BlogSpot",
    "Bloomberg",
    "BossMind",
    "BusinessFocus",
    "Capital",
    "CCTV",
    "CGTN",
    "Channel C HK",
    "China Daily HK",
    "China News",
    "China News Service",
    "China Times",
    "Citizen News",
    "CNBC",
    "CNN",
    "Cosmopolitan HK",
    "Crntt",
    "CUP",
    "DCFever",
    "Dimsum Daily",
    "DoctorDaddySoccer",
    "DotDotNews",
    "East Touch",
    "East Week",
    "EDigest",
    "EduKingdom",
    "eElderly",
    "Ejinsight",
    "ELLE HK",
    "Epoch Times",
    "Epoch Times HK",
    "Epoch Weekly",
    "EPrice",
    "Esquire HK",
    "ET Net",
    "Exmoo",
    "e-zone",
    "Facebook Group",
    "Facebook Page",
    "FactWire",
    "FanPiece",
    "Fashion Network",
    "FeverSound",
    "FinanceAsia",
    "Finet",
    "FinTV",
    "Fitz",
    "Flyagain",
    "Flyday",
    "Forbes",
    "Fortune Insight",
    "Futubull",
    "Galden",
    "GCMT",
    "GeoExpat",
    "GirlStyle",
    "Global Times",
    "GOGOAdvise",
    "gold899",
    "Golden",
    "GOtrip",
    "Guandian",
    "Harper's Bazaar HK",
    "Headline",
    "Hittt",
    "Hive Life",
    "HK01",
    "HKBT",
    "HK Business",
    "HKCD",
    "HK Citizen Media",
    "HKCNA",
    "HKD4",
    "HKDiscuss",
    "HKEJ",
    "HKEPC",
    "HKEPC Forum",
    "hket",
    "HKFP",
    "HKG Pao",
    "HKitalk",
    "HK-Kicks",
    "HK Metas",
    "HK News",
    "HKRU",
    "HKSilicon",
    "HKTKWW",
    "HolidaySmart",
    "Home Journal",
    "Homemory",
    "HongKongCard",
    "Hong Kong Living",
    "Huanqiu",
    "Hypebeast",
    "i-Cable",
    "iMoney",
    "Initium Media",
    "InMediaHK",
    "Instagram",
    "Investing.com",
    "JDonline",
    "JET",
    "Jetso Today",
    "Kin Liu",
    "KKnews",
    "Kongsolo",
    "KRT",
    "Kwun Media",
    "Liberty Times",
    "Lifenews HK",
    "Lifestyle",
    "LifeStyle Journal",
    "LIHKG",
    "LineToday",
    "Lion Rock Daily",
    "MadameFigaro",
    "MamiDaily",
    "Marie Claire",
    "Marketing Interactive",
    "Master Insight",
    "Media OutReach",
    "MeetHK",
    "MemeHK",
    "MenClub",
    "Men’s Uno",
    "Metro Daily",
    "Metro Education",
    "Metro Health",
    "Metro Hit Picks",
    "Metro Immigration",
    "Metro Insurance",
    "Metro Pop",
    "Metroradio",
    "Milk",
    "MingPao",
    "MingPao OL",
    "Modaily App",
    "Modia",
    "Money18",
    "Money Smart",
    "More",
    "Morningstar HK",
    "MP Finance",
    "MP Happypama",
    "MP JUMP",
    "MP Life",
    "MP Power Up",
    "MP Weekly",
    "Mr Miles",
    "MSN",
    "myTV SUPER",
    "NewMobileLife",
    "News GovHK",
    "Nikkei Asia",
    "NMplus",
    "Now Finance",
    "NOWnews",
    "NowTV News",
    "NY Times",
    "Ohpama",
    "Oncc",
    "OpenHolidays",
    "Orange News",
    "Oriental Daily",
    "Oriental Sunday",
    "PassionTimes",
    "PC3",
    "PCM",
    "People.cn",
    "POPBEE",
    "POPNews",
    "Post852",
    "Press Foto Plus",
    "Prestige",
    "PRN Asia",
    "Property Station (hket)",
    "Qooah",
    "Quamnet",
    "Reuters",
    "review33",
    "RFA",
    "RFI",
    "RTHK",
    "Running Biji",
    "San Po Yan",
    "SAUCE",
    "SCMP",
    "SHE",
    "SHE.com",
    "Silent Majority",
    "Sina",
    "Sina HK",
    "SingTao",
    "SkyPost",
    "Speak Out",
    "Sports Road",
    "Standard",
    "StandNews",
    "StartUpBeat",
    "SundayKiss",
    "Swagger",
    "SYMedia Lab",
    "Takungpao",
    "Tatler Asia",
    "Tatler HK",
    "Technow",
    "Tech Ritual",
    "The Business of Fashion",
    "The Gospel Herald",
    "The Gospel Herald (HK)",
    "The Mirror",
    "The News Lens",
    "ThinkHK",
    "Time Out",
    "TMHK",
    "TopBeauty",
    "TOPick",
    "TVB News",
    "TVBOXNOW",
    "TVBS",
    "Twitter",
    "UDN",
    "UDN Money",
    "UDN Vip",
    "ULifestyle",
    "Unwire",
    "Unwire.pro",
    "Upbeat Media",
    "Uwants",
    "V1Paper",
    "Vision Times",
    "VJMedia",
    "Vogue HK",
    "Weekend Weekly",
    "WenWeiPo",
    "WeShare",
    "World Journal",
    "WSJ",
    "Xinhua Finance",
    "Xinhuanet",
    "Yahoo News",
    "Yazhou Zhoukan",
    "Yes News",
    "YouTube",
    "021east",
    "100ec",
    "10jqka",
    "163",
    "16bk",
    "21jingji",
    "360doc",
    "360kuai",
    "51emo",
    "521u",
    "58",
    "95306",
    "baidu",
    "bbtnews",
    "bilibili",
    "bjd",
    "caijing",
    "cctv",
    "chnfund",
    "cls",
    "cnr",
    "cnstock",
    "cqtresearch",
    "ctdsb",
    "dcdapp",
    "dingxinwen",
    "djxww",
    "dotdotnews",
    "douyin",
    "eastmoney",
    "ec100",
    "emoney",
    "feheadline",
    "futunn",
    "gelonghui",
    "gf",
    "gog",
    "gqsoso",
    "gszq",
    "gtxh",
    "guancha",
    "guannews",
    "hbsztv",
    "hexun",
    "hkqaizela",
    "hnshkx",
    "ifeng",
    "iqiyi",
    "ixigua",
    "jrj",
    "jstv",
    "jwview",
    "jxyuging",
    "kuaishou",
    "lankeji",
    "laoke",
    "lcxwfc",
    "linkshop",
    "logclub",
    "mala",
    "myzaker",
    "nbd",
    "ocn",
    "pdnews",
    "pedaily",
    "peopleapp",
    "pingan",
    "qianlima",
    "qingyise",
    "qq",
    "scol",
    "sfccn",
    "shangyexinzhi",
    "sina",
    "sjqcj",
    "sohu",
    "southcn",
    "stcn",
    "stockstar",
    "taiyuanrx",
    "thejike",
    "thepaper",
    "thexincai",
    "toutiao",
    "uc",
    "weibo",
    "wenshannet",
    "xhby",
    "xiangyang",
    "xiaohongshu",
    "xueqiu",
    "ycwb",
    "yewangcaijing",
    "yfbzb",
    "yicai",
    "yidianzixun",
    "yixianews",
    "yoojia",
    "youth",
    "zbytb",
    "zgjrw",
    "zhicheng",
    "zhihu",
    "zhongbunews",
    "zjpubservice",
    "zjzwfw"
]

interface MiscState {
    industries: Industry[],
    sites: string[],
    mediums: string[],
    languages: Language[],
    keywordCategories: any,
    sources: {
        medium: string,
        sites: string[]
    }[]
}

// action thunk
export const getIndus = createAsyncThunk("misc/getIndus", async (_, thunkApi): Promise<Industry[]> => {
    const currIndus = store.getState().misc.industries
    if (currIndus.length) return currIndus

    const token = store.getState().user.token
    // const ind: Industry[] = await uf.ajax({
    //     headers: { token },
    //     url: `${process.env.REACT_APP_ENDPOINT}/api/misc/list/industries`
    // }).then((res:any) => industryData)
    //     .catch(err => { throw err })
    return industryData
})

export const getSites = createAsyncThunk("misc/getSites", async (_, thunkApi): Promise<string[]> => {
    const currSites = store.getState().misc.sites
    if (currSites.length) return currSites


    const token = store.getState().user.token
    // const sites: string[] = await uf.ajax({
    //     headers: { token },
    //     url: `${process.env.REACT_APP_ENDPOINT}/api/misc/list/sites`
    // }).then((res: any) => sitesData)
    //     .catch(err => { throw err })
    return sitesData
})


export const getLangs = createAsyncThunk("misc/getLangs", async (_, thunkApi): Promise<Language[]> => {
    const currLangs = store.getState().misc.languages
    if (currLangs.length) return currLangs

    const token = store.getState().user.token
    // const langs: Language[] = await uf.ajax({
    //     headers: { token },
    //     url: `${process.env.REACT_APP_ENDPOINT}/api/misc/list/langs`
    // }).then((res: any) => languageData)
    //     .catch(err => { throw err })
    return languageData
})


export const getMediums = createAsyncThunk("misc/getMediums", async (_, thunkApi): Promise<string[]> => {
    const currMed = store.getState().misc.mediums
    if (currMed.length) return currMed

    const token = store.getState().user.token
    // const mediums: string[] = await uf.ajax({
    //     headers: { token },
    //     url: `${process.env.REACT_APP_ENDPOINT}/api/misc/list/mediums`
    // }).then((res: any) => mediumsData)
    //     .catch(err => { throw err })
    return mediumsData
})

export const getKeywordCategories = createAsyncThunk("misc/getKeywordCategories" , async (_, thunkApi): Promise<string[]> => {
    const currkeywordCategories = store.getState().misc.keywordCategories
    if (currkeywordCategories.length) return currkeywordCategories

    const token = store.getState().user.token
    const keywordCategories: any[] = await uf.ajax({
        headers: {  Authorization: `Bearer ${store.getState().user.token}` },
        url: `${process.env.REACT_APP_ENDPOINT}/keyword-categories`
    })
    console.log("keywordCategories", keywordCategories)
    return keywordCategories
})

export const getSources = createAsyncThunk("misc/getSources", async (_, thunkApi): Promise<{ medium: string, sites: string[] }[]> => {
    const currentSources = store.getState().misc.sources
    if (currentSources.length) return currentSources

    const token = store.getState().user.token
    try {
        const res: any = await uf.ajax({
            headers: { token },
            url: `${process.env.REACT_APP_ENDPOINT}/api/misc/list/medsites`
        })
         return res.data

    } catch (error) {
        throw new Error(error)
    }
})


const initialState: MiscState = {
    industries: [],
    sites: [],
    languages: new Array<Language>(),
    mediums: [],
    sources: [],
    keywordCategories: []
}


// slice
export const MiscSlice = createSlice({
    name: "misc",
    initialState,
    reducers: {},
    extraReducers: {
        // FETCH INDUSTRIES LIST
        [getIndus.fulfilled.toString()]: (state, action: PayloadAction<Industry[]>) => {
            state.industries = action.payload
        },
        // FAILED TO FETCH INDUSTRIES LIST
        [getIndus.rejected.toString()]: (_) => { },
        // FETCH KEYWORD CATEGORIES LIST
        [getKeywordCategories.fulfilled.toString()]: (state, action: PayloadAction<any>) => {
            state.keywordCategories = action.payload
        },
        // FAILED TO FETCH KEYWORD CATEGORIES LIST
        [getKeywordCategories.rejected.toString()]: (_) => { },
        // FETCH SITES LIST
        [getSites.fulfilled.toString()]: (state, action: PayloadAction<string[]>) => {
            state.sites = action.payload
        },
        // FAILED TO FETCH SITES LIST
        [getSites.rejected.toString()]: (_) => { },
        // FETCH MEDIUMS LIST
        [getMediums.fulfilled.toString()]: (state, action: PayloadAction<string[]>) => {
            state.mediums = action.payload
        },
        // FAILED TO FETCH MEDIUMS LIST
        [getMediums.rejected.toString()]: (_) => { },
        // FETCH LANG LIST
        [getLangs.fulfilled.toString()]: (state, action: PayloadAction<Language[]>) => {
            state.languages = action.payload
        },
        // FAILED TO FETCH LANG LIST
        [getLangs.rejected.toString()]: (_) => { },
        // FETCH SOURCES
        [getSources.fulfilled.toString()]: (state, action: PayloadAction<{ medium: string, sites: string[] }[]>) => {
            state.sources = action.payload
        },
        // FAILED TO FETCH SOURCES
        [getSources.rejected.toString()]: (_) => { },
        [removeUser.fulfilled.toString()]: (state) => {
            Object.assign(state, initialState)
        },
    }
})


// selector
export const selectIndustries = (state: RootState) => state.misc.industries
export const selectSites = (state: RootState) => state.misc.sites
export const selectMediums = (state: RootState) => state.misc.mediums
export const selectLangs = (state: RootState) => state.misc.languages
export const selectSources = (state: RootState): { medium: string, sites: string[] }[] => state.misc.sources
export const selectCategories = (state: RootState) => state.misc.keywordCategories


export default MiscSlice.reducer