import React from "react";
import { selectIndustries } from "../../../../../app/store/miscSlice";
import styles from "./name.module.scss";
import { useSelector } from "react-redux";
import { Industry } from "../../../../../app/interfaces/misc";
import { setTaskName, setTaskIndus } from "../ctx/action";
import Ctx, { Context } from "../ctx";
//import { ESQLayer } from "../interface";
// ESConvert from "../qrybuilder/esconvert";
const TaskName = () => {
    const industries:Industry[] = useSelector(selectIndustries);
    const m_ind:Industry[] = React.useMemo(()=> [{id:0,industry:"- Select Industry -"},...industries],[industries]);

    const ctx = React.useContext(Ctx) as Context;
    const defaultName:string = React.useMemo(()=>ctx.editor.detail.name!,[ctx.editor.detail.name]);
    const defaultIndus:number = React.useMemo(()=>ctx.editor.detail.indus_id!,[ctx.editor.detail.indus_id]);
    const onEnterName = React.useCallback((name:string)=> {
        setTaskName(name,ctx.setEditor);
        


        /*
        if( (ctx.editor.detail.keywords?.guided.length ?? 0) <= 1 && (typeof (ctx.editor.detail.keywords?.guided[0]?.in?.[0]) !== "object")){
            const adv:ESQLayer[] = [{in:[name]}];
            const ec = new ESConvert([{in:[name]}]);
            const qrystring = ec.convert();
            setTaskKw(adv,qrystring,ctx.setEditor);
        }
        */
    },[ctx.setEditor]);
    const onChangeIndus = React.useCallback((indus_id:number)=>setTaskIndus(indus_id,ctx.setEditor),[ctx.setEditor]);


    return (
        <div className={styles.name}>
            <h3 className={styles.title}>Give your task a name</h3>

            {/* <p className={styles.label}>optional</p> */}
            <input defaultValue={defaultName} type="text" className="input" onChange={e => onEnterName(e.currentTarget.value)} />
            {/* <div className="field">
                <div className="select">
                    <select onChange={e => onChangeIndus(parseInt(e.currentTarget.value))} defaultValue={defaultIndus}>
                        {
                            m_ind.map(ind =>
                                <option key={ind.id} value={ind.id}>{ind.industry}</option>
                            )
                        }
                    </select>
                </div>
            </div> */}
        </div>
    )
}

export default TaskName;