import React from "react"
import uf from "usefuljs"
import { useDispatch } from "react-redux"
import { setInfo } from "../../app/store/userSlice"
import { UserInfo } from "../../app/interfaces/user"
import styles from "./login.module.scss"
import styled from 'styled-components'
import Loading from "../reusable/loader"

const TextInput = styled.input`
    height: 2.5rem;
    width: 100%;
    border: 1px solid #e6e6e6d2;
    border-radius: 5px;
    padding: 12px;
    font-size: 1rem;
    color: #424c5a;
    background-color: #FFF;
    &:focus {
        outline: none;
        box-shadow: none;
        border: 1px solid #29d1d7;
    }
`

const Login = () => {

    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [errMsg, setErrMsg] = React.useState("")

    const dispatch = useDispatch()

    const onSubmit = async (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setLoading(true)

        // const { ip }: any = await uf.ajax({
        //     url: "https://api64.ipify.org?format=json"
        // })

        await uf.ajax({
            method: "post",
            url: `${process.env.REACT_APP_ENDPOINT}/login`,
            data: {
                email,
                password,
            }
        }).then((res:any) => {
            console.log("Login Data", res)
            if(res?.id){
                const userinfo: UserInfo = {
                    user_id: res.id,
                    email: email,
                    token: res?.accessToken,
                    dateFromUnixTime: 1689811200
                }
                dispatch(setInfo(userinfo))
            }
            // if (res.result === "success") {
            //     let dateFromUnixTime: number = null
            //     if(res.data.acc_level.date_from){
            //         const date: Date = new Date(res.data.acc_level.date_from);
            //         dateFromUnixTime = date.getTime();
            //     }
            //     const userinfo: UserInfo = {
            //         user_id: res.data.user_id,
            //         username: res.data.username,
            //         display_name: res.data.display_name,
            //         email: res.data.email,
            //         token: res.token,
            //         ac_id: res.data.ac_id,
            //         liveFeedLimit: res.data.acc_level.live_limit ?? null,
            //         adhocFeedLimit: res.data.acc_level.adhoc_limit ?? null,
            //         exportPerMonthPerTaskQuota: res.data.acc_level.export_pm ?? null,
            //         keywordPerTask: res.data.acc_level.kw_pt ?? null,
            //         queryModificationPerMonthPerTask: res.data.acc_level.live_qry_mod ?? 0,
            //         subscribedToSmartFeed: res.data.acc_level.smart_feed ?? false,
            //         subscribedToAlert: res.data.acc_level.alert ?? false,
            //         keywordLimit: res.data.acc_level.keyword_limit ?? null,
            //         dateFromUnixTime: dateFromUnixTime ?? null
            //     }
            //     dispatch(setInfo(userinfo))
            // } else {
            //     throw new Error(res.error)
            // }

        })
            .catch(err => {
                console.error(err)
                setErrMsg(err.toString())
                setLoading(false)
            })
    }

    return (
        loading ? <Loading /> :
            <section className="section" id={styles.login_page}>
                <img className={styles.img} src="https://d18dyb0v4b4cx9.cloudfront.net/lenx_logo.webp" alt="logo" />
                <div id={styles.login}>
                    <h1>Login</h1>

                    {
                        errMsg.length ?
                            <div className="notification is-danger is-light">
                                <button className="delete" onClick={() => setErrMsg("")}></button>
                                {errMsg}
                            </div> : null
                    }


                    <form onSubmit={onSubmit}>
                        <div className="field">
                            <div className="control">
                                <TextInput name="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    className="input"
                                    type="email"
                                    placeholder="Email e.g johndoe@example.com"
                                    minLength={6}
                                    required />
                            </div>
                        </div>
                        <br />

                        <div className="field">
                            <div className="control">
                                <TextInput name="password"
                                    className="input"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    type="password"
                                    placeholder="Password"
                                    minLength={4}
                                    required />
                            </div>
                        </div>
                        <br />
                        <div className="field">
                            <div className="control">
                                <button className="button is-primary">Login</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>

    )
}

export default Login