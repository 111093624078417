import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  SelectAuthors,
  clearAuthorData,
  fetchTopAuthors,
  refetchTopAuthorsCommentsData,
  selectDateRange,
  selectTopChannels,
  showAuthorData,
  showInfluencersData,
} from "../../../../../../app/store/overviewSlice";
import {
  DateRange,
  TopAuthors,
} from "../../../../../../app/interfaces/overview";
import styles from "./topchannels.module.scss";
import { siteicon } from "../../../../../../app/tools/display";
import EmptyStatePlaceholder from "../../components/EmptyStatePlaceholder";
import AuthorsSkeleton from "./Skeleton";
import {
  GadgetBody,
  GadgetHeader,
  GadgetRoot,
  RowGridContainer,
} from "../../styled";
import {
  AuthorTitle,
  Channel,
  GreyText,
  Logo,
  PaginationButton,
  PaginationContainer,
  PostCount,
} from "./styled";
import {
  selectFeedType,
  selectTaskId,
  selectTaskUnixEnd,
  selectTaskUnixStart,
  selectedKeywordType,
} from "../../../../../../app/store/feedSlice";

export type SortOption = "follower count" | "engagements";

const TopChannels = () => {
  const dispatch = useDispatch();
  const topChannels:any = useSelector(selectTopChannels);

  const dateRange = useSelector(selectDateRange);
  const keywordType = useSelector(selectedKeywordType)
  const [dropdownExpanded, setDropdownExpanded] = React.useState(false);

  const [sortOption, setSortOption] = React.useState<SortOption>("follower count");
  const sortOptions: SortOption[] = [
    "follower count",
    "engagements",
  ];

  const sortedAuthors = useMemo(() => {
    if (topChannels) {
        console.log("Top Channels234", topChannels)
      switch (sortOption) {
        case "follower count":
          return topChannels.byFollowers;
        case "engagements":
          return topChannels.byEnagements;
        default:
          return topChannels.byFollowers;
      }
    }
    return [];
  }, [sortOption, topChannels]);

  const pageShow = 10;
  const [page, setPage] = React.useState(0);
  const pageRange0 = page * pageShow;
  const pageRange1 = page * pageShow + pageShow;
  const pageAuthors = sortedAuthors?.length ? sortedAuthors?.slice(pageRange0, pageRange1): [];

  const onClickAuthor = (author) => {
    console.log("author1", author?.site, author?.channel_id, author?.site === "Youtube")
    if(author?.site === "Facebook"){
      window.open(`https://www.facebook.com/${author?.channel_id}`, "_blank");
    } else if(author?.site === "Instagram"){
      window.open(`https://www.instagram.com/${author?.channel_id}`, "_blank");
    } else if(author?.site === "YouTube"){
      console.log("author", author?.site, author?.channel_id)
      window.open(`https://www.youtube.com/@${author?.channel_id}`, "_blank");
    }

  }

  const handleClickExpand = () => {
    if (!dropdownExpanded) {
      document.addEventListener("click", collapseDropdown);
    }
    setDropdownExpanded(true);
  };

  const collapseDropdown = () => {
    setDropdownExpanded(false);
    document.removeEventListener("click", collapseDropdown);
  };

  const taskId = useSelector(selectTaskId);
  const taskType = useSelector(selectFeedType);
  const taskUnixStart = useSelector(selectTaskUnixStart);
  const taskUnixEnd = useSelector(selectTaskUnixEnd);

  console.log("topChannels", topChannels, keywordType);



  return (
    <GadgetRoot>
      <GadgetHeader>
        <h3>Top Channels</h3>
        <div>
          <span>View by:</span>
          <div
            className={
              "dropdown is-right" + (dropdownExpanded ? " is-active" : "")
            }
            id={styles.dropdownContainer}
          >
            <div
              className="dropdown-trigger"
              onClick={handleClickExpand}
              id={styles.dropdownTriggerContainer}
            >
              <button
                className="button"
                aria-haspopup="true"
                aria-controls="taskMenu"
                id={styles.dropdownButton}
              >
                <span className={styles.chosen}>{sortOption}</span>
                <span className="icon is-small">
                  {dropdownExpanded ? (
                    <i className="fas fa-angle-up" aria-hidden="true" />
                  ) : (
                    <i className="fas fa-angle-down" aria-hidden="true" />
                  )}
                </span>
              </button>
            </div>
            <div className="dropdown-menu" id="taskMenu" role="menu">
              <div className="dropdown-content">
                {sortOptions.map((sortOption) => {
                  return (
                    <div
                      key={sortOption}
                      className={"dropdown-item " + styles.dropdownOption}
                      onClick={() => {
                        setSortOption(sortOption);
                        setPage(0);
                      }}
                    >
                      No. of {sortOption}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </GadgetHeader>
      <GadgetBody style={{height:"30.5rem"}}>
        <RowGridContainer>
          <div style={{gridColumn:"1/5"}}>
            <GreyText>Name</GreyText>
          </div>
          <div style={{gridColumn:"5/6"}}>
            <GreyText>Comment</GreyText>
          </div>
          <div  style={{gridColumn:"6/10"}}>
            <GreyText>Reaction</GreyText>
          </div>
          <div  style={{gridColumn:"10/11", padding: "7.2px 10.8px"}}>
            <GreyText>Posts</GreyText>
          </div>
          <div style={{gridColumn:"11/13",  padding: "7.2px 10.8px"}}>
            <GreyText>Engagement</GreyText>
          </div>
          <div style={{gridColumn:"13/14",  padding: "7.2px 10.8px"}}>
            <GreyText>Followers</GreyText>
          </div>
        </RowGridContainer>

        <div>
          {topChannels ? (
            pageAuthors.length ? (
              pageAuthors.map((author) => (
                <RowGridContainer
                  $isClickable
                  key={author.id}
                  onClick={() => {
                    if(keywordType === 2){
                      dispatch(showInfluencersData(author.name))
                    } else {
                      onClickAuthor(author)
                    }

                  }}
                  
                >
                  <div style={{gridColumn:"1/5"}}>
                    <Logo
                      src={siteicon(author.site, "News")}
                      alt={`${author.site} logo`}
                    />
                    <AuthorTitle onClick={() => {
                  

                  }}> {author.name}</AuthorTitle>
                  </div>
                  <div style={{gridColumn:"5/6", display:"flex", alignItems:"center"}}>
                  <i className="fas fa-comment-alt" style={{color:"#d8d8d8", maxWidth:"1rem", maxHeight:"1rem",}}></i>
                    <PostCount style={{marginLeft:"8px"}}>{author.comment_count}</PostCount>
                  </div>
                  <div style={{gridColumn:"6/10", display:"flex", alignItems:"center"}}>
                  {author.reaction_like_count !== undefined && author.reaction_like_count !== 0  && <span style={{display:"flex", alignItems:"center", marginRight:"7px"}}><i className="fas fa-thumbs-up" style={{color:"#d8d8d8", maxWidth:"1rem", maxHeight:"1rem", marginRight:"2px"}}></i> {author.reaction_like_count}</span>}
                  {author.reaction_dislike_count !== undefined && author.reaction_dislike_count !== 0 && <span style={{display:"flex", alignItems:"center",  marginRight:"7px"}}><i className="fas fa-thumbs-down" style={{color:"#d8d8d8", maxWidth:"1rem", maxHeight:"1rem", marginRight:"2px"}}></i> {author.reaction_dislike_count}</span>}
                  {author.reaction_love_count !== undefined && author.reaction_love_count !== 0 && <span style={{display:"flex", alignItems:"center",  marginRight:"7px"}}><img src="https://d18dyb0v4b4cx9.cloudfront.net/fb_love.webp" alt="love"  style={{maxWidth:"1rem", maxHeight:"1rem",  marginRight:"2px"}}/> {author.reaction_love_count}</span>}
                  {author.reaction_haha_count !== undefined && author.reaction_haha_count !==0  && <span style={{display:"flex", alignItems:"center",  marginRight:"7px"}}><img src="https://d18dyb0v4b4cx9.cloudfront.net/fb_haha.webp" alt="love"  style={{maxWidth:"1rem", maxHeight:"1rem", marginRight:"2px" }}/> {author.reaction_haha_count}</span>}
                  {author.reaction_angry_count !== undefined && author.reaction_angry_count !== 0 && <span style={{display:"flex", alignItems:"center",  marginRight:"7px"}}><img src="https://d18dyb0v4b4cx9.cloudfront.net/fb_angry.webp" alt="love"  style={{maxWidth:"1rem", maxHeight:"1rem",  marginRight:"2px"}}/> {author.reaction_angry_count}</span>}
                  {author.reaction_sad_count !== undefined && author.reaction_sad_count !== 0 && <span style={{display:"flex", alignItems:"center",  marginRight:"7px"}}><img src="https://d18dyb0v4b4cx9.cloudfront.net/fb_sad.webp" alt="love"  style={{maxWidth:"1rem", maxHeight:"1rem",  marginRight:"2px"}}/> {author.reaction_sad_count}</span>}
                  {author.reaction_wow_count !== undefined && author.reaction_wow_count !== 0 && <span style={{display:"flex", alignItems:"center"}}><img src="https://d18dyb0v4b4cx9.cloudfront.net/fb_wow.webp" alt="love"  style={{maxWidth:"1rem", maxHeight:"1rem",  marginRight:"2px"}}/> {author.reaction_wow_count}</span>}
           
          
          
           
                
                  
                    {/* <PostCount style={{marginLeft:"8px"}}>{author.reaction_count}</PostCount> */}
                  </div>
                  <div style={{gridColumn:"10/11", padding: "7.2px 10.8px"}}>
                    <PostCount  style={{marginLeft:"2px"}}  onClick={() => {
                    if(keywordType === 2){
                      dispatch(showInfluencersData(author.name))
                    } 
                    // else {
                    //   onClickAuthor(author)
                    // }

                  }}
                  >{author.post_count}</PostCount>
                  </div>
                  <div style={{gridColumn:"11/13",  padding: "7.2px 10.8px"}}>
                    <Channel>{author.engagement?.toLocaleString()}</Channel>
                  </div>
                  <div style={{gridColumn:"13/14",  padding: "7.2px 10.8px"}}>
                    <PostCount>{author.follower_count?.toLocaleString()}</PostCount>
                  </div>
                </RowGridContainer>
              ))
            ) : (
              <EmptyStatePlaceholder />
            )
          ) : (
            <AuthorsSkeleton />
          )}
        </div>

        <PaginationContainer>
          <PaginationButton
            $disabled={page === 0}
            onClick={() => page > 0 && setPage(page - 1)}
          >
            <i className="fas fa-chevron-left"></i>
          </PaginationButton>
          <p className={styles.txt}>
            Showing {pageRange0 + 1}-{pageRange1} of {sortedAuthors.length}{" "}
            channels
          </p>
          <PaginationButton
            $disabled={pageRange1 >= sortedAuthors.length}
            onClick={() =>
              pageRange1 < sortedAuthors.length && setPage(page + 1)
            }
          >
            <i className="fas fa-chevron-right"></i>
          </PaginationButton>
        </PaginationContainer>
      </GadgetBody>
    </GadgetRoot>
  );
};

export default TopChannels;
