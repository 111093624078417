// return icon image url linke of specific site
import facebookIcon from '../../assets/icons/mFacebook.webp'
import LIHKGIcon from '../../assets/icons/mLIHKG.webp'
import YoutubeIcon from '../../assets/icons/mYoutube.webp'
import InstagramIcon from '../../assets/icons/mInstagram.webp'
import NewsIcon from '../../assets/icons/News.webp'
export const siteicon = (site: string, medium?: string) => {
	switch (site) {
		case "MSN":
			return "https://i.imgur.com/sEOTPAX.png"
		case "HKEJ":
			return "https://d18dyb0v4b4cx9.cloudfront.net/HKEJ.webp"
		case "SingTao":
			return "https://d18dyb0v4b4cx9.cloudfront.net/msingtao.webp"
		case "Oncc":
			return "https://d18dyb0v4b4cx9.cloudfront.net/moncc.webp"
		case "Yahoo News":
			return "https://d18dyb0v4b4cx9.cloudfront.net/Yahoo.webp"
		case "Bastille":
			return "https://d18dyb0v4b4cx9.cloudfront.net/mBastille.webp"
		case "HK01":
			return "https://d18dyb0v4b4cx9.cloudfront.net/mHK01.webp"
		case "HKDiscuss":
			return "https://i.imgur.com/C5bXZU0.png"
		case "MingPao":
			return "https://d18dyb0v4b4cx9.cloudfront.net/MingPao.webp"
		case "Headline":
			return "https://d18dyb0v4b4cx9.cloudfront.net/mheadline.webp"
		case "Galden":
			return "https://i.imgur.com/5YM2qWQ.png"
		case "YouTube":
			return YoutubeIcon
			// return "https://d18dyb0v4b4cx9.cloudfront.net/mYoutube.webp"
		case "GeoExpat":
			return "https://i.imgur.com/BW2CIe9.png"
		case "StandNews":
			return "https://d18dyb0v4b4cx9.cloudfront.net/mStandNews.webp"
		case "InMediaHK":
			return "https://i.imgur.com/AKdbS6n.png"
		case "BabyKingdom":
			return "https://d18dyb0v4b4cx9.cloudfront.net/mBabyKingdom.webp"
		case "AppleDaily":
			return "https://d18dyb0v4b4cx9.cloudfront.net/mAppleDaily.webp"
		case "Facebook Group":
		case "Facebook Page":
		case "Facebook":
			// return "https://d18dyb0v4b4cx9.cloudfront.net/mFacebook.webp"
			return facebookIcon
		case "LIHKG":
			// return "https://d18dyb0v4b4cx9.cloudfront.net/mLIHKG.webp"
			return LIHKGIcon
		case "Golden":
			return "https://i.imgur.com/GzCCQZJ.png"
		case "Telegram":
			return "https://d18dyb0v4b4cx9.cloudfront.net/mTelegram.webp"
		case "Twitter":
			return "https://d18dyb0v4b4cx9.cloudfront.net/mtwitter.webp"
		case "Instagram":
			return InstagramIcon
			// return "https://d18dyb0v4b4cx9.cloudfront.net/mInstagram.webp"
		case "SCMP":
			return "https://corp.scmp.com/wp-content/uploads/2018/02/SCMP_logo_03.png"
		case "Unwire":
			return "https://startfintech.unwire.hk/wp-content/uploads/2016/11/unwire_logo-300x266.png"
		case "LineToday":
			return "https://d18dyb0v4b4cx9.cloudfront.net/LineToday.webp"
		case "BabyDiscuss":
			return "https://d18dyb0v4b4cx9.cloudfront.net/BabyDiscuss.webp"
		case "Standard":
			return "https://d18dyb0v4b4cx9.cloudfront.net/TheStandard.webp"
		case "AAstocks":
			return "https://d18dyb0v4b4cx9.cloudfront.net/aastocks.webp"
		case "Post852":
			return "https://d18dyb0v4b4cx9.cloudfront.net/Post852.webp"
		case "BeautyExchange":
			return "https://d18dyb0v4b4cx9.cloudfront.net/BeautyExchange.webp"
		case "Uwants":
			return "https://d18dyb0v4b4cx9.cloudfront.net/Uwants.webp"
		case "PassionTimes":
			return "https://d18dyb0v4b4cx9.cloudfront.net/PassionTimes.webp"
		case "EPrice":
			return "https://d18dyb0v4b4cx9.cloudfront.net/EPrice.webp"
		case "Weekend Weekly":
			return "https://d18dyb0v4b4cx9.cloudfront.net/WeekendWeekly.webp"
		case "EduKingdom":
			return "https://d18dyb0v4b4cx9.cloudfront.net/EduKingdom.webp"
		case "hket":
			return "https://d18dyb0v4b4cx9.cloudfront.net/HKET.webp"
		case "am730":
			return "https://d18dyb0v4b4cx9.cloudfront.net/am730.webp"
		case "Sina HK":
			return "https://d18dyb0v4b4cx9.cloudfront.net/SinaHK.webp"
		case "PCM":
			return "https://d18dyb0v4b4cx9.cloudfront.net/PCM.webp"
		case "NowTV News":
			return "https://d18dyb0v4b4cx9.cloudfront.net/NowTVNews.webp"
		case "Epoch Times":
			return "https://d18dyb0v4b4cx9.cloudfront.net/EpochTimes.webp"
		case "Epoch Times HK":
			return "https://d18dyb0v4b4cx9.cloudfront.net/EpochTimesHK.webp"
		case "EDigest":
			return "https://d18dyb0v4b4cx9.cloudfront.net/EDigest.webp"
		case "TOPick":
			return "https://d18dyb0v4b4cx9.cloudfront.net/TOPick.webp"
		case "SkyPost":
			return "https://d18dyb0v4b4cx9.cloudfront.net/SkyPost.webp"
		case "RTHK":
			return "https://d18dyb0v4b4cx9.cloudfront.net/RTHK.webp"
		case "Finet":
			return "https://i.imgur.com/AwgzixJ.png"
		default:
			if (!medium) {
				return ""
			} else {
				return getMediumIconLink(medium)
			}
	}
}

export const getMediumIconLink = (medium: string) => {
	switch (medium) {
		case "Social":
			return "https://d18dyb0v4b4cx9.cloudfront.net/Social.webp"
		case "Video":
			return "https://d18dyb0v4b4cx9.cloudfront.net/Video.webp"
		case "News":
			return NewsIcon
			// return "https://d18dyb0v4b4cx9.cloudfront.net/News.webp"
		case "Blog":
			return "https://d18dyb0v4b4cx9.cloudfront.net/Blog.webp"
		default:
			return ""
	}
}
