import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { selectDateRange, selectTopSites } from "../../../../../../app/store/overviewSlice"
import styles from "./sites.module.scss"
import EmptyStatePlaceholder from "../../components/EmptyStatePlaceholder"
import { DateRange, TopSites } from "../../../../../../app/interfaces/overview"

import { GadgetBody, GadgetHeader, GadgetRoot } from "../../styled"
import SiteRow from "../../../../../reusable/modules/sites/SiteRow"
import styled from "styled-components"
import SitesSkeleton from "../../../../../reusable/modules/sites/SitesSkeleton"
import { selectFeedType } from "../../../../../../app/store/feedSlice"

type SortOption = "threads" | "comments" | "threads and comments" | "engagements" | "engagements and comments"

interface SitedData {
    comment_count: number
    site: string
    post_count: number
    author_count: number
    thread_count: number
    rank: number
    channel: string
}

const SiteTable = styled.div`
    height: 17.5rem;
    overflow-y: auto;
    overflow-x: hidden;
`

const Sites = () => {

    const [dropdownExpanded, setDropdownExpanded] = React.useState(false)
    const [sortOption, setSortOption] = React.useState<SortOption>("engagements")
    const sortOptions: SortOption[] = ["engagements", "comments", "engagements and comments"]
    const dateRange = useSelector(selectDateRange)
    const taskType = useSelector(selectFeedType);

    const handleClickExpand = () => {
        if (!dropdownExpanded) {
            document.addEventListener('click', collapseDropdown)
        }
        setDropdownExpanded(true)
    }

    const collapseDropdown = () => {
        setDropdownExpanded(false)
        document.removeEventListener('click', collapseDropdown)
    }

    const topSites: TopSites | null = useSelector(selectTopSites)

    console.log("Top Sites", topSites)

    const sortedSites = useMemo(() => {
        if (topSites) {
            switch (sortOption) {
                case "comments":
                    return topSites.byComments
                case "engagements":
                    return topSites.byThreads
                default:
                    return topSites.byTotal
            }
        }
        return []
    }, [sortOption, topSites])

    const maxSortedPostCount = useMemo(() => {
        return Math.max.apply(Math, sortedSites.map(site => site.postCount))
    }, [sortedSites])

    const getPostCount = (site: string, postCount: number) => {
        if((site === "Facebook Page" || site === "Facebook Group") && dateRange !== DateRange.Today && taskType !== 1){
            return Math.floor(postCount*0.2)

        } 
        return postCount

    }

    return (
        <GadgetRoot>
            <GadgetHeader>
                <h3>Top Sites by Activity</h3>
                <div>
                    <span>View by:</span>
                    <div className={"dropdown is-right" + (dropdownExpanded ? " is-active" : "")} id={styles.dropdownContainer}>
                        <div className="dropdown-trigger" onClick={handleClickExpand} id={styles.dropdownTriggerContainer}>
                            <button className="button" aria-haspopup="true" aria-controls="taskMenu" id={styles.dropdownButton}>
                                <span className={styles.chosen}>{sortOption}</span>
                                <span className="icon is-small">
                                    {dropdownExpanded ? <i className="fas fa-angle-up" aria-hidden="true" /> : <i className="fas fa-angle-down" aria-hidden="true" />}
                                </span>
                            </button>
                        </div>
                        <div className="dropdown-menu" id="taskMenu" role="menu">
                            <div className="dropdown-content">
                                {sortOptions.map(sortOption => {
                                    return <div key={sortOption} className={"dropdown-item " + styles.dropdownOption} onClick={() => {
                                        setSortOption(sortOption)
                                    }}>No. of {sortOption}</div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </GadgetHeader>
            <GadgetBody>
                <SiteTable>
                    {topSites ?
                        (sortedSites.length ? sortedSites.map(site => (
                            <SiteRow
                                site={site.site}
                                channel={site.channel}
                                postCount={site.postCount}
                                maxSortedPostCount={maxSortedPostCount}
                                key={`${site.site}_${site.channel}`}
                            />
                        )) : <EmptyStatePlaceholder />) : <SitesSkeleton />
                    }
                </SiteTable>
            </GadgetBody>
        </GadgetRoot>
    )
}

export default Sites