import React from "react"
import styled from 'styled-components'
import ModalRoot from "../../../../reusable/utils/Modal/ModalRoot"
import ModalMain from "../../../../reusable/utils/Modal/ModalMain"
// import ModalHeader from "../../../../reusable/Utils/Modal/ModalHeader"
// import BinIcon from "../../../../reusable/icons/BinIcon"
import Button from "../../../../reusable/buttons/Button"

interface DeleteConfirmationProp {
  show: boolean
  cancel: () => void
  deleteFeed: () => void
}

const Heading = styled.h3`
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #516380;
`

const SubHeading = styled.p`
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 2rem;
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;
`

const MainContent = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

function DeleteConfirmation({ show, cancel, deleteFeed }: DeleteConfirmationProp) {
  return (
    <ModalRoot show={show} onClick={(event) => { event.stopPropagation() }}>
      <ModalMain>
        {/* <ModalHeader
          title="Delete feed"
          icon={<BinIcon fill="#ed5252" height="1.4rem" width="1.4rem" />}
        /> */}
        <MainContent >
          <Heading>Are you sure?</Heading>
          <SubHeading>Once you delete this brand, it will be gone forever.</SubHeading>
          <ButtonsWrapper>
            <Button variant="tertiary" onClick={cancel} size="large"><span>Cancel</span></Button>
            <Button variant="danger" onClick={deleteFeed} size="large"><span>Delete</span></Button>
          </ButtonsWrapper>
        </MainContent>
      </ModalMain>
    </ModalRoot>
  )
}

export default DeleteConfirmation