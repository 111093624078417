import { Frequency } from "../interface"
import { Selections } from "./MenuSelectSingle"

export const timeFrameSelections: Selections<Frequency>[] = [
	{
		title: "Last 24 hours",
		disabled: false,
		value: "Latest",
		show: true,
	},
	// {
	// 	title: "Yesterday",
	// 	disabled: false,
	// 	value: "Daily",
	// 	show: true,
	// },
	{
		title: "Past 7 days",
		disabled: false,
		value: "Weekly",
		show: true,
	},
	{
		title: "Past 14 days",
		disabled: false,
		value: "BiWeekly",
		show: true,
	},
	// {
	// 	title: "Past 30 days",
	// 	disabled: false,
	// 	value: "Monthly",
	// 	show: true,
	// },
	// {
	// 	title: "Past 3 months",
	// 	disabled: true,
	// 	value: "ThreeMonths",
	// 	show: true,
	// },
	// {
	// 	title: "Past 6 months",
	// 	disabled: true,
	// 	value: "HalfYearly",
	// 	show: false,
	// },
	// {
	// 	title: "Past 12 months",
	// 	disabled: true,
	// 	value: "Yearly",
	// 	show: false,
	// },
	// {
	// 	title: "Past 24 months",
	// 	disabled: true,
	// 	value: "24m",
	// 	show: false,
	// },
	{
		title: "Custom Time Range",
		disabled: true,
		value: "custom",
		show: false,
	},
]

export const categorySelections = [
	{
		title: "Name",
		disabled: false,
		value: "name",
	},
	{
		title: "Brand",
		disabled: false,
		value: "brand",
	},
	{
		title: "Location",
		disabled: false,
		value: "location",
	},
]

export const maturitySelections = [
	{
		title: "Dormant",
		disabled: false,
		value: "dormant",
	},
	{
		title: "Emerging",
		disabled: false,
		value: "emerging",
	},
	{
		title: "Growing",
		disabled: false,
		value: "growing",
	},
	{
		title: "Mature",
		disabled: false,
		value: "mature",
	},
	{
		title: "Declining",
		disabled: false,
		value: "declining",
	},
	{
		title: "Fading",
		disabled: false,
		value: "fading",
	},
]
