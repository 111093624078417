import {
	Author,
	PostCountChartPoint,
	Sentiment,
	Site,
	StatCount,
	Stats,
	TopAuthors,
	TopEngagementThread,
	TopGrowthThread,
	TopSites,
	TopTrend,
} from "../../interfaces/overview"
import uf from "usefuljs"
import {
	AuthorDataFromAPI,
	AuthorFromAPI,
	HiddenFromAPI,
	PostCountChartFromAPI,
	SentimentsFromAPI,
	SiteFromAPI,
	StatCountFromAPI,
	StatsFromAPI,
	TopAuthorsFromAPI,
	TopEngagementThreadsFromAPI,
	TopGrowthThreadsFromAPI,
	TopSitesFromAPI,
	TopTrendsFromAPI,
	WordCloudFromAPI,
} from "../../interfaces/ResponseFromAPIs"
import { FeedData } from "../../interfaces/feed"
import moment from "moment"
import { groupBy, orderBy } from "lodash"

export function mapTopSites(topSitesFromAPI: any) {
	const groupedData = groupBy(topSitesFromAPI, "channel")
	console.log("Grouped Site Data", groupedData)
	let byComments = []
	let byEnagements = []
	let byTotal = []
	Object.keys(groupedData).forEach(authorKey => {
		console.log("authorKey", authorKey)
		if(authorKey !== "null"){
			const postData = groupedData[authorKey]
			console.log("postData", postData, authorKey)
			let comment_count = 0
			let engagement_count = 0
			postData?.forEach(post => {
				comment_count += post?.comment_count
				engagement_count += post?.engagement
			})
			byComments.push({
				id: authorKey,
				name: authorKey,
				postCount: postData.length,
				site: postData?.[0]?.site,
				channel: postData?.[0]?.channel,
				comment_count: comment_count
			})
			byEnagements.push({
				id: authorKey,
				name: authorKey,
				postCount: postData.length,
				site: postData?.[0]?.site,
				channel: postData?.[0]?.channel,
				engagement_count: engagement_count
			})
			byTotal.push({
				id: authorKey,
				name: authorKey,
				postCount: postData.length,
				site: postData?.[0]?.site,
				channel: postData?.[0]?.channel,
				total: engagement_count + comment_count
			})
	
		}


	})
	byEnagements = orderBy(byEnagements, ["engagement_count"], "desc").slice(0, 10)
	byComments = orderBy(byComments, ["comment_count"], "desc").slice(0, 10)
	byTotal = orderBy(byTotal, ["total"], "desc").slice(0, 10)
	return {
		byComments: mapSites(byComments),
		byThreads: mapSites(byEnagements),
		byTotal: mapSites(byTotal),
	} as TopSites
}

export function mapSites(sitesFromAPI: SiteFromAPI[]) {
	return sitesFromAPI.map((site, index) => ({
		site: site.site,
		rank: index + 1,
		channel: site.channel,
		postCount: site.postCount,
	})) as Site[]
}

export function mapTopAuthors(topAuthorsFromAPI: any) {
	const groupedData = groupBy(topAuthorsFromAPI, "author_name")
	let byComments = []
	let byEnagements = []
	let byTotal = []
	Object.keys(groupedData).forEach(authorKey => {
		const postData = groupedData[authorKey]
	
		let comment_count = 0
		let engagement_count = 0
		postData?.forEach(post => {
			comment_count += post?.comment_count
			engagement_count += post?.engagement
		})
		byComments.push({
			id: authorKey,
			name: authorKey,
			postCount: postData.length,
			site: postData?.[0]?.site,
			channel: postData?.[0]?.channel,
			comment_count: comment_count
		})
		byEnagements.push({
			id: authorKey,
			name: authorKey,
			postCount: postData.length,
			site: postData?.[0]?.site,
			channel: postData?.[0]?.channel,
			engagement_count: engagement_count
		})
		byTotal.push({
			id: authorKey,
			name: authorKey,
			postCount: postData.length,
			site: postData?.[0]?.site,
			channel: postData?.[0]?.channel,
			total: engagement_count + comment_count
		})


	})
	byEnagements = orderBy(byEnagements, ["engagement_count"], "desc").slice(0, 10)
	byComments = orderBy(byComments, ["comment_count"], "desc").slice(0, 10)
	byTotal = orderBy(byTotal, ["total"], "desc").slice(0, 10)
	return {
		byComments: mapAuthors(byComments),
		byThreads: mapAuthors(byEnagements),
		byTotal: mapAuthors(byTotal),
	} as TopAuthors
}

export function mapTopChannels(topChannelsFromAPI: any) {

	let byFollowers = []
	let byEnagements = []

	byEnagements = orderBy(topChannelsFromAPI, ["engagement"], "desc")
	byFollowers = orderBy(topChannelsFromAPI, ["follower_count"], "desc")

	return {
		byFollowers:byFollowers,
		byEnagements:byEnagements,
	} as any
}

export const mapAuthors = (authorsFromAPI: any) => {

	return authorsFromAPI.map((author, index) => ({
		id: author.task_id,
		name: author.name,
		rank: index + 1,
		postCount: author.postCount,
		site: author.site,
		channel: author.channel,
	})) as Author[]
}

export async function sequentialApiCalls(authorsFromAPI: AuthorFromAPI[], userToken?: string, feedTaskId?: number) {
    const results = [];

    for (const item of authorsFromAPI) {
		try {

		const response: AuthorDataFromAPI = await uf.ajax({
			method: "get",
			headers: { token: userToken },
			url: `${process.env.REACT_APP_ENDPOINT}/api/task/author?task_id=${feedTaskId}&author_id=${item?.author_id}`,
		})

		if (response.result !== "success")
			throw new Error("failed to fetch author data")
		item.post_count = findDistinctObjects(response.data)
			results.push(item);
		// return response.data as FeedData[]
	} catch (err) {
		console.error(err.message)
		return []
	}
       
       
    }

    return results;
}

function findDistinctObjects(feedDataList: FeedData[]) {
	const distinctValues = new Set();
	const result:FeedData[] = [];
  
	feedDataList?.forEach(feedData => {
	  const propValue = feedData["post_message"];
  
	  if (!distinctValues.has(propValue)) {
		distinctValues.add(propValue);
		result.push(feedData);
	  }
	});
	return result?.length;
  }


export function mapStats(statsFromAPI: any) {
	
	return {
		// authorCount: mapStatCount(statsFromAPI.data.author_count),
		engagementCount: mapStatCount(statsFromAPI, "engagement"),
		postCount: mapStatCount(statsFromAPI, "postCount"),
		threadCount: mapStatCount(statsFromAPI, "comment"),
		viewCount: mapStatCount(statsFromAPI, "view"),
	} as Stats
}

export function mapStatCount(statList: any, type?:any) {
	let engagementCount = 0
	let postCount = 0
	let threadCount = 0
	let viewCount = 0
	statList?.forEach(item => {
		if(item?.engagement_score){
			engagementCount += item?.engagement_score
		}
		let fb_post_count = 0
		let ig_post_count = 0
		let yt_post_count = 0
		if(item?.fb_post_count){
			fb_post_count = item?.fb_post_count
		}
		if(item?.fb_post_count){
			fb_post_count = item?.fb_post_count
		}
		if(item?.ig_post_count){
			ig_post_count = item?.ig_post_count
		}
		if(item?.yt_post_count){
			yt_post_count = item?.yt_post_count
		}
		
		postCount += fb_post_count + ig_post_count + yt_post_count
		threadCount += item?.fb_comment_count || 0 + item?.ig_comment_count || 0 + item?.yt_comment_count ||0
		viewCount += item?.yt_view_count || 0
	})
	if(type === "engagement"){
		return {
			total: engagementCount,
			// previous: statCount.prev_avg,
		} as StatCount
	} else if(type === "postCount"){
		return {
			total: postCount,
			// previous: statCount.prev_avg,
		} as StatCount
	}  else if(type === "comment"){
		return {
			total: threadCount,
			// previous: statCount.prev_avg,
		} as StatCount
	} else if(type === "view"){
		return {
			total: viewCount,
			// previous: statCount.prev_avg,
		} as StatCount
	}
	// return {
	// 	total: statCount.total,
	// 	// previous: statCount.prev_avg,
	// } as StatCount
}

export function mapPostCountChart(
	postCountChartFromAPI: any
) {

	return postCountChartFromAPI.map(
		(point) => {
			let engagementCount = 0
			let postCount = 0
			let threadCount = 0
			let viewCount = 0
			engagementCount = point?.engagement_score || 0
			if(point?.fb_post_count){
				postCount = point?.fb_post_count
			} 
			if(point?.ig_post_count){
				postCount += point?.ig_post_count
			}
			if(point?.yt_post_count){
				postCount += point?.yt_post_count
			}
			if(point?.fb_comment_count){
				threadCount = point?.fb_comment_count
			} 
			if(point?.ig_comment_count){
				threadCount += point?.ig_comment_count
			}
			if(point?.yt_comment_count){
				threadCount += point?.yt_comment_count
			}
		// postCount = point?.fb_post_count || 0 + point?.ig_post_count || 0 + point?.yt_post_count
		// threadCount = point?.fb_comment_count || 0 + point?.ig_comment_count || 0 + point?.yt_comment_count || 0
			return {
				point: moment(point?.date).unix()*1000,
				postCount: postCount,
				engagementCount: engagementCount,
				commentCount:threadCount,
				topThreads: [],
			}
		}
			
	)
}

export function mapWordCloud(wordCloudFromAPI: WordCloudFromAPI) {
	return Object.keys(wordCloudFromAPI.data).map((key) => ({
		word: key,
		count: wordCloudFromAPI.data[key] as number,
	}))
}

export const convert_facebook_keys_to_expected = (facebook_data) => {

    let mapped_data = {
        'post_timestamp': facebook_data["ts_iso_8601"] || facebook_data["ts"],
        'engagement': 
            facebook_data['reaction_like_count'] || 0 +
            facebook_data['reaction_love_count']  || 0 +
            facebook_data['reaction_haha_count'] || 0 +
            facebook_data['reaction_wow_count'] || 0 +
            facebook_data['reaction_sad_count'] || 0 +
            facebook_data['reaction_angry_count'] || 0 +
            facebook_data['comment_count'] || 0 +
            facebook_data['share_count'] || 0
        ,
        'reaction_count': 
            facebook_data['reaction_like_count'] +
            facebook_data['reaction_love_count'] +
            facebook_data['reaction_haha_count']+
            facebook_data['reaction_wow_count'] +
            facebook_data['reaction_sad_count'] +
            facebook_data['reaction_angry_count'],
        'comment_count': facebook_data['comment_count'],
        'share_count': facebook_data['share_count'],
        'view_count': null,
        'author_name': facebook_data['from_name'],
        'post_link': facebook_data['website'],
        'site': 'Facebook',
        'channel': null,
        'post_message': facebook_data['text'],
        'thread_title': facebook_data['text']
    }

    return mapped_data
}

export const convert_facebook_keys_to_kol = (facebook_data) => {
	
		let mapped_data = {
			'engagement': 
				facebook_data['reaction_like_count'] || 0 +
				facebook_data['reaction_love_count']  || 0 +
				facebook_data['reaction_haha_count'] || 0 +
				facebook_data['reaction_wow_count'] || 0 +
				facebook_data['reaction_sad_count'] || 0 +
				facebook_data['reaction_angry_count'] || 0 +
				facebook_data['comment_count'] || 0 +
				facebook_data['share_count'] || 0
			,
			'reaction_all_count': 
				facebook_data['reaction_like_count'] +
				facebook_data['reaction_love_count'] +
				facebook_data['reaction_haha_count']+
				facebook_data['reaction_wow_count'] +
				facebook_data['reaction_sad_count'] +
				facebook_data['reaction_angry_count'],
			'reaction_count':  facebook_data['reaction_all_count'],
			'comment_count': facebook_data['comment_count'],
			'share_count': facebook_data['share_count'],
			'view_count': null,
			'site': 'Facebook',
			'follower_count': facebook_data['follower_count'],
			'post_count': facebook_data['post_count'],
			'name': facebook_data['name'],
			'reaction_like_count': facebook_data['reaction_like_count'],
			'reaction_love_count': facebook_data['reaction_love_count'],
			'reaction_haha_count': facebook_data['reaction_haha_count'],
			'reaction_wow_count': facebook_data['reaction_wow_count'],
			'reaction_sad_count': facebook_data['reaction_sad_count'],
			'reaction_angry_count': facebook_data['reaction_angry_count'],
			'channel_id': facebook_data["page_id"],
			'link': `https://www.facebook.com/${facebook_data["page_id"]}`
	
		}
	
		return mapped_data
	
	
}

export const convert_instagram_keys_to_kol = (instagram_data) => {
	let mapped_data = {
        'engagement': calculate_instagram_engagement(instagram_data),
        'reaction_count': instagram_data['like_count'],
		'reaction_all_count':  instagram_data['like_count'],
        'comment_count': instagram_data['comment_count'],
        'share_count': null,
        'view_count':null,
        'site': 'Instagram',
		'follower_count': instagram_data['follower_count'],
		'post_count': instagram_data['post_count'],
		'name': instagram_data['name'],
		'reaction_like_count': instagram_data['like_count'],
		'channel_id': instagram_data["username"],
		'link': `https://www.instagram.com/${instagram_data["username"]}`
    }

    return mapped_data
}

export const convert_youtube_keys_to_kol = (youtube_data) => {

    let mapped_data = {
        'engagement': calculate_youtube_engagement(youtube_data),
        'reaction_count': youtube_data['like_count'],
		'reaction_all_count':  youtube_data['like_count'],
        'comment_count': youtube_data['comment_count'],
        'share_count':null,
        'view_count': youtube_data['view_count'],
        'site': 'YouTube',
		'follower_count': youtube_data['subscriber_count'],
		'post_count': youtube_data['post_count'],
		'name': youtube_data['channel_name'],
		'reaction_like_count': youtube_data['like_count'],
		'reaction_dislike_count': youtube_data['dislike_count'],
		'channel_id': youtube_data["channel_name"],
		'link': `https://www.youtube.com/@${youtube_data["channel_name"]}`

    }

    return mapped_data
}

const calculate_instagram_engagement = (row) => {
	let like_count = row['like_count']
    let comment_count = row['comment_count']
    let engagement_rate = parseInt(like_count  || 0 + comment_count  || 0)
    return engagement_rate
}
  

export const convert_instagram_keys_to_expected = (instagram_data) => {

    let mapped_data = {
        'post_timestamp': instagram_data["ts_iso_8601"] || instagram_data["ts"],
        'engagement': calculate_instagram_engagement(instagram_data),
        'reaction_count': instagram_data['like_count'],
        'comment_count': instagram_data['comment_count'],
        'share_count': null,
        'view_count':null,
        'author_name': instagram_data['from_name'] || instagram_data['username'],
        'post_link': instagram_data['permalink'] || instagram_data['url'],
        'site': 'Instagram',
        'channel': instagram_data['username'],
        'post_message': instagram_data['text'],
        'thread_title': instagram_data['text']
    }

    return mapped_data
}


const calculate_youtube_engagement = (row) => {
	let like_count = row['like_count']
    let comment_count = row['comment_count']
    let engagement_rate = parseInt(like_count  || 0 + comment_count  || 0)
    return engagement_rate
}
   

export const convert_youtube_keys_to_expected = (youtube_data) => {

    let mapped_data = {
        'post_timestamp': youtube_data["ts_iso_8601"] || youtube_data["ts"],
        'engagement': calculate_youtube_engagement(youtube_data),
        'reaction_count': youtube_data['like_count'],
        'comment_count': youtube_data['comment_count'],
        'share_count':null,
        'view_count': youtube_data['view_count'],
        'author_name': youtube_data['from_name'],
        'post_link': youtube_data['url'] || youtube_data['permalink'],
        'site': 'YouTube',
        'channel': youtube_data['fromname_t'],
        'post_message': youtube_data['description'],
        'thread_title': youtube_data['title']
    }

    return mapped_data
}



  


export function mapTopEngagementThreads(
	allConverertedData: any
) {


	const orderData = orderBy(allConverertedData, ["engagement"], "desc")
	console.log("Orderred Data", orderData)
	return orderData.map(
		(thread, index) =>
			({
				title: thread.thread_title,
				url: thread.post_link,
				medium: thread.site,
				site: thread.site,
				engagementCount: thread.engagement,
				rank: index,
			} as TopEngagementThread)
	)
}

export function mapTopGrowthThreads(
	topGrowthThreadsFromAPI: TopGrowthThreadsFromAPI
) {
	return topGrowthThreadsFromAPI.data.map(
		(thread) =>
			({
				title: thread.thread_title,
				url: thread.thread_link,
				site: thread.site,
				roc: thread.roc,
				hash: thread.hash,
			} as TopGrowthThread)
	)
}

export function mapTopTrends(topTrends: TopTrendsFromAPI) {
	if (topTrends.data && topTrends.data.length > 0) {
		return [
			{
				name: topTrends.data[0].term[0]?.name,
				result: topTrends.data[0].term[0]?.result?.map((trend) => ({
					doc_freq: trend.doc_freq,
					medium: trend.medium,
					related_word: trend.related_word,
					date: trend.run_date,
					ai_score: trend.ai_score,
					word: trend.word,
				})),
			},
		] as TopTrend[]
	}

	throw new Error("no data")
}

export function mapHidden(hidden: HiddenFromAPI) {
	return hidden.data.hide
		? hidden.data.hide
		: {
				keywords: [],
				topics: [],
				sites: [],
		  }
}

export function mapSentimentsFromAPI(sentimentsFromAPI: any) {
	// if (
	// 	sentimentsFromAPI.data.every(
	// 		(item) => item.negative === 0 && item.neutral === 0 && item.positive === 0
	// 	)
	// )
	// 	return [] as Sentiment[]
	let list = []
	sentimentsFromAPI.forEach((item) => {
		const positive_count = item?.fb_post_positive_count || 0 + item?.ig_post_positive_count || 0 + item?.yt_post_positive_count || 0;
		const negative_count = item?.fb_post_negative_count || 0 + item?.ig_post_negative_count || 0 + item?.yt_post_negative_count || 0;
		const neutal_count = item?.fb_post_neutral_count || 0 + item?.ig_post_neutral_count || 0 + item?.yt_post_neutral_count || 0;
		list.push({
			timestamp: moment(item?.date).unix()*1000,
			positive: positive_count,
			neutral: negative_count,
			negative: neutal_count,
		})
	})
	return list as Sentiment[]
}
