import React, { useEffect, useState } from 'react'
import { SectionBody, SectionHeader, SectionRoot } from '../styled'
import SingleSelect from '../../../reusable/inputs/select/singleSelect'
import ChannelList from './ChannelList'
import { usePostsStore } from '../../postStore'
import { ChannelsForTopChannels, TopChannelsInterface } from '../../interface'
import { sortChannelRanking } from '../../helperFn/sortSiteRankingFn'

function TopChannels() {
    const { overviewPost } = usePostsStore(state => ({
        overviewPost: state.overviewPost,
    }))

    const [channels, setChannels] = useState<ChannelsForTopChannels[]>([])
    const [viewBy, setViewBy] = useState<keyof TopChannelsInterface>("engagement")
    
    const viewOptions = [{
        label: "Total Engagement", 
        value: "engagement" 
    }, 
    // { 
       
    //     label: "No. of threads and comments", 
    //     value: "total" 
    // }, { 
    //     label: "No. of threads", 
    //     value: "threads" 
    // },
    { 
        label: "No. of comments", 
        value: "comments" 
    },
]
    const selectedLabel = viewOptions.filter((option)=>(option.value === viewBy))[0].label

    useEffect(()=>{
        const allChannels = overviewPost && overviewPost.aggregations.top_channels[viewBy].map((channel)=>(
            {
                site: channel.site,
                name: channel.channel_name,
                count: channel.engagement_score? channel.engagement_score : channel.count,
                link: channel.channel_link
            }
        )).sort(sortChannelRanking)
        allChannels && allChannels.length > 0 && setChannels(allChannels)
    },[overviewPost, viewBy])

    return (
        <SectionRoot>
            <SectionHeader>
                <h3>Top Channels by Activity</h3>
                <div>
                    <SingleSelect
                        id="top-channels-view-by"
                        options={viewOptions}
                        value={selectedLabel}
                        setValue={(value) => setViewBy(value as keyof TopChannelsInterface)}
                        width={180}
                        label="View by:"
                    />
                </div>
            </SectionHeader>
            <SectionBody expanded={true}>
                <ChannelList 
                    channels={channels} 
                    maxCount={channels[0]?.count || 0}
                />
            </SectionBody>
        </SectionRoot>
    )
}

export default React.memo(TopChannels)
