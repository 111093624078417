import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectPostCountChart, selectDateRange, showChartData, selectOverviewFilter } from "../../../../../../app/store/overviewSlice"
import styled from 'styled-components'
import { useWindowSize } from "react-use"
import GraphHeader from "./GraphHeader"
import LineChartSkeleton from "./Skeleton"
import { CartesianGrid, Tooltip, XAxis, ResponsiveContainer, YAxis, AreaChart, Area } from "recharts"
import CustomTooltip from "./CustomTooltip"
import EmptyStatePlaceholder from "../../components/EmptyStatePlaceholder"
import { selectFeedType } from "../../../../../../app/store/feedSlice"
import { formatAdHocChartPoints, formatLiveChartPoints, getDataKey } from "./helper"
import { IntervalContext } from "../../context/IntervalContext"
import useInterval from "./hook/useInterval"
import { generateDateRangeInterval, generateTimeRange } from "../../../../../../app/tools/dateRangeConverter"

export enum LineGraphViewBY {
    engagement = "Engagements",
    comment = "Comments",
    post = "Engagements and comments"
}

const LineGraphRoot = styled.div`
    border-radius: 3px;
    background-color: white;
    padding: 1rem;
`

const LineGraphBody = styled.div`
    width: 100%;
`

const LineGraph = () => {
    const dispatch = useDispatch()

    const feedType = useSelector(selectFeedType)
    const intervalObject = useInterval()

    const dateRange = useSelector(selectDateRange)
    const customDateRange = useSelector(selectOverviewFilter)
    const postCountChartPoints = useSelector(selectPostCountChart)

    const [viewBy, setViewBy] = useState(LineGraphViewBY.engagement)
    const { height } = useWindowSize()

    const formatted = feedType === 0 ? formatLiveChartPoints(postCountChartPoints, dateRange) : formatAdHocChartPoints(postCountChartPoints, intervalObject.interval)

    const handleDotClick = (e: any) => {
        if (feedType === 0) {
            const [from, to] = generateTimeRange(e.payload.point as string, dateRange)
            dispatch(showChartData({
                start: from,
                end: to
            }))
        }

        if (feedType === 1 && intervalObject.interval) {
            const [from, to] = generateDateRangeInterval(e.payload.point as string, intervalObject.interval)
            dispatch(showChartData({
                start: from,
                end: to
            }))
        }

    }



    return (
        <IntervalContext.Provider value={intervalObject}>
            <LineGraphRoot>
                <GraphHeader
                    dateRange={dateRange}
                    customDateRange={customDateRange}
                    viewBy={viewBy}
                    setViewBy={setViewBy}
                    feedType={feedType!}
                />
                <LineGraphBody >
                    {
                        postCountChartPoints ? (postCountChartPoints.length > 0 ?
                            <ResponsiveContainer width={"100%"} height={height * 0.3}>
                                <AreaChart data={formatted}>
                                    <CartesianGrid vertical={false} />
                                    <YAxis
                                        axisLine={{ stroke: '#D3D2E0' }}
                                        tick={{ fontSize: '14px' }}
                                        tickLine={{ stroke: '#D3D2E0' }}
                                        tickSize={12}
                                    />
                                    <XAxis
                                        dataKey="label"
                                        axisLine={{ stroke: '#D3D2E0' }}
                                        tick={{ fontSize: '14px' }}
                                        tickLine={{ stroke: '#D3D2E0' }}
                                        tickSize={12}
                                    />
                                    <Tooltip content={(
                                        <CustomTooltip
                                            viewBy={viewBy}
                                            dateRange={dateRange}
                                            interval={intervalObject.interval}
                                            feedType={feedType}
                                        />
                                    )} />
                                    <Area
                                        type="monotone"
                                        dot={false}
                                        dataKey={getDataKey(viewBy)}
                                        stroke="#29d1d7"
                                        strokeWidth={3}
                                        fill="#29d1d7"
                                        
                                        fillOpacity={0.3}
                                        activeDot={{
                                            onClick: handleDotClick,
                                            r: 8,
                                            strokeWidth: 1.5,
                                            cursor:"pointer"
                                        }}
                                    />
                                </AreaChart>
                            </ResponsiveContainer>
                            : <EmptyStatePlaceholder />) :
                            <LineChartSkeleton />
                    }
                </LineGraphBody>
            </LineGraphRoot >
        </IntervalContext.Provider>
    )
}

export default LineGraph