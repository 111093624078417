import React from "react"
import { useSelector } from "react-redux"
import styled from 'styled-components'
import TaskList from "./tasklist"
import { selectFeedTaskAccess } from "../../../app/store/feedSlice"
import CreateFeedButton from "../create/button"
import Empty from "./empty"
import { selectCredits } from "../../../app/store/userSlice"

const DashboardRoot = styled.div`
    display: grid;
    padding: 3rem;
    grid-template-rows: min-content auto;
    min-width: 960px;
    overflow: auto;
`

const Header = styled.div`
    display: flex;
    align-items: center;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    column-gap: 1rem;
    >h1 {
        font-weight: 500;
        font-size: 1.5rem;
    }
    >* {
        align-self: center;
    }
`

const FeedDashboard = () => {
    const liveTasks = useSelector(selectFeedTaskAccess)
    console.log("liveTasks", liveTasks)
    const credits = useSelector(selectCredits);
    return (
        <DashboardRoot>
            <Header>
                <h1>My Tasks</h1>
                <div style={{flex:"1 1 auto"}}>
                <CreateFeedButton />
                </div>
              
                <div style={{paddingRight:"3rem"}}>Total Credits remaining: <b>{credits}</b></div>
            </Header>
            {liveTasks && liveTasks.length > 0 ? <TaskList tasks={liveTasks} /> : <Empty />}
        </DashboardRoot>
    )
}

export default FeedDashboard
