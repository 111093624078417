import React from 'react'
import { Switch, BrowserRouter as Router, Route } from "react-router-dom"
import styled from 'styled-components'
import Home from "./home"
import TrendOverview from "./trendOverview"

const TrendRoot = styled.div`
  background-color: #F8F8FA;
`

function Trend() {
  return (
    <TrendRoot>
      <Router>
        <Switch>
          <Route path="/gptTrend/:topic" component={TrendOverview} />
          <Route path="/gptTrend" exact component={Home} />
        </Switch>
      </Router>
    </TrendRoot>
  )
}

export default Trend
