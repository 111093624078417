import React from 'react'
import styled, { css } from 'styled-components'
import Skeleton from '../../../reusable/utils/Skeleton'
import { usePostsStore } from '../../postStore'
import { overviewData } from '..'

const PickerRoot = styled.section`
  width: 100;
  display: flex;
`

const TopicView = styled.div<{ disabled: boolean, borderRight?: boolean }>`
  width: 50% ;
  height: 4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${props => props.disabled ? "#FAFAFA" : "#FFFFFF"};
  padding: 1rem 2rem;
  border-bottom: 1px solid #DADADA;
  ${props => props.borderRight && css`
    border-right: 1px solid #DADADA;
  `}

  h2 {
    font-size: 1.2rem;
    color: ${props => props.disabled ? "#D3D2E0" : "#333333"};
    margin-right: 1rem;
  }
`

// const StatusView = styled.span`
//   color: white;
//   padding: 0.2rem 1rem;
//   border-radius: 4px;
//   background-color: #25BEF9;
//   font-size: 12px;
// `

function TopicPicker() {
  const { 
    topicWord, 
    topicRelatedWord,
    overview
  } = usePostsStore(state => ({
    topicWord: state.overviewPost?.topic?.word,
    topicRelatedWord: state.overviewPost?.topic?.related_word?.[0],
    overview: state.overviewPost
  }))

  // const topicWord = overviewData?.topic?.word
  //  const  topicRelatedWord = overviewData?.topic?.related_word?.[0]




  return (
    <PickerRoot>
      <TopicView disabled={false} borderRight={true}>
        {topicWord ? (
          <h2>{topicWord} &bull; {topicRelatedWord}</h2>
        ):(
          <>
            <Skeleton height="1rem" width="4rem" margin="0 10px" />
            <Skeleton height="1rem" width="4rem" margin="0 10px" />
          </>
        )}
        {/* <StatusView>Rising</StatusView> */}
      </TopicView>
      <TopicView 
        disabled={true}
        data-tip="Upcoming feature"
        data-place="bottom"
        data-effect="solid"
        data-html={true}
      >
        <h2>+ Compare</h2>
        {/* <StatusView>Rising</StatusView> */}
      </TopicView>
    </PickerRoot>

  )
}

export default React.memo(TopicPicker)
