import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, store } from "."
import { IrrelevantPost, ModelCounts, ModelType, SmartTrainState, SpamRecord, TaskAI, TaskAIResponse } from "../interfaces/smartTrain"
import { ajax } from "usefuljs"
import { FeedData } from "../interfaces/feed"
import { FetchTrainingHistoryFromAPI, UserInfoFromAPI } from "../interfaces/ResponseFromAPIs"
import { mapFeedData } from "../tools/mapping/smartTrainMapping"

const initialRelevancy = {
  tagHistory: [],
  trainingData: null,
  validationData: null,
  isEditingHistory: false,
  currentDisplayPost: null,
  currentIndex: 0,
  hasModel: false,
  isTraining: false,
  shouldCreateModel: null,
  counts: null
}

const initialSentiment = {
  tagHistory: [],
  trainingData: null,
  validationData: null,
  isEditingHistory: false,
  currentDisplayPost: null,
  currentIndex: 0,
  hasModel: false,
  isTraining: false,
  shouldCreateModel: null,
  counts: null
}

const initialState: SmartTrainState = {
  irrelevantPosts: [],
  showIrrelevantManager: false,
  showSmartTrainManager: false,
  showHistoryTab: false,
  showModelSelector: true,
  displaySmartTrainPrompt: false,
  chosenIrrelevant: [],
  isLoading: true,
  isConfirming: false,
  mode: "relevancy",
  models: [],
  relevancy: initialRelevancy,
  sentiment: initialSentiment
}

export const ModelNameToAPIMapping = {
  relevancy: "spam",
  sentiment: "sentiment"
}


export const fetchIrrelevant = createAsyncThunk("feed/posts/fetchIrrelevant", async (tagBy: string) => {
  try {
    const taskId = store.getState().feed.task_id
    const res: { data: IrrelevantPost[], result: string } = await ajax({
      method: "get",
      headers: { token: store.getState().user.token },
      url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/spam/list?from=0&to=${Date.now()}&type=${tagBy}`
    })

    if (res.result !== "success") { throw new Error("failed to fetch irrelevant") }

    if (res.data) {
      return res.data
    }

    throw new Error("No irrelevant posts")

  } catch (err) {
    console.error(err)
    return [] as IrrelevantPost[]
  }
})

export const untagIrrelevant = createAsyncThunk("feed/posts/untagIrrelevant", async (_, thunkAPI) => {
  try {
    const taskId = store.getState().feed.task_id
    const res: { data: IrrelevantPost[], result: string } = await ajax({
      method: "post",
      headers: { token: store.getState().user.token },
      url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/spam/mtag`,
      data: store.getState().smartTrain.chosenIrrelevant.map(item => ({
        live_sid: `${taskId}_${item}`,
        spam: 0
      }))
    })

    if (res.result !== "success") { throw new Error("failed to untag irrelevant") }

    const irrelevantPosts = store.getState().smartTrain.irrelevantPosts
    const chosenIrrelevant = store.getState().smartTrain.chosenIrrelevant

    thunkAPI.dispatch(setChosenIrrelevant([]))
    thunkAPI.dispatch(setIrrelevant([...irrelevantPosts].filter(post => !chosenIrrelevant.includes(post.hash))))


  } catch (err) {
    console.error(err)
    return [] as IrrelevantPost[]
  }
})

export const fetchTrainingData = createAsyncThunk("feed/posts/fetchTrainingData", async () => {
  try {
    const taskId = store.getState().feed.task_id
    const mode = store.getState().smartTrain.mode
    const res: { data: FeedData[], result: string } = await ajax({
      method: "get",
      headers: { token: store.getState().user.token },
      url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/smart_train/${ModelNameToAPIMapping[mode]}/data`,
    })

    if (res.result !== "success") { throw new Error("failed to fetch training data") }

    return res.data ? mapFeedData(res.data) : []

  } catch (err) {
    console.error(err)
    return [] as FeedData[]
  }
})


export const fetchValidationData = createAsyncThunk("feed/posts/fetchValidationData", async () => {
  try {
    const taskId = store.getState().feed.task_id
    const { mode } = store.getState().smartTrain
    const res: { data: FeedData[], result: string } = await ajax({
      method: "get",
      headers: { token: store.getState().user.token },
      url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/smart_train/${ModelNameToAPIMapping[mode]}/validation/data`,
    })

    if (res.result !== "success") { throw new Error("failed to fetch training data") }

    return res.data ? mapFeedData(res.data) : []

  } catch (err) {
    console.error(err)
    return [] as FeedData[]
  }
})

export const tagTrainingItem = createAsyncThunk("feed/posts/tagTrainingItem", async (tag: number, thunkAPI) => {
  try {
    const taskId = store.getState().feed.task_id
    const { mode, relevancy, sentiment } = store.getState().smartTrain
    const current = mode === "relevancy" ? relevancy : sentiment
    const currentDisplayPost = current.currentDisplayPost

    if (currentDisplayPost !== null) {
      const data = {
        live_sid: `${taskId}_${currentDisplayPost.hash}`,
        tag,
        data: currentDisplayPost
      }

      const res: { result: string } = await ajax({
        method: "post",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/smart_train/${ModelNameToAPIMapping[mode]}/cache/set`,
        data
      })

      if (res.result !== "success") { throw new Error("failed to tag training item") }

      if (current.isEditingHistory) {
        thunkAPI.dispatch(updateHistoryRecord({ id: `${taskId}_${currentDisplayPost.hash}`, tag }))
      } else {
        thunkAPI.dispatch(pushCurrentToHistory({
          ...data,
          task_id: taskId
        }))
      }

      if (current.trainingData && current.currentIndex === current.trainingData.length - 1) {
        thunkAPI.dispatch(fetchTrainingData())
      }

      thunkAPI.dispatch(setNextCurrent())
    }

  } catch (err) {
    console.error(err)
  }
})

export const fetchTrainingHistory = createAsyncThunk("feed/posts/fetchTrainingHistory", async () => {
  try {
    const taskId = store.getState().feed.task_id
    const { mode } = store.getState().smartTrain
    const res: FetchTrainingHistoryFromAPI = await ajax({
      method: "get",
      headers: { token: store.getState().user.token },
      url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/smart_train/${ModelNameToAPIMapping[mode]}/cache/get`,
    })

    if (res.result !== "success") { throw new Error("failed to tag spam") }
    return res.data as SpamRecord[]
  } catch (err) {
    console.error(err)
    return [] as SpamRecord[]
  }
})

export const setValidation = createAsyncThunk("feed/posts/setValidation", async (tag: number, thunkAPI) => {
  try {

    const taskId = store.getState().feed.task_id
    const { mode, relevancy, sentiment } = store.getState().smartTrain
    const current = mode === "relevancy" ? relevancy : sentiment
    const { currentDisplayPost, isEditingHistory, validationData, currentIndex } = current

    if (currentDisplayPost !== null) {
      if (isEditingHistory) {
        thunkAPI.dispatch(updateHistoryRecord({ id: `${taskId}_${currentDisplayPost.hash}`, tag }))
      } else {
        thunkAPI.dispatch(pushCurrentToHistory({
          task_id: taskId,
          live_sid: `${taskId}_${currentDisplayPost.hash}`,
          tag,
          data: currentDisplayPost
        } as SpamRecord))
      }

      if (validationData && currentIndex === validationData.length - 1) {
        thunkAPI.dispatch(fetchValidationData())
      }

      thunkAPI.dispatch(setNextCurrent())
    }

  } catch (err) {
    console.error(err)
  }
})

export const triggerValidation = createAsyncThunk("feed/posts/triggerValidation", async () => {
  try {

    const taskId = store.getState().feed.task_id
    const { mode, sentiment, relevancy } = store.getState().smartTrain
    const current = mode === "relevancy" ? relevancy : sentiment
    const tagHistory = current.tagHistory

    if (!tagHistory.length) return null

    const res: { result: string } = await ajax({
      method: "post",
      headers: { token: store.getState().user.token },
      url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/smart_train/${ModelNameToAPIMapping[mode]}/validation/set`,
      data: current.tagHistory
    })

    if (res.result !== "success") { throw new Error("failed to validate spam") }


  } catch (err) {
    console.error(err)
  }
})

export const fetchModelStatus = createAsyncThunk("feed/posts/fetchModelStatus", async () => {
  try {
    const taskId = store.getState().feed.task_id

    const res: TaskAIResponse = await ajax({
      method: "get",
      headers: { token: store.getState().user.token },
      url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/ai`,
    })

    // if (res.result !== "success") { throw new Error("failed to fetch model status") }

    return []

  } catch (err) {
    console.error(err)
  }
})

export const getDisplaySmartTrainPrompt = createAsyncThunk("feed/getDisplaySmartTrainPrompt", async () => {
  try {
    const taskId = store.getState().feed.task_id
    const res: UserInfoFromAPI = await ajax({
      method: "get",
      headers: { token: store.getState().user.token },
      url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/user_info`
    })

    if (res.result !== "success") throw new Error("failed to fetch smart train prompt")

    return res.data.display_smart_train_prompt ?? false

  } catch (error) {
    console.error(error)
    return false
  }
})

export const unsubscribeSmartTrainPrompt = createAsyncThunk("feed/unsubscribeSmartTrainPrompt", async (_, thunkAPI) => {
  try {
    const taskId = store.getState().feed.task_id
    const res: UserInfoFromAPI = await ajax({
      method: "post",
      headers: { token: store.getState().user.token },
      url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/user_info`,
      data: {
        display_smart_train_prompt: false
      }
    })

    if (res.result !== "success") throw new Error("failed to unsubscribe from smart train prompt")

    if (res.data.display_smart_train_prompt !== store.getState().smartTrain.displaySmartTrainPrompt) {
      thunkAPI.dispatch(setDisplaySmartTrainPrompt(res.data.display_smart_train_prompt ?? false))
    }

  } catch (error) {
    console.error(error)
  }
})

export const resetModel = createAsyncThunk("feed/resetModel", async (_, thunkAPI) => {
  try {
    const taskId = store.getState().feed.task_id
    const { mode } = store.getState().smartTrain
    const res: { result: string } = await ajax({
      method: "delete",
      headers: { token: store.getState().user.token },
      url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/smart_train/${ModelNameToAPIMapping[mode]}/reset`,
    })

    if (res.result !== "success") throw new Error("failed to reset the")

  } catch (error) {
    console.error(error)
  }
})

export const fetchModelsCounts = createAsyncThunk("feed/fetchModelsCounts", async (_, thunkAPI) => {
  try {
    const taskId = store.getState().feed.task_id
    const sentimentCount: {
      counts: {
        total: number
        trained: number
        validating: number
      }
      result: string
    } = await ajax({
      method: "get",
      headers: { token: store.getState().user.token },
      url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/smart_train/${ModelNameToAPIMapping["sentiment"]}/counts`,
    })

    const relevancyCount: {
      counts: {
        total: number
        trained: number
        validating: number
      }
      result: string
    } = await ajax({
      method: "get",
      headers: { token: store.getState().user.token },
      url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/smart_train/${ModelNameToAPIMapping["relevancy"]}/counts`,
    })

    if (sentimentCount.result !== "success") throw new Error("failed to fetch the sentiment counts")
    if (relevancyCount.result !== "success") throw new Error("failed to fetch the relevancy counts")

    if (sentimentCount.counts) {
      thunkAPI.dispatch(setModelCounts({ model: "sentiment", counts: sentimentCount.counts }))

      if (sentimentCount.counts.total === 0 || sentimentCount.counts.trained === 0) {
        thunkAPI.dispatch(setShouldCreateModel("sentiment"))
      } else {
        thunkAPI.dispatch(setShouldNotCreateModel("sentiment"))
      }
    }

    if (relevancyCount.counts) {
      thunkAPI.dispatch(setModelCounts({ model: "relevancy", counts: relevancyCount.counts }))

      if (relevancyCount.counts.total === 0 || relevancyCount.counts.trained === 0) {
        thunkAPI.dispatch(setShouldCreateModel("relevancy"))
      } else {
        thunkAPI.dispatch(setShouldNotCreateModel("relevancy"))
      }
    }

  } catch (error) {
    console.error(error)
  }
})

// slice
export const SmartTrainSlice = createSlice({
  name: "smartTain",
  initialState,
  reducers: {
    setIrrelevant: (state, action: PayloadAction<IrrelevantPost[]>) => {
      state.irrelevantPosts = action.payload
    },
    setShowIrrelevantManager: (state, action: PayloadAction<boolean>) => {
      state.showIrrelevantManager = action.payload
    },
    setShowSmartTrainManager: (state, action: PayloadAction<boolean>) => {
      state.showSmartTrainManager = action.payload

      if (action.payload === false) {
        state.isConfirming = false
        state.showModelSelector = true
        state.showHistoryTab = false
      }
    },
    setDisplaySmartTrainPrompt: (state, action: PayloadAction<boolean>) => {
      state.displaySmartTrainPrompt = action.payload
    },
    setShowHistoryTab: (state, action: PayloadAction<boolean>) => {
      state.showHistoryTab = action.payload
    },
    setShowModelSelector: (state, action: PayloadAction<boolean>) => {
      state.showModelSelector = action.payload
    },
    setChosenIrrelevant: (state, action: PayloadAction<string[]>) => {
      state.chosenIrrelevant = action.payload
    },
    clearIrrelevant: (state) => {
      state.irrelevantPosts = []
    },
    setNextCurrent: (state) => {
      if (state.mode === "relevancy") {

        if (state.relevancy.shouldCreateModel) {
          if (state.relevancy.trainingData && state.relevancy.currentIndex !== state.relevancy.trainingData.length - 1) {
            state.relevancy.currentDisplayPost = state.relevancy.trainingData[++state.relevancy.currentIndex]
          }

        } else {
          if (state.relevancy.validationData && state.relevancy.currentIndex !== state.relevancy.validationData.length - 1) {
            state.relevancy.currentDisplayPost = state.relevancy.validationData[++state.relevancy.currentIndex]
          }
        }

      }


      if (state.mode === "sentiment") {

        if (state.sentiment.shouldCreateModel) {
          if (state.sentiment.trainingData && state.sentiment.currentIndex !== state.sentiment.trainingData.length - 1) {
            state.sentiment.currentDisplayPost = state.sentiment.trainingData[++state.sentiment.currentIndex]
          }
        } else {
          if (state.sentiment.validationData && state.sentiment.currentIndex !== state.sentiment.validationData.length - 1) {
            state.sentiment.currentDisplayPost = state.sentiment.validationData[++state.sentiment.currentIndex]
          }

        }
      }

      state.relevancy.isEditingHistory = false

    },
    setCurrentDisplayPost: (state, action: PayloadAction<FeedData>) => {
      if (state.mode === "relevancy") {
        state.relevancy.currentDisplayPost = action.payload
      } else {
        state.sentiment.currentDisplayPost = action.payload
      }
    },
    pushCurrentToHistory: (state, action: PayloadAction<SpamRecord>) => {
      if (state.mode === "relevancy") {
        state.relevancy.tagHistory = [action.payload, ...state.relevancy.tagHistory]
      }
      if (state.mode === "sentiment") {
        state.sentiment.tagHistory = [action.payload, ...state.sentiment.tagHistory]
      }
    },
    setIsEditingHistory: (state, action: PayloadAction<boolean>) => {
      if (state.mode === "relevancy") {
        state.relevancy.isEditingHistory = action.payload
      }
      if (state.mode === "sentiment") {
        state.sentiment.isEditingHistory = action.payload
      }
    },
    updateHistoryRecord: (state, action: PayloadAction<{ id: string, tag: number }>) => {
      if (state.mode === "relevancy") {
        state.relevancy.tagHistory = state.relevancy.tagHistory.map(item => {
          if (item.live_sid !== action.payload.id) {
            return item
          } else {
            return {
              ...item,
              tag: action.payload.tag
            }
          }
        })

        state.relevancy.isEditingHistory = false
      }

      if (state.mode === "sentiment") {
        state.sentiment.tagHistory = state.sentiment.tagHistory.map(item => {
          if (item.live_sid !== action.payload.id) {
            return item
          } else {
            return {
              ...item,
              tag: action.payload.tag
            }
          }
        })
        state.sentiment.isEditingHistory = false
      }

    },
    clearSmartTrain: (state) => {
      Object.assign(state, initialState)
    },
    clearTagHistory: (state) => {
      state.sentiment.tagHistory = []
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setHasModel: (state, action: PayloadAction<boolean>) => {
      if (state.mode === "relevancy") {
        state.relevancy.hasModel = action.payload
      }

      if (state.mode === "sentiment") {
        state.sentiment.hasModel = action.payload
      }
    },
    setSmartTrainMode: (state, action: PayloadAction<"relevancy" | "sentiment">) => {
      state.mode = action.payload
    },
    setIsConfirming: (state, action: PayloadAction<boolean>) => {
      state.isConfirming = action.payload
    },
    setIsTraining: (state, action: PayloadAction<boolean>) => {
      if (state.mode === "relevancy") {
        state.relevancy.isTraining = action.payload
      }

      if (state.mode === "sentiment") {
        state.sentiment.isTraining = action.payload
      }
    },
    setShouldCreateModel: (state, action: PayloadAction<ModelType>) => {
      if (action.payload === "relevancy") {
        state.relevancy.shouldCreateModel = true
      }

      if (action.payload === "sentiment") {
        state.sentiment.shouldCreateModel = true
      }
    },
    setShouldNotCreateModel: (state, action: PayloadAction<ModelType>) => {
      if (action.payload === "relevancy") {
        state.relevancy.shouldCreateModel = false
      }

      if (action.payload === "sentiment") {
        state.sentiment.shouldCreateModel = false
      }
    },
    setModelCounts: (state, action: PayloadAction<{ model: ModelType, counts: ModelCounts }>) => {
      if (action.payload.model === "sentiment") {
        state.sentiment.counts = action.payload.counts
      }

      if (action.payload.model === "relevancy") {
        state.relevancy.counts = action.payload.counts
      }
    }
  },
  extraReducers: {
    [fetchIrrelevant.fulfilled.toString()]: (state, action: PayloadAction<IrrelevantPost[]>) => {
      state.irrelevantPosts = action.payload
    },
    [fetchTrainingData.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [fetchTrainingData.fulfilled.toString()]: (state, action: PayloadAction<FeedData[]>) => {
      if (state.mode === "relevancy") {
        state.relevancy.trainingData = [...(state.relevancy.trainingData ?? []), ...action.payload]
        state.relevancy.currentDisplayPost = state.relevancy.trainingData[state.relevancy.currentIndex]
      }

      if (state.mode === "sentiment") {
        state.sentiment.trainingData = [...(state.sentiment.trainingData ?? []), ...action.payload]
        state.sentiment.currentDisplayPost = state.sentiment.trainingData[state.sentiment.currentIndex]
      }

      state.isLoading = false
    },
    [fetchTrainingData.rejected.toString()]: (state) => {
      state.isLoading = false
    },
    [fetchValidationData.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [fetchValidationData.fulfilled.toString()]: (state, action: PayloadAction<FeedData[]>) => {
      if (state.mode === "relevancy") {
        state.relevancy.validationData = [...(state.relevancy.validationData ?? []), ...action.payload]
        state.relevancy.currentDisplayPost = state.relevancy.validationData[state.relevancy.currentIndex]
      }

      if (state.mode === "sentiment") {
        state.sentiment.validationData = [...(state.sentiment.validationData ?? []), ...action.payload]
        state.sentiment.currentDisplayPost = state.sentiment.validationData[state.sentiment.currentIndex]
      }

      state.isLoading = false
    },
    [fetchValidationData.rejected.toString()]: (state) => {
      state.isLoading = false
    },
    [fetchTrainingHistory.fulfilled.toString()]: (state, action: PayloadAction<SpamRecord[]>) => {
      if (state.mode === "relevancy") {
        state.relevancy.tagHistory = action.payload
      }

      if (state.mode === "sentiment") {
        state.sentiment.tagHistory = action.payload
      }

    },
    [tagTrainingItem.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [tagTrainingItem.rejected.toString()]: (state) => {
      state.isLoading = false
    },
    [tagTrainingItem.fulfilled.toString()]: (state) => {
      state.isLoading = false
    },
    [fetchModelStatus.fulfilled.toString()]: (state, action: PayloadAction<{ [key: string]: TaskAI }>) => {
      if (action.payload["spam"]) {
        if (action.payload["spam"].training) {
          state.relevancy.isTraining = true
        } else {
          state.relevancy.hasModel = true
          state.models.push("relevancy")
        }
      } else {
        state.relevancy.hasModel = false
      }

      if (action.payload["sentiment"]) {
        if (action.payload["sentiment"].training) {
          state.sentiment.isTraining = true
        } else {
          state.sentiment.hasModel = true
          state.models.push("sentiment")
        }
      } else {
        state.sentiment.hasModel = false
      }
    },
    [getDisplaySmartTrainPrompt.fulfilled.toString()]: (state, action: PayloadAction<boolean>) => {
      state.displaySmartTrainPrompt = action.payload
    },
    [fetchModelsCounts.pending.toString()]: (state) => {
      state.isLoading = true
    },
    [fetchModelsCounts.fulfilled.toString()]: (state) => {
      state.isLoading = false
    },
    [fetchModelsCounts.rejected.toString()]: (state) => {
      state.isLoading = false
    }
  }
})

// actions
export const {
  setIrrelevant,
  setShowIrrelevantManager,
  setShowSmartTrainManager,
  setShowHistoryTab,
  setShowModelSelector,
  setChosenIrrelevant,
  clearIrrelevant,
  setNextCurrent,
  setCurrentDisplayPost,
  pushCurrentToHistory,
  setIsEditingHistory,
  updateHistoryRecord,
  clearSmartTrain,
  clearTagHistory,
  setIsLoading,
  setDisplaySmartTrainPrompt,
  setHasModel,
  setSmartTrainMode,
  setIsConfirming,
  setIsTraining,
  setShouldCreateModel,
  setShouldNotCreateModel,
  setModelCounts
} = SmartTrainSlice.actions

// selector
export const selectIrrelevant = (state: RootState) => state.smartTrain.irrelevantPosts
export const selectShowIrrelevantManager = (state: RootState) => state.smartTrain.showIrrelevantManager
export const selectShowSmartTrainManager = (state: RootState) => state.smartTrain.showSmartTrainManager
export const selectShowHistoryTab = (state: RootState) => state.smartTrain.showHistoryTab
export const selectChosenIrrelevant = (state: RootState) => state.smartTrain.chosenIrrelevant
export const selectSmartTrainMode = (state: RootState) => state.smartTrain.mode
export const selectShowModelSelector = (state: RootState) => state.smartTrain.showModelSelector
export const selectModelsAvailable = (state: RootState) => state.smartTrain.models
export const selectIsConfirming = (state: RootState) => state.smartTrain.isConfirming

export const selectIsLoading = (state: RootState) => state.smartTrain.isLoading
export const selectHasModel = (state: RootState) => state.smartTrain.models.length > 0
export const selectDisplaySmartTrainPrompt = (state: RootState) => state.smartTrain.displaySmartTrainPrompt

export const selectRelevancy = (state: RootState) => state.smartTrain.relevancy
export const selectSentiment = (state: RootState) => state.smartTrain.sentiment
export const selectCurrent = (state: RootState) => {
  switch (state.smartTrain.mode) {
    case "relevancy":
      return state.smartTrain.relevancy
    case "sentiment":
      return state.smartTrain.sentiment
  }
}

export default SmartTrainSlice.reducer