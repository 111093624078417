import React, { useState } from 'react'
import { useHistory } from 'react-router'
import MehIcon from '../../../../reusable/icons/MehIcon'
import WideAngleArrow from '../../../../reusable/icons/WideAngleArrow'
import { TopicData } from '../../../interface'
import { usePostsStore } from '../../../postStore'
import { CardBodyContent, CardBodyContainer, DetailsLink, ItemNumber, SummaryText, TitleText, CardRow, CardSentimentLoading } from '../styled'
import ExpandedCard from './ExpandedCard'
import SocialOutreach from './SocialOutreach'
import SentimentScale from './SentimentScale'
import TrendForecast from './TrendForecast'
import TrendIndex from './TrendIndex'
import MaturityTag from './MaturityTag'
import { MaturityColorMap } from '../../../trendOverview/models'

const CardBody = ({index, activeCardId, setActiveCardId, post, allTopicData}: CardBodyProps) => {
  const {
    isLoadingDetailed,
    frequency,
    labelId,
    setClickedPostFrequencies
  } = usePostsStore(state =>({
    isLoadingDetailed: state.isLoadingDetailed,
    frequency: state.frequency,
    labelId: state.labelId,
    setClickedPostFrequencies: state.setClickedPostFrequencies,
  }))

  const [isHovered, setHovered] = useState(false)

  const handleShrink = (e:React.MouseEvent<HTMLDivElement> ) => {
    console.log(post)
    e.stopPropagation()
    setActiveCardId(null)
  }

  const history = useHistory()

  const handleClickLink = () => {
    const frequencyKeys = post.briefData.frequencies
    setClickedPostFrequencies(frequencyKeys)
    history.push(`/gptTrend/${post.briefData.topic_id}?f=${frequency}&label=${labelId}`)
  }
  
  return (
    <>
      <CardBodyContainer 
        onMouseEnter={()=>setHovered(true)} 
        onMouseLeave={()=>setHovered(false)} 
        onClick={()=>setActiveCardId(post.briefData.topic_id)}
        isActive={activeCardId === post.briefData.topic_id}
      >
        <CardRow style={{height: activeCardId === post.briefData.topic_id ? '6rem':''}}>
          <CardBodyContent basis="1 0 0" flexDirection="row">
            <ItemNumber>{index + 1}</ItemNumber>
          </CardBodyContent>
          <CardBodyContent basis="15 0 0" flexDirection="column" padding="1.5rem 3rem 1.5rem 0.5rem" style={{cursor: 'pointer'}}>
            <TitleText>
              {post.briefData.data.word} &bull; {post.briefData.data.related_word[0]} 
              {/* {
                MaturityColorMap[post.briefData.data.maturity] &&
                <MaturityTag 
                  maturity={post.briefData.data.maturity} 
                  color={MaturityColorMap[post.briefData.data.maturity]!}
                />
              } */}
              { 
                (isHovered || activeCardId === post.briefData.topic_id) && post.detailData && !isLoadingDetailed && 
                  <DetailsLink onClick={handleClickLink}>See Details</DetailsLink> 
              }
            </TitleText> 

            {activeCardId !== post.briefData.topic_id && 
              <SummaryText>
                {post.briefData.summary.length > 60 ? (
                  `${post.briefData.summary.slice(0,60)}...`
                ):(
                  post.briefData.summary ? (
                    post.briefData.summary
                  ):(
                    <i>Selected post is not available.</i>
                  )
                )}
            </SummaryText>}
          </CardBodyContent>

          <CardBodyContent basis="3.5 0 0">
            {/* <TrendIndex trendIndex={post.briefData.data.ai_score} /> */}
          </CardBodyContent>

          <CardBodyContent basis="3 0 0">
            {/* <TrendForecast trendForecast={post.briefData.data.period} /> */}
          </CardBodyContent>

          <CardBodyContent basis="3 0 0">
            {!post.detailData ? (
              <CardSentimentLoading>
                <MehIcon height="24px" width="24px" fill="#DADADA" />
                <p>Analyzing Sentiment</p>
              </CardSentimentLoading>
            ):(
              <CardBodyContent basis="3 0 0">
                <SentimentScale sentiment={post.detailData?.results[frequency]?.ai_results} />
              </CardBodyContent>
            )}
          </CardBodyContent>
          <CardBodyContent basis="2.5 0 0">
            <SocialOutreach 
              totalEngagement={post.briefData.total_engagement} 
              totalPosts={post.briefData.total_posts}
            />
          </CardBodyContent>
          <CardBodyContent basis="1 0 0">
            {activeCardId === post.briefData.topic_id ? (
              <div onClick={handleShrink}>
                <WideAngleArrow height="2.5rem" rotate="180 9 0"/> 
              </div>
              ):(
              <WideAngleArrow height="2.5rem"/>
            )}
          </CardBodyContent>
        </CardRow>
        { activeCardId === post.briefData.topic_id && (
          <CardRow justify="center">
            <ExpandedCard topicId={post.briefData.topic_id} allTopicData={allTopicData}/>
          </CardRow>
        )}
      </CardBodyContainer>

    </>
  )
}

export default React.memo(CardBody)

interface CardBodyProps{
  activeCardId: null | string
  setActiveCardId: (activeCardId: string | null) => void
  post: TopicData
  index: number
  allTopicData: any
}