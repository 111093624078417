import { FeedData } from "../../../../app/interfaces/feed";

export enum EditorType {
    Create = 0,
    Edit = 1
}

export enum EditorView {
    Type = 0,
    Name = 1,
    Keywords = 2,
    Sources = 3,
    Settings = 4
}

export const EditorViewNames:Record<EditorView,string> = {
    0: "Task Type",
    1: "Task Name",
    2: "Refine Keywords",
    3: "Sources",
    4: "Settings"
}


export type ESQLayer = {
    in?: (string|string[])[],
    ex?: (string| string[])[]
}

export interface GuidedKeyword {
    ExcludeOnly: ESQLayer[]
    NoExOnly: ESQLayer[]
}

export interface KeywordSearch {
    guided: ESQLayer[],
    advanced: string, //query_string
    guidedView: GuidedKeyword  | null
}


export enum UpdateFrequency {
    RealTime = 0,
    EveryHour = 1,
    EveryDay = 2,
    OnRequest = 3
}

export const UpdateFrequencyNames:Record<UpdateFrequency,string> = {
    0: "Real time",
    1: "Every hour",
    2: "Every day",
    3: "On request"
}

export interface TaskUpdateFrequency {
    frequency: UpdateFrequency | null
}

export interface TaskDateRange {
    unix_start:number |null,
    unix_end:number |null
}

export enum TaskType {
    adhoc = 1,
    daily = 0,
    kol = 2
}

export interface EditorDetail extends TaskUpdateFrequency,TaskDateRange{
    type: TaskType |null,
    name:string | null,
    indus_id: number | null,
    keywords: KeywordSearch | null,
    lang:string,
    mediums:string[],
    keywordType: number,
    keywordCategoryId: number
}


export interface PreviewState {
    data: FeedData[] | null,
    fetching: boolean
}

export interface EditorState {
    task_id: number|null,
    type: EditorType|null,
    view: EditorView,
    detail:EditorDetail,
    preview: PreviewState,
    submitting: boolean
}

