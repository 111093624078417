import React from "react"
import styles from "./addbtn.module.scss"

type AddBtnProp = {
    clickBtn: (exclude: boolean) => void,
    showEx: boolean
    isLimited: boolean
}

const AddBtn = (props: AddBtnProp) => {
    const [expand, setExpand] = React.useState(false)

    return (
        <div className={styles.wrapper} onClick={() => setExpand(!expand)}>
            <div className={styles.btn}>
                <i className="fas fa-plus"></i>
            </div>
            <div className={"dropdown" + (expand ? " is-active" : "")}>
                <div className="dropdown-trigger">
                    <span className="icon is-small">
                        <i className="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                </div>
                <div className="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                        {!props.isLimited && (
                            <span className="dropdown-item" onClick={() => props.clickBtn(false)}>
                                <span className={styles.or}>/</span>
                                <span>Add 'OR'</span>
                            </span>
                        )}
                        {/* {props.showEx && <span className="dropdown-item" onClick={() => props.clickBtn(true)}>
                            <span className={styles.ex}>-</span>
                            <span>Add 'exclude'</span>
                        </span>} */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddBtn