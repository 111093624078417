import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOverviewData,
  clearOverviewData,
  selectStats,
} from "../../../../app/store/overviewSlice";
import Head from "./head";
import Stats from "./gadgets/stats";
import LineGraph from "./gadgets/linegraph";
import Authors from "./gadgets/authors";
import TopThreads from "./gadgets/topthreads";
import TopSites from "./gadgets/sites";
import OVdata from "./ovdata";
import WordCloud from "./gadgets/wordcloud";
import Trend from "./gadgets/trend";
// import EmptyOverviewData from "./EmptyOverviewData"
import {
  selectFeedKeywords,
  selectFeedType,
  selectTaskId,
  selectTaskUnixEnd,
  selectTaskUnixStart,
  selectTaskMediums
} from "../../../../app/store/feedSlice";
import {
  HeaderContainer,
  HorizontalFlexContainer,
  LineGraphContainer,
  OverviewRoot,
  StatsContainer,
  VerticalLeftContainer,
  VerticalRightContainer,
} from "./styled";
import SentimentOverview from "./gadgets/sentiment/overview";
import SentimentTimeline from "./gadgets/sentiment/timeline";
import Button from "../../../reusable/buttons/Button";
import EmptyPostData from "../posts/EmptyPostData";
import Loader from "../../../reusable/loader";
import moment from "moment";
import TopChannels from "./gadgets/topchannels";

const Overview = () => {
  const ref = createRef();
  const dispatch = useDispatch();

  const taskId = useSelector(selectTaskId);
  const taskType = useSelector(selectFeedType);
  const taskUnixStart = useSelector(selectTaskUnixStart);
  const keywords = useSelector(selectFeedKeywords);
  const mediums = useSelector(selectTaskMediums)
  const taskUnixEnd = useSelector(selectTaskUnixEnd);
  const stats:any = useSelector(selectStats);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorLoaded, setIsAuthorLoaded] = useState(false);
  useEffect(() => {
    if (taskId !== 0) {
      if (taskType === 0) {
        dispatch(fetchOverviewData(null));
      }
      console.log(
        "Task Unix Start ", taskType,taskUnixStart,taskUnixEnd,
        taskType === 1 && taskUnixStart && taskUnixEnd
      );

      if (taskType !== 0 && taskUnixStart && taskUnixEnd) {
        const startDate = moment(taskUnixStart, "DD/MM/YYYY")?.valueOf();
        const endDate = moment(taskUnixEnd, "DD/MM/YYYY")?.valueOf();

        dispatch(
          fetchOverviewData({
            start: startDate,
            end: endDate,
          })
        );
      }
    }

    return () => {
      dispatch(clearOverviewData());
    };
  }, [
    dispatch,
    taskId,
    taskType,
    taskUnixEnd,
    taskUnixStart,
    JSON.stringify(keywords),
    JSON.stringify(mediums)
  ]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);



  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <OverviewRoot>
          {taskType === 1 && (
            <>
              {stats?.postCount?.total === 0 && <EmptyPostData />}
              {(stats?.postCount?.total || !stats?.postCount) && (
                <div
                  id="capture"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "1rem",
                    columnGap: "1rem",
                  }}
                >
                  <HeaderContainer>
                    <Head isAuthorLoaded={isAuthorLoaded} />
                  </HeaderContainer>
                  <StatsContainer>
                    <Stats />
                  </StatsContainer>
                  <LineGraphContainer>
                    <LineGraph />
                  </LineGraphContainer>

                  <HorizontalFlexContainer>
                    <VerticalLeftContainer>
                      <SentimentOverview />
                    </VerticalLeftContainer>
                    <VerticalRightContainer>
                      <SentimentTimeline />
                    </VerticalRightContainer>
                  </HorizontalFlexContainer>

                  <HorizontalFlexContainer>
                    <VerticalLeftContainer>
                      {/* {taskType !== 1 && <Trend />} */}
                  <WordCloud />
                      <Authors setIsAuthorLoaded={setIsAuthorLoaded} />
                      {taskType === 1 && <TopSites />}
                    </VerticalLeftContainer>
                    <VerticalRightContainer>
                      <TopThreads isExpanded={taskType === 1} />
                      {/* {taskType !== 1 && <TopSites />} */}
                    </VerticalRightContainer>
                  </HorizontalFlexContainer>
                </div>
              )}
            </>
          )}
          {taskType === 2 && (
            <>
              <HeaderContainer>
                <Head isAuthorLoaded={isAuthorLoaded} />
              </HeaderContainer>
              {/* <HorizontalFlexContainer> */}
              <TopChannels />
              {/* </HorizontalFlexContainer> */}
            </>
          )}

          <OVdata />
        </OverviewRoot>
      )}
    </>
  );
};

export default Overview;
