import React, { useState, useEffect, useRef } from "react"
import { ESQLayer } from "../../../interface"
import In from "./in"
import styled, {css} from 'styled-components'
import { useClickAway } from "react-use"
import uf from "usefuljs"

interface GroupProp {
    layer: ESQLayer,
    index: number,
    onChange: (layer: ESQLayer, index: number) => void,
    onDelete: (index: number, ex: boolean) => void,
    excludeOnly: boolean
    isLimited: boolean
}

const GroupRoot = styled.div<{selected: boolean, excludeOnly: boolean}>`
    display: flex;
    padding: 0.5rem 1rem;
    column-gap: 0.5rem;
    border-radius: 3px;
    transition: all 0.2s ease;
    border: 1px solid transparent;
    cursor: pointer;

    ${props => props.excludeOnly && css`
        background-color: white;
        &:hover {
            box-shadow: inset 0 0 0 1px #ff496ad6;
        }
    `} 
    
    ${props => !props.excludeOnly && css`
        background-color: rgba(41,209,215,0.15);
        &:hover {
            border: 1px solid #29d1d7;
            box-shadow: 0px 2px 5px #BDBDBD;
        }
    `}
   
    ${props => props.selected && !props.excludeOnly && css`
        border: 1px solid #29d1d7;
        box-shadow: 0 0 14px 3px rgba(211,210,224,0.8);
    `}

  
`

const GroupContentWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
`

const ExcludeBlock = styled.div<{excludeOnly: boolean}>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    ${props => !props.excludeOnly && css` 
        width: 100%; /* so that button start at next row */
    `}
`

const ExcludeBlockLabel = styled.p`
    border: 1px;
    padding: 0.2rem;
    border-style: solid;
    border-color: white;
    color: white;
    background-color: #ff496ad6;
`

const AddAndButton = styled.button`
    font-size: 0.8rem;
    height: 26px;
    color: white;
    border: none;
    border-radius: 5px;
    background-color: #3a89ff;
    transition: all 0.2s ease;
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    &:focus {
        outline: none;
    }
`

const AddExcludeButton = styled.button`
    color: white;
    background-color: #ff6e86;
    font-size: 0.8rem;
    height: 26px;
    border: none;
    border-radius: 5px;
    transition: all 0.2s ease;
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    &:focus {
        outline: none;
    }
`

const DeleteGroupButton = styled.button`
    height: 32px;
    border: none;
    align-self: flex-start;
    transition: all 0.2s ease;
    background-color: transparent;
    cursor: pointer;
    &:hover {
        color: rgba(237,82,82,0.8);
    }
    &:focus {
        outline: none;
    }
`

const Group = (props: GroupProp) => {
    const [data, setData] = useState(props.layer)
    const [selected, setSelected] = useState(!props.layer.ex?.length && !props.layer.in?.length)

    const onClickAddBtn = () => {
        if (!data.in) return setData({ ...data, in: [] })
        setData({ ...data, in: [...data.in, [] as string[]] })
    }

    const onClickAddExBtn = () => {
        if (!data.ex) return setData({ ...data, ex: [] })
        setData({ ...data, ex: [...data.ex, [] as string[]] })
    }

    useEffect(() => {
        setData(props.layer)
    }, [props.layer, setData])

    const onChangeIn = (changeddata: (string | string[])[]) => {
        const currdata = data
        currdata.in = changeddata
        props.onChange(currdata, props.index)

        if (!changeddata.flat(3).length) {
            props.onDelete(props.index, false)
            return
        }

        cleanUp()
    }

    const onChangeEx = (changeddata: (string | string[])[]) => {
        const currdata = data
        currdata.ex = changeddata
        props.onChange(currdata, props.index)

        if (!changeddata.flat(3).length && !data.in?.flat(3).length) {
            props.onDelete(props.index, true)
            return
        }

        cleanUp()
    }

    const cleanUp = () => {
        if (selected) {
            setSelected(false)

            if ((!data.ex || data.ex.flat().length === 0) && (!data.in || data.in.flat().length === 0)) {
                return props.onDelete(props.index, props.excludeOnly)
            }

            // === cleaning ===
            const currdata = data

            // clean exclude
            if (currdata.ex) {
                for (let i = 0; i < currdata.ex.length; ++i) {
                    if (!currdata.ex[i].length) {
                        uf.arr_rmi(currdata.ex, i, true)
                        props.onChange(currdata, props.index)
                    }
                }

                if (!currdata.ex.flat().length) {
                    delete currdata.ex
                    props.onChange(currdata, props.index)
                }

            }

            // clean include
            if (currdata.in) {
                for (let i = 0; i < currdata.in.length; ++i) {
                    if (!currdata.in[i].length) {
                        uf.arr_rmi(currdata.in, i, true)
                        props.onChange(currdata, props.index)
                    }
                }
                if (!currdata.in.flat().length) {
                    delete currdata.in
                    props.onChange(currdata, props.index)
                }
            }

        }
    }

    const groupRef = useRef(null)
    useClickAway(groupRef, () => {
        if (selected) {
            setSelected(false)
        }
    })

    return (
        <GroupRoot
            onClick={() => setSelected(true)}
            ref={groupRef}
            selected={selected}
            excludeOnly={props.excludeOnly}
        >
            <GroupContentWrapper>
                {data.in && (
                    <In
                        relationship={"AND"}
                        in={data.in}
                        onChange={onChangeIn}
                        selectedGroup={selected}
                        sanitize={() => { cleanUp() }}
                        excludeOnly={props.excludeOnly}
                        excludeBlock={false}
                        isLimited={props.isLimited}
                    />)}

                {data.ex && (
                    <ExcludeBlock excludeOnly={props.excludeOnly}>
                        {!props.excludeOnly && <ExcludeBlockLabel>Exclude</ExcludeBlockLabel>}
                        <In
                            relationship={props.excludeOnly ? "AND" : "OR"}
                            in={data.ex}
                            onChange={onChangeEx}
                            selectedGroup={selected}
                            sanitize={() => { cleanUp() }}
                            excludeOnly={props.excludeOnly}
                            excludeBlock={true}
                            isLimited={false}
                        />
                    </ExcludeBlock>
                )}


                {/* {(!data.ex && !props.excludeOnly && selected && (((data.in?.length ?? 0) > 0))) && (
                    <AddExcludeButton onClick={onClickAddExBtn}>- Exclude</AddExcludeButton>
                )} */}

                {(selected && (((data.in?.length ?? 0) > 0) || ((data.ex?.length ?? 0) > 0))) && !props.isLimited && (
                    <AddAndButton onClick={props.excludeOnly ? onClickAddExBtn : onClickAddBtn}>+ AND</AddAndButton>
                )}
            </GroupContentWrapper>



            {selected && (
                <DeleteGroupButton 
                    onClick={() => props.onDelete(props.index, props.excludeOnly)}
                >
                    <i className="fas fa-times" />
                </DeleteGroupButton>
            )}
        </GroupRoot>
    )
}

export default Group

