import React, { useContext, useState } from "react"
import { useSelector } from "react-redux"
import ReactTooltip from "react-tooltip"
import Ctx from "../ctx"
import styles from "./settings.module.scss"
import { setTaskStart, setTaskEnd, setTaskFrequency } from "../ctx/action"
import { UpdateFrequencyNames, UpdateFrequency, TaskType } from "../interface"
import { Context } from "../ctx"
import { setTaskLang } from "../ctx/action"
import { Lang } from "./interface"
import { Language } from "../../../../../app/interfaces/misc"
import { selectLangs } from "../../../../../app/store/miscSlice"

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './react_dates_overrides.css'
import { SingleDatePicker } from 'react-dates'
import styled from "styled-components"
import moment from "moment"
import MonthYearSelect from "../../../../reusable/dates/MonthYearSelect"
import { selectDatefrom } from "../../../../../app/store/userSlice"

const StyledDatePickerWrapper = styled.div`

    .SingleDatePickerInput {
        border: 1px solid #dbdbdb;
        border-radius: 4px;
        height: 40px;
        &:hover {
            border-color: #b5b5b5;
        }
        #feedStartDate,
        #feedEndDate {
            font: 400 13.3333px Arial;
            font-size: 1rem;
            color: #363636;
            border: none;
            padding: 7px 11px;
            cursor: pointer;
            &::placeholder {
                font: 400 1rem Arial;
                color: #363636;
            }
            &:focus {
                outline: none;
            }
        }
        .SingleDatePickerInput_calendarIcon {
            &:focus {
                outline: none;
            }
            .SingleDatePickerInput_calendarIcon_svg {
                fill: #363636;
            }
        }
        svg.DateInput_fang.DateInput_fang_1 {
            top: 52px !important;
        }
    }
`

const LabelText = styled.p`
    color: #828282;
    font-size: 14px;
    margin-left: 1.3rem;
    /* margin-top: -1rem; */
    padding-bottom: 0.4rem;
`

const Settings = () => {
    const ctx = useContext(Ctx) as Context
    const pickedStart = ctx.editor.detail.unix_start
    const pickedEnd = ctx.editor.detail.unix_end
    const taskType = ctx.editor.detail.type
    const type = ctx.editor.detail.type
    const keywordType = ctx.editor.detail.keywordType
    const dateFromUnixTime = useSelector(selectDatefrom)

    console.log("dateFromUnixTime", dateFromUnixTime)

    const onChangeStart = (date: moment.Moment | null) => {
        if (date) {
            setTaskEnd(0, ctx.setEditor)
            // date.unix() give the time at 12:00, in second
            setTaskStart((date.unix() - 12 * 60 * 60) * 1000, ctx.setEditor)
        }
    }

    const onChangeEnd = (date: moment.Moment | null) => {
        if (date) {
            // date.unix() give the time at 12:00, in second
            // adjust for 16hrs to calculate the unix at 23:59
            setTaskEnd((date.unix() + 12 * 60 * 60) * 1000 - 1, ctx.setEditor)
        }
    }

    const onChangeFreq = (freq: UpdateFrequency) => {
        if (type === TaskType.adhoc && freq !== UpdateFrequency.OnRequest) { return }
        setTaskFrequency(freq, ctx.setEditor)
    }

    const checkStartOutsideRange = (day: moment.Moment) => {
        const dayUnix = day.unix() * 1000

        const today = new Date()
        today.setHours(23, 59, 59, 999)

        if (dayUnix > today.getTime()) {
            // disable days after today
            return true
        }

        if(dateFromUnixTime && dayUnix < dateFromUnixTime){
            return true
        }

        if (pickedEnd && pickedEnd !== 0 && dayUnix > pickedEnd) {
            return true
        }

        const earliest = new Date("2020-01-01T00:00:00+08:00")
        if (taskType === 1 && dayUnix < earliest.getTime()) {
            return true
        }


        return false
    }

    const checkEndOutsideRange = (day: moment.Moment) => {
        const dayUnix = day.unix() * 1000
        const today = new Date()
        today.setHours(23, 59, 59, 999)

        if (dayUnix > today.getTime() || dayUnix < pickedStart!) {
            // disable days after today
            return true
        }

        if (taskType === 1 && pickedStart) {
            const start = moment(pickedStart)
            const oneYearFromStart = start.add(1, "year")
            if (day.unix() > oneYearFromStart.unix()) {
                return true
            }
        }

        return false
    }

    const languages: Language[] = useSelector(selectLangs)

    const langs: Lang[] = React.useMemo(() => {
        const retme: Lang[] = []
        const x = new Set<string>()// for deduping
        for (let l of languages) {
            if (x.has(l.lang_abbr)) continue
            retme.push({ lang_abbr: l.lang_abbr, name: l.name })
            x.add(l.lang_abbr)
        }
        return [{ lang_abbr: "", name: "- Pick a language -" }, ...retme]
    }, [languages])

    const onPickLang = (langAbbr: string) => {
        setTaskLang(langAbbr, ctx.setEditor)
    }

    const [startPickerFocused, setStartPickerFocused] = useState<boolean | null>(false)
    const [endPickerFocused, setEndPickerFocused] = useState<boolean | null>(false)

    return (
        <div className={styles.settings}>
            <div className={styles.daterange}>
                <h3>Date Range</h3>
                <div className="field is-grouped">
                    <div className="control">
                        <StyledDatePickerWrapper>
                            <SingleDatePicker
                                id="feedStartDate"
                                date={pickedStart ? moment(pickedStart) : null}
                                onDateChange={onChangeStart}
                                focused={startPickerFocused}
                                onFocusChange={({ focused }) => setStartPickerFocused(focused)}
                                hideKeyboardShortcutsPanel={true}
                                showDefaultInputIcon={true}
                                inputIconPosition="after"
                                numberOfMonths={1}
                                placeholder="dd/mm/yyyy"
                                displayFormat="DD/MM/YYYY"
                                disabled={keywordType === 2}
                                isOutsideRange={checkStartOutsideRange}
                                readOnly
                                calendarInfoPosition="bottom"
                                renderCalendarInfo={() => {
                                    return taskType === 1 ? <LabelText>Note: You can select up to 365 days of data</LabelText> : <p></p>
                                }}
                                renderMonthElement={MonthYearSelect}
                            />
                        </StyledDatePickerWrapper>
                    </div>
                    <span className={styles.sep}>to</span>
                    <div className="control">
                        <ReactTooltip html={true} />
                        <StyledDatePickerWrapper
                            data-tip={taskType === 0 ? "We will keep this feed live<br/>with no end date" : ""}
                            data-place="bottom"
                            data-effect="solid"
                        >
                            <SingleDatePicker
                                id="feedEndDate"
                                date={pickedEnd ? moment(pickedEnd) : null}
                                onDateChange={onChangeEnd}
                                focused={endPickerFocused}
                                onFocusChange={({ focused }) => setEndPickerFocused(focused)}
                                hideKeyboardShortcutsPanel={true}
                                showDefaultInputIcon={true}
                                inputIconPosition="after"
                                numberOfMonths={1}
                                placeholder={taskType === 0 ? "No end date" : "dd/mm/yyyy"}
                                displayFormat="DD/MM/YYYY"
                                disabled={taskType === 0 || pickedStart === null || pickedStart === 0 || keywordType === 2}
                                isOutsideRange={checkEndOutsideRange}
                                readOnly
                                calendarInfoPosition="bottom"
                                renderCalendarInfo={() => {
                                    return taskType === 1 ? <LabelText>Note: You can select up to 365 days of data</LabelText> : <p></p>
                                }}
                                renderMonthElement={MonthYearSelect}
                            />
                        </StyledDatePickerWrapper>
                    </div>
                </div>
            </div>

            {keywordType !== 2 &&   <div className={styles.freq}>
                <h3>Update Frequency</h3>
                <div className={styles.freqlist}>
                    {
                        Object.entries(UpdateFrequencyNames).map((key, value) => {

                            if (taskType === 0 && value !== 3) { // live feed
                                return (
                                    <span
                                        className={(type === TaskType.adhoc && value !== UpdateFrequency.OnRequest ? `${styles.disabled} ` : "") + (ctx.editor.detail.frequency === value ? styles.active : "")}
                                        onClick={() => onChangeFreq(value)} key={value}
                                    >
                                        {key[1]}
                                    </span>
                                )
                            } else if (taskType !== 0) { // adhoc feed
                                if (value === 3) { // on request 
                                    return (
                                        <span
                                            className={((type === TaskType.adhoc || type === TaskType.kol) && value !== UpdateFrequency.OnRequest ? `${styles.disabled} ` : "") + (ctx.editor.detail.frequency === value ? styles.active : "")}
                                            onClick={() => onChangeFreq(value)} key={value}
                                        >
                                            {key[1]}
                                        </span>
                                    )
                                } else {
                                    return (
                                        <span
                                            className={((type === TaskType.adhoc || type === TaskType.kol) && value !== UpdateFrequency.OnRequest ? `${styles.disabled} ` : "") + (ctx.editor.detail.frequency === value ? styles.active : "")}
                                            onClick={() => onChangeFreq(value)} key={value}
                                            data-tip={taskType !== 1 ? "This feature is only<br/>available for live feed" : ""}
                                            data-place="bottom"
                                            data-effect="solid"
                                        >
                                            {key[1]}
                                        </span>
                                    )
                                }
                            }
                            return null
                        })
                    }
                </div>
            </div>}

          
            {/* <div className={styles.lang}>
                <label className="label">AI Primary Language</label>

                <div className={"select"} id={styles.langSelect}>
                    <select defaultValue={ctx.editor.detail.lang} onChange={e => onPickLang(e.target.value)}>
                        {
                            langs.map(lang => <option key={lang.lang_abbr} value={lang.lang_abbr}>{lang.name}</option>)
                        }
                    </select>
                </div>
                <i data-tip="We will use this language as the<br/>primary language for all the AI<br/>analysis in this feed." data-place="bottom" className={"fas fa-question-circle " + styles.info} />
            </div> */}
        </div>
    )
}

export default Settings