import React, { useEffect } from "react"
import styled from 'styled-components'
import { Switch, BrowserRouter as Router } from "react-router-dom"
import { ToastContainer } from "react-toastify"

// routes
import AuthRoute from "./components/auth/AuthRoute"
import UAuthRoute from "./components/auth/UAuthRoute"

// pages and components
import Landing from "./components/landing"
import Vnav from "./components/bar/vnav"
import Tnav from "./components/bar/tnav"
import Login from "./components/login"
import Logout from "./components/logout"
import Loading from "./components/reusable/loader"
import Feed from "./components/feed"
import GptTrend from "./components/gptTrendExplorer";

import {
    selectUserInfo,
    refreshUser,
    fetchUserConfig,
    fetchUserInfo,
    selectToken,
} from "./app/store/userSlice"
import { useSelector, useDispatch } from "react-redux"

// react-query setup
import { QueryClientProvider } from 'react-query'

import "bulma/css/bulma.min.css"
import "@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css"
import "./static/css/fix.css"
import "react-toastify/dist/ReactToastify.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import styles from "./static/scss/app.module.scss"
import Account from "./components/account"
// import Trend from "./components/trendExplorer"
import queryClient from "./app/query/client"

const StyledToastContainer = styled(ToastContainer).attrs({
    className: 'toast-container',
    toastClassName: 'toast',
    bodyClassName: 'body',
    progressClassName: 'progress'
})`
    .Toastify__toast--info {
        background-color: #283044;
    }
`

const App = () => {
    const [verifying, setVerifying] = React.useState(true)
    const userinfo = useSelector(selectUserInfo)
    const dispatch = useDispatch()

    useEffect(() => {
        // if (userinfo && userinfo.token) {
        //     dispatch(fetchUserConfig(userinfo.token))
        // }

        let refresher = setInterval(() => {
            if (userinfo && userinfo.token) {
                dispatch(refreshUser(userinfo.token))
            }
        }, parseInt(process.env.REACT_APP_TOKEN_REFRESH_INTERVAL!))
        return () => {
            // unmount
            clearInterval(refresher)
        }
    }, [userinfo, dispatch])

    useEffect(() => {
        // mounted
        if (sessionStorage.user && !userinfo) {
            // sessionstorage has record but redux doesnt,
            // probably means that user refreshed the page
            // verify user login
            console.log("verifying user") //DEBUG
        } else {
            setVerifying(false)
        }
        return () => {
            // unmount
        }
    }, [userinfo])

    const token = useSelector(selectToken)

    useEffect(() => {
        if (token) {
            dispatch(fetchUserInfo(token))
        }
    }, [dispatch, token])

    return (
        <div className={styles.App}>
            <QueryClientProvider client={queryClient}>
                <header className="App-header"></header>
                <StyledToastContainer
                    autoClose={3000}
                    hideProgressBar={true}
                    pauseOnHover={false}
                />
                <Router>
                    <div id={styles.main}>
                        <AuthRoute path="/" component={Vnav} />
                        <AuthRoute path="/" component={Tnav} />

                        <div className={styles.content} id="main-content">
                            {verifying ? (
                                <Loading />
                            ) : (
                                <Switch>
                                    <UAuthRoute path="/login" component={Login} />
                                    <AuthRoute path="/logout" component={Logout} />
                                    <AuthRoute path="/brand" component={Feed} />
                                    <AuthRoute path="/account" component={Account} />
                                    {/* <AuthRoute path="/trend" component={Trend} /> */}
                                    <AuthRoute path="/gptTrend" component={GptTrend} />
                                    <AuthRoute path="/" component={Landing} />
                                </Switch>
                            )}
                        </div>
                    </div>
                </Router>
            </QueryClientProvider>
        </div>
    )
}

export default App
