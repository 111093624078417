import { StatType } from "."
import { DateRangeFilter } from "../../../../../../app/interfaces/misc"
import { DateRange, StatCount } from "../../../../../../app/interfaces/overview"
import {
	convertDateRangeToText,
	convertDateRangeToText2,
	convertDateRangeToUnit,
} from "../../../../../../app/tools/dateRangeConverter"

export function generateTypeCountLabel(type: StatType, dateRange: DateRange, customDateRange: DateRangeFilter | null) {
	let labelText = ""
	switch (type) {
		case "post":
			labelText = "Posts"
			break
		case "thread":
			labelText = "Threads"
			break
		case "author":
			labelText = "Unique authors"
			break
		case "engagement":
			labelText = "Total engagement"
			break
		case "view":
			labelText = "Total view counts"
			break
	}
	if((dateRange === DateRange.Today || dateRange === DateRange.Week || dateRange === DateRange.Month) && !customDateRange?.start){
		return `${labelText} in ${convertDateRangeToText2(dateRange)}`								
	} else {
		return `${labelText}`
	}
}

export function generateStatTooltip(
	type: StatType,
	count: StatCount,
	isIncreased: boolean,
	dateRange: DateRange,
	percentChanged: number,
	isAdHoc: boolean
) {
	if (isAdHoc) {
		switch (type) {
			case "post":
				return `<i>Note: Posts include both threads<br>and comments</i>`
			case "thread":
				return `<i>Note: Threads are initial posts<br>that trigger comments or replies</i>`
			case "author":
				return ``
			case "engagement":
				return `<i>Note: Engagement includes shares,<br>comments, and reactions</i>`
			case "view":
				return ``
		}
	} else {
		switch (type) {
			case "post":
				return `${
					count.previous !== undefined
						? count.previous > 0
							? `Volume ${convertDateRangeToText(
									dateRange
							  )} is <b>${percentChanged}%</b> ${
									isIncreased ? "higher" : "lower"
							  }<br>than average volume in a ${convertDateRangeToUnit(
									dateRange
							  )}<br><br>`
							: `No percentage comparison ${convertDateRangeToText(
									dateRange
							  )} since the starting figure was 0<br><br>`
						: ""
				}<i>Note: Posts include both threads<br>and comments</i>`
			case "thread":
				return `${
					count.previous !== undefined
						? count.previous !== 0
							? `Thread count ${convertDateRangeToText(
									dateRange
							  )} is <b>${percentChanged}%</b><br>${
									isIncreased ? "higher" : "lower"
							  } than average volume in a ${convertDateRangeToUnit(
									dateRange
							  )} <br><br>`
							: `No percentage comparison ${convertDateRangeToText(
									dateRange
							  )} since the starting figure was 0<br><br>`
						: ""
				}<i>Note: Threads are initial posts<br>that trigger comments or replies</i>`
			case "author":
				return count.previous !== 0
					? `Unique author count ${convertDateRangeToText(
							dateRange
					  )}<br>is <b>${percentChanged}%</b> ${
							isIncreased ? "higher" : "lower"
					  } than average`
					: `No percentage comparison ${convertDateRangeToText(
							dateRange
					  )} since the starting figure was 0`
			case "engagement":
				return `${
					count.previous !== undefined
						? count.previous !== 0
							? `Engagement count ${convertDateRangeToText(
									dateRange
							  )} is <b>${percentChanged}%</b><br>${
									isIncreased ? "higher" : "lower"
							  } than average volume in a ${convertDateRangeToUnit(
									dateRange
							  )}<br></br>`
							: `No percentage comparison ${convertDateRangeToText(
									dateRange
							  )} since the starting figure was 0<br><br>`
						: ""
				}<i>Note: Engagement includes shares,<br>comments, and reactions</i>`
			case "view":
				return count.previous !== 0
					? `View count ${convertDateRangeToText(
							dateRange
					  )} is <b>${percentChanged}%</b><br>${
							isIncreased ? "higher" : "lower"
					  } than average volume in a ${convertDateRangeToUnit(dateRange)}`
					: `No percentage comparison ${convertDateRangeToText(
							dateRange
					  )} since the starting figure was 0`
		}
	}
}

export function generateAdhocLabel(type: StatType) {
	switch (type) {
		case "post":
			return "Total posts"
		case "thread":
			return "Total Comments"
		case "author":
			return "Total unique authors"
		case "engagement":
			return "Total engagement"
		case "view":
			return "Total view count"
	}
}
