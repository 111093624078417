import React from 'react'
import styled from 'styled-components'
import { useHistory } from "react-router-dom"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin-top: 6rem;
`

const Heading = styled.h1`
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`

const SubHeading = styled.p`
  font-weight: 500;
  color: #828282;
  .keywords {
    color: #3a89ff;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
  }
`

function AccessDenied() {
  const history = useHistory()
  return (
      <Container>
        <img src="https://lenx-public-assets.s3.amazonaws.com/Denied_Face.png" alt="denied face"/>
        <Heading>Hm..you are not supposed to be here</Heading>
        <SubHeading>Let us bring you <span className="keywords" onClick={() => history.push("/brand")}>back to home</span></SubHeading>
      </Container>
  )
}

export default AccessDenied

