import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Ctx, { Context } from "../ctx";
import uf from "usefuljs"
import {
  setTaskEnd,
  setTaskKeywordCategoryId,
  setTaskKeywordType,
  setTaskKw,
  setTaskStart,
} from "../ctx/action";
import Guided from "./guided";
import styles from "./qrybuilder.module.scss";
import { KeywordSearch, ESQLayer } from "../interface";
import ESConvert from "./esconvert";
import Preview from "./preview";
import Examples from "./examples";
import { calculateIncludes } from "../helper";
import { useSelector } from "react-redux";
import {
  selectKeywordLimit,
  selectKeywordPerTask,
  selectToken,
} from "../../../../../app/store/userSlice";
import taskTypeStyles from "../tasktype/tasktype.module.scss";
import { selectCategories } from "../../../../../app/store/miscSlice";

const KeywordsCount = styled.p`
  font-size: 16px;
  color: #868686;
  text-align: right;
`;

const categoryList = [
  {
    id: 1,
    name: "Airlines",
    // keywords: "((國泰航空) OR (香港航空))",
    keywordsData: "國泰航空 OR 香港航空",
    keywordsString: "((國泰航空 OR 香港航空))",
    keywordsObj: [
      {
        in: [["國泰航空", "香港航空"]],
      },
    ],
    // keywords: {
    //     "guided": [
    //         {
    //             "in": [
    //                 [
    //                     "國泰航空",
    //                     "香港航空"
    //                 ]
    //             ]
    //         }
    //     ],
    //     "advanced": "((國泰航空 OR 香港航空))",
    //     "guidedView": {
    //         "NoExOnly": [
    //             {
    //                 "in": [
    //                     [
    //                         "國泰航空",
    //                         "香港航空"
    //                     ]
    //                 ]
    //             }
    //         ],
    //         "ExcludeOnly": []
    //     }
    // }
  },
  {
    id: 2,
    name: "Fashion",
    keywordsData: "國泰航空 OR 香港航空",
    keywordsString: "((國泰航空 OR 香港航空))",
    keywordsObj: [
      {
        in: [["國泰航空", "香港航空"]],
      },
    ],
  }
];

const Qrybuilder = () => {
  const ctx = React.useContext(Ctx) as Context;
  const editorType = ctx.editor.type;
  const taskType = ctx.editor.detail.type;
  const keywordType = ctx.editor.detail.keywordType;
  const token = useSelector(selectToken)

  const qrydata: KeywordSearch = React.useMemo(
    () => ctx.editor.detail.keywords!,
    [ctx.editor.detail.keywords]
  );
  console.log("qrydata", qrydata);

  const onChangeGuide = React.useCallback(
    (qry: ESQLayer[]) => {
      const ec = new ESConvert(qry);
      const qrystring = ec.convert();
      console.log("qrystring", qrystring, qry);
      setTaskKw(qry, qrystring, ctx.setEditor);
    },
    [ctx.setEditor]
  );

  const [showEx, setShowEx] = React.useState(false);
const categoryList = useSelector(selectCategories)


  // const taskType = ctx.editor.detail.type
  const numberOfIncludes = calculateIncludes(
    qrydata?.guidedView?.NoExOnly ?? []
  );
  const keywordPerTask = useSelector(selectKeywordPerTask);
  // const globalKeywordLimit = useSelector(selectKeywordLimit)
  const globalKeywordLimit = 5;
  const isLimitingInput =
    (keywordPerTask !== null && numberOfIncludes >= keywordPerTask) ||
    (globalKeywordLimit !== null && numberOfIncludes >= globalKeywordLimit);

  // const onChangeIndus = React.useCallback((category_id:number)=> set,[ctx.setEditor]);

  useEffect(() => {
    uf.ajax({
      method: "get",
      headers: { token },
      url: `${process.env.REACT_APP_ENDPOINT}/keyword-categories`,
    }).then((response: any) => {
      console.log("Keyword Categories", response?.data);

    }).catch((err: any) => {
      console.error(err.message)
    })
  }, [])
  return (
    <div className={styles.qrybuilder}>
      <div className={styles.recTxt}>
        <p>
          We recommend you add alternative combinations to collect data about
          your topic.
        </p>
      </div>

      <div className={styles.qryHead}>
        <p>We collect posts with the following combinations</p>
      </div>
      {taskType === 2 && keywordType === 0 && (
        <div className={taskTypeStyles.tasktype}>
          <div
            className={taskTypeStyles.type}
            data-tip={editorType === 1 ? "Feed type cannot be changed" : ""}
            data-place="bottom"
            data-effect="solid"
            onClick={() => {
              setTaskKeywordType(1, ctx.setEditor);
              setTaskKeywordCategoryId(null, ctx.setEditor)
            }}
            style={{ justifySelf: "flex-end" }}
          >
            <span>
              <span className={taskTypeStyles.circle}>
                <span className={taskTypeStyles.bgcircle}></span>
                <img
                  src="https://d18dyb0v4b4cx9.cloudfront.net/adhoc_task.svg"
                  alt="adhoc"
                />
              </span>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <p>Enter your keywords</p>
              </div>
            </span>
          </div>
          <div
            className={taskTypeStyles.type}
            data-tip={editorType === 1 ? "Feed type cannot be changed" : ""}
            data-place="bottom"
            data-effect="solid"
            onClick={() => {
              setTaskKeywordType(2, ctx.setEditor);
              setTaskKeywordCategoryId(categoryList?.[0]?.id, ctx.setEditor)
              setTaskKw(
                categoryList?.[0]?.keywordsObj,
                categoryList?.[0]?.keywordsString,
                ctx.setEditor
              );
              const currentTimestamp = Math.floor(Date.now() / 1000);
              const oneMonthAgoTimestamp = currentTimestamp - 30 * 24 * 60 * 60;

              setTaskStart(oneMonthAgoTimestamp * 1000, ctx.setEditor);
              setTaskEnd(currentTimestamp * 1000, ctx.setEditor);
            }}
            // style={{marginLeft:"120px"}}
          >
            <span>
              <span className={taskTypeStyles.circle}>
                <span className={taskTypeStyles.bgcircle}></span>
                <img
                  src="https://d18dyb0v4b4cx9.cloudfront.net/adhoc_task.svg"
                  alt="adhoc"
                />
              </span>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <p>Select by category</p>
              </div>
            </span>
          </div>
        </div>
      )}

      {(taskType === 1 || keywordType === 1) && (
        <>
          <div className={styles.qryview}>
            <Guided
              onChange={onChangeGuide}
              qry={qrydata?.guided ?? []}
              view={qrydata?.guidedView ?? { ExcludeOnly: [], NoExOnly: [] }}
              isLimited={isLimitingInput}
            />
          </div>
          <div className={styles.qryExample}>
            <button onClick={() => setShowEx(true)}>See examples</button>
            {numberOfIncludes > 0 &&
              keywordPerTask !== null &&
              globalKeywordLimit === null && (
                <KeywordsCount>
                  {keywordPerTask - numberOfIncludes} keyword(s) left
                </KeywordsCount>
              )}
            {numberOfIncludes > 0 && globalKeywordLimit !== null && (
              <KeywordsCount>
                {globalKeywordLimit - numberOfIncludes} keyword(s) left
              </KeywordsCount>
            )}
            {globalKeywordLimit === null && keywordPerTask === null && (
              <KeywordsCount>{"∞"} keyword(s) left</KeywordsCount>
            )}
          </div>
        </>
      )}

      {keywordType === 2 && (
        <div style={{ marginTop: "30px" }}>
          <div
            style={{
              color: "#828282",
              fontSize: "0.85rem",
              marginBottom: "5px",
            }}
          >
            Select Category
          </div>
          <div className="field">
            <div className="select" style={{ width: "100%" }}>
              <select
                style={{ width: "100%" }}
                onChange={(e) => {
                  console.log("I am getting called", e.target.value);
                  setTaskKeywordCategoryId(parseInt(e.target.value), ctx.setEditor)
                  const selectedCategory = categoryList?.find(c => c?.id === parseInt(e.target.value))
                  console.log("selectedCategory", selectedCategory)
                  setTaskKw(
                    selectedCategory?.keywordsObj,
                    selectedCategory?.keywordsString,
                    ctx.setEditor
                  );
                }}
                // onChange={e =>   {
                //     console.log("Data test", )
                //     setTaskKw(categoryList?.[0]?.keywordsObj, categoryList?.[0]?.keywordsString, ctx.setEditor)
                // }}
              >
                {categoryList.map((ind) => (
                  <option key={ind.id} value={ind.id} onClick={() => {}}>
                    {ind.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* <div style={{marginTop:"20px"}}>{categoryList?.[0]?.keywordsData}</div> */}
        </div>
      )}

      {taskType === 2 && keywordType !== 0 && (
        <button className="button" style={{width:"100px", marginTop:"20px", color:"#828282"}} onClick={() =>  setTaskKeywordType(0, ctx.setEditor)}>
             <span className="icon is-small">
            <i className="fas fa-angle-left" aria-hidden="true" />
          </span>
          <span>Back</span>
         
        </button>
      )}

      {/* <Preview /> */}

      <Examples active={showEx} onClickClose={() => setShowEx(false)} />
    </div>
  );
};

export default Qrybuilder;
