import React from 'react'
import { HeaderGradientContainer, HeadersContainer, MainHeader, RegionHeader } from '../styled'

const BannerRow = ({bannerUrl,labelText}: BannerRowProps) => {
  return (
    <>
      <HeaderGradientContainer url={bannerUrl} />
      <HeadersContainer>
        <MainHeader>TRENDING ENTITIES</MainHeader>
        <RegionHeader>{labelText}</RegionHeader>
      </HeadersContainer>
    </>
  )
}

export default React.memo(BannerRow)

interface BannerRowProps{
  bannerUrl: string
  labelText: string
}