import React, { useState, memo, useMemo } from "react";
import Searcher from "../searcher";
import EditFeedSettingButton from "./EditFeedSettingButton";
import { FeedTask, TaskInfo } from "../../../../app/interfaces/feed";
import { useLocation, useHistory } from "react-router-dom";
import styles from "./subnav.module.scss";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  selectFeedTaskInfo,
  selectFeedTaskAccess,
  selectTaskUnixStart,
  selectTaskUnixEnd,
} from "../../../../app/store/feedSlice";

const TaskInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const TaskInfoFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const TaskInfoSecondRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TaskTitle = styled.h2`
  color: white;
  align-self: center;
  font-size: 22px;
  font-weight: 500;
  margin-right: 1.2rem;
`;

const FeedTypeLabel = styled.p<{ taskType: number }>`
  color: #283044;
  text-align: center;
  width: 4rem;
  font-size: 0.9rem;
  padding: 0.1rem 0.3rem;
  border-radius: 3px;
  margin-right: 1.2rem;
  background-color: ${(props) => (props.taskType ? "#FFD9DF" : "#FEEEC6")};
`;

const FeedDataRange = styled.p`
  color: #d2d2d2;
  font-size: 0.8rem;
`;

const SearcherContainer = styled.div`
  display: grid;
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
`;

const SubNav = memo(() => {
  const location = useLocation();
  const history = useHistory();

  const task: TaskInfo = useSelector(selectFeedTaskInfo);
  const liveTasks: FeedTask[] = useSelector(selectFeedTaskAccess);

  const feedStartTime = useSelector(selectTaskUnixStart);
  const feedEndTime = useSelector(selectTaskUnixEnd);

  const startDate = useMemo(() => {
    if (feedStartTime) {
      return moment(feedStartTime).format("MMM D, YYYY");
    } else {
      return "not defined";
    }
  }, [feedStartTime]);

  const endDate = useMemo(() => {
    if (feedEndTime) {
      return moment(feedEndTime).format("MMM D, YYYY");
    } else {
      return moment().format("MMM D, YYYY");
    }
  }, [feedEndTime]);

  const page = React.useMemo(() => location.pathname.split("/")[3], [location]);
  const currPath = React.useMemo(
    () => location.pathname.split("/").slice(0, 3).join("/"),
    [location]
  );
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);

  const tabActive = React.useCallback(
    (tab: string): boolean => {
      switch (page) {
        case "posts":
        case "trends":
        case "compare":
          return tab === page;
        default:
          return tab === "overview";
      }
    },
    [page]
  );

  const toggleMenu = () => {
    if (!showDropdownMenu) {
      document.addEventListener("click", closeMenu);
      setShowDropdownMenu(true);
    }
  };

  const closeMenu = () => {
    setShowDropdownMenu(false);
    document.removeEventListener("click", closeMenu);
  };

  return (
    <div id={styles.subnav}>
      <TaskInfoContainer>
        <TaskInfoFirstRow>
          <TaskTitle>{task.task_name} </TaskTitle>
          <FeedTypeLabel taskType={task.taskType!}>
            {task.taskType === 1 ? "Brand" : "KOL"}
          </FeedTypeLabel>
          <EditFeedSettingButton />
        </TaskInfoFirstRow>
        <TaskInfoSecondRow>
          {/* <FeedDataRange>
            {`Monitoring period: ${startDate} - ${endDate}`}
          </FeedDataRange> */}
        </TaskInfoSecondRow>
      </TaskInfoContainer>

      <SearcherContainer>{page === "posts" && <Searcher />}</SearcherContainer>

      <div
        className={"dropdown is-right" + (showDropdownMenu ? " is-active" : "")}
        id={styles.taskdropdown}
      >
        <div className="dropdown-trigger" onClick={toggleMenu}>
          <button
            className="button"
            aria-haspopup="true"
            aria-controls="taskmenu"
          >
            <span>{task.task_name}</span>
            <span className="icon is-small">
              <i className="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="taskmenu" role="menu">
          <div className="dropdown-content">
            {liveTasks.map((lt) => (
              <a
                key={lt.task_id}
                href={`/feed/${lt.task_id}/overview`}
                className="dropdown-item"
              >
                {lt.task_name}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div id={styles.pagination}>
        <span
          className={tabActive("overview") ? "active" : ""}
          onClick={() => history.push(`${currPath}/overview`)}
        >
          Overview
        </span>
        {/* <span
          className={tabActive("posts") ? "active" : ""}
          onClick={() => history.push(`${currPath}/posts`)}
        >
          Posts
        </span> */}
        {task.taskType === 1 && (
          <span
            className={tabActive("compare") ? "active" : ""}
            onClick={() => history.push(`${currPath}/compare`)}
          >
            Compare
          </span>
        )}
      </div>
    </div>
  );
});

export default SubNav;
