import React, { useState } from 'react'
import MultiSelect from "../../../reusable/inputs/select/multiSelect"
import SingleSelect from "../../../reusable/inputs/select/singleSelect"
import { calculateMedium } from '../../home/Cards/CardBody/ExpandedCard/Statistics/ChartRow/helperFn'
import { usePostsStore } from '../../postStore'
import { MediumKey } from "../models"
import { SectionBody, SectionHeader, PostCountChartRoot } from "../styled"
import Chart from "./Chart"

function PostCountChart() {
  const {overviewPost, frequency} = usePostsStore(state => ({
    overviewPost : state.overviewPost,
    frequency: state.frequency
  }))

  const [option, setOption] = useState("Comments")
  const [medium, setMedium] = useState<MediumKey[]>([])

  const handleMediumPick = (value: MediumKey) => {
    if (medium.includes(value)) {
      setMedium(medium.filter(item => item !== value))
    } else {
      setMedium([...medium, value])
    }
  }
  
  const threadCountData = (overviewPost && calculateMedium(overviewPost.aggregations.medium_count_line, option, frequency, medium)) || []

  const mappedMediumLabelFromDB = overviewPost?.aggregations.medium_count_line.map((medium)=>({label: medium.medium as MediumKey, value: medium.medium as MediumKey}))
  const mappedMediumLabel = mappedMediumLabelFromDB && [...mappedMediumLabelFromDB, {label: 'Total' as MediumKey, value: 'Total' as MediumKey}]

  const viewLabels = [{ label: "Comments", value: "Comments" }]


  return (
    <PostCountChartRoot>
      <SectionHeader>
        <h3>Comments Count</h3>
        <div>
          <MultiSelect
            id="post-count-chart-medium"
            values={medium}
            setValues={handleMediumPick}
            options={mappedMediumLabel}
            width={120}
          />
          <SingleSelect
            id="post-count-chart-view-by"
            options={viewLabels}
            value={option}
            setValue={(value) => setOption(value as string)}
            width={180}
            label="View by:"
          />
        </div>
      </SectionHeader>
      <SectionBody>
        <Chart data={threadCountData} />
      </SectionBody>
    </PostCountChartRoot>
  )
}

export default React.memo(PostCountChart)
