import React from 'react'
import styled from 'styled-components'
import { ChannelsForTopChannels } from '../../interface'
import ChannelRow from './ChannelRow'

interface ChannelListProp {
  channels: ChannelsForTopChannels[]
  maxCount: number
}

const ListContainer = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 60vh;
  max-height: 60vh;
`

function ChannelList({ channels, maxCount }: ChannelListProp) {
  return (
    <ListContainer>
      {
        channels.map((channel,i) => (
          <a href={channel.link} rel="noopener noreferrer" target="_blank" style={{color:'#7B7B7B'}} key={i}>
            <ChannelRow
              site={channel.site}
              channel={channel.name}
              postCount={channel.count}
              maxSortedPostCount={maxCount}
            />
          </a>
        ))
      }
    </ListContainer>
  )
}

export default ChannelList
