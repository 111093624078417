import React, { useState } from "react"
import styled from 'styled-components'
import { selectUserInfo } from "../../../../../app/store/userSlice"
import { useSelector } from "react-redux"
import styles from "./tasktype.module.scss"
import Ctx, { Context } from "../ctx"
import { setTaskType } from "../ctx/action"
import { TaskType as TType } from "../interface"
import ReactTooltip from "react-tooltip"
import UpgradePlanNotice, { NoticeType } from "../../../../reusable/notice/UpgradePlanNotice"
import { selectAdHocFeedNumber, selectLiveFeedNumber } from "../../../../../app/store/feedSlice"

const Title = styled.h3`
    grid-row: 1 / span 1;
    grid-column: 1 / span 2;
    font-weight: 500;
    font-size: 2rem;
    color: #4d5280;
`

const UpgradeLabel = styled.p`
    grid-column: 1 / 3;
    text-align: center;
    cursor: default;

    span {
        text-decoration: underline;
        color: #29d1d7;
        cursor: pointer;
    }
`

export const QuotaCount = styled.div<{ isUsedUp: boolean }>`
    height: 24px;
    width: 24px;
    bottom: -8px;
    right: -8px;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border-radius: 50%;
    color: white;
    background-color: ${props => props.isUsedUp ? " #ff6e86" : "#1bc2c8"};
`

const BetaLabel = styled.span`
    color: #29D1D7;
    background-color: #E3FEFF;
    border-radius: 4px;
    font-size: 9px;
    padding: 2px 4px;
`

const TaskType = () => {

    const userInfo = useSelector(selectUserInfo)
    const liveFeedCount = useSelector(selectLiveFeedNumber)
    const adHocFeedCount = useSelector(selectAdHocFeedNumber)
    const liveFeedLimit = userInfo.liveFeedLimit
    const adHocFeedLimit = userInfo.adhocFeedLimit

    const [show, setShow] = useState(false)
    const [noticeType, setNoticeType] = useState<NoticeType>("feedLimit")

    const ctx = React.useContext(Ctx) as Context
    const taskType = ctx.editor.detail.type
    const editorType = ctx.editor.type

    const onClickType = (type: TType) => {

        // create mode
        if (editorType === 0) {

            if (type === 0) {
                if (liveFeedLimit === null || liveFeedLimit > liveFeedCount) {
                    setTaskType(type, ctx.setEditor)
                    return
                }
            }

            if (type === 1) {
                if (adHocFeedLimit === null || adHocFeedLimit > adHocFeedCount) {
                    setTaskType(type, ctx.setEditor)
                    return
                }
            }
            if (type === 2) {
                if (adHocFeedLimit === null || adHocFeedLimit > adHocFeedCount) {
                    setTaskType(type, ctx.setEditor)
                    return
                }
            }

            setNoticeType("feedLimit")
            setShow(true)
        }
    }



    return (
        <div className={styles.tasktype}>
            <ReactTooltip html={true} />
            <Title>Hey {userInfo.display_name}, what would you like to monitor?</Title>
            <div
                className={
                    styles.type + (taskType === TType.adhoc ? " active" : "") + (editorType === 1 ? ` ${styles.disable}` : "")
                }
                data-tip={editorType === 1 ? "Feed type cannot be changed" : ""} data-place="bottom" data-effect="solid"
                onClick={() => onClickType(TType.adhoc)}
                // style={{marginLeft:"120px"}}
            >
                <span>
                    <span className={styles.circle}>
                        <span className={styles.bgcircle}></span>
                        <img
                            src="https://d18dyb0v4b4cx9.cloudfront.net/adhoc_task.svg"
                            alt="adhoc"
                        />
                    </span>
                    <div>
                        <p>Brand Search </p>
                        <BetaLabel>BETA</BetaLabel>
                    </div>
                </span>
                {
                    adHocFeedLimit !== null ? (
                        <QuotaCount data-tip={`${adHocFeedLimit - adHocFeedCount} feed remaining`} isUsedUp={adHocFeedCount >= adHocFeedLimit}>
                            <p>{adHocFeedLimit - adHocFeedCount}</p>
                        </QuotaCount>
                    ) : <QuotaCount data-tip={`∞ feed remaining`} isUsedUp={false}>
                        <p>{"∞"}</p>
                    </QuotaCount>
                }
            </div>

            <div
                className={
                    styles.type + (taskType === TType.kol ? " active" : "") + (editorType === 1 ? ` ${styles.disable}` : "")
                }
                data-tip={editorType === 1 ? "Feed type cannot be changed" : ""} data-place="bottom" data-effect="solid"
                onClick={() => onClickType(TType.kol)}
                // style={{marginLeft:"120px"}}
            >
                <span>
                    <span className={styles.circle}>
                        <span className={styles.bgcircle}></span>
                        <img
                            src="https://d18dyb0v4b4cx9.cloudfront.net/adhoc_task.svg"
                            alt="adhoc"
                        />
                    </span>
                    <div>
                        <p>KOL Search </p>
                        <BetaLabel>BETA</BetaLabel>
                    </div>
                </span>
                {
                    adHocFeedLimit !== null ? (
                        <QuotaCount data-tip={`${adHocFeedLimit - adHocFeedCount} feed remaining`} isUsedUp={adHocFeedCount >= adHocFeedLimit}>
                            <p>{adHocFeedLimit - adHocFeedCount}</p>
                        </QuotaCount>
                    ) : <QuotaCount data-tip={`∞ feed remaining`} isUsedUp={false}>
                        <p>{"∞"}</p>
                    </QuotaCount>
                }
            </div>

            {/* <div
                className={
                    styles.type + (taskType === TType.daily ? " active" : "") + (editorType === 1 ? ` ${styles.disable}` : "")
                }
                data-tip={editorType === 1 ? "Feed type cannot be changed" : ""} data-place="bottom" data-effect="solid"
                onClick={() => onClickType(TType.daily)}
            >
                <span>
                    <span className={styles.circle}>
                        <span className={styles.bgcircle}></span>
                        <img
                            src="https://d18dyb0v4b4cx9.cloudfront.net/daily_task.svg"
                            alt="live"
                        />
                    </span>

                    <p>Live Feed</p>
                </span>
                {
                    liveFeedLimit !== null ? (
                        <QuotaCount data-tip={`${liveFeedLimit - liveFeedCount} feed remaining`} isUsedUp={liveFeedCount >= liveFeedLimit}>
                            <p>{liveFeedLimit - liveFeedCount}</p>
                        </QuotaCount>
                    ) : <QuotaCount data-tip={`∞ feed remaining`} isUsedUp={false}>
                        <p>{"∞"}</p>
                    </QuotaCount>
                }
            </div> */}


            {/* <UpgradeLabel>
                Upgrade your plan to create more feed. <span onClick={() => {
                    setNoticeType("general")
                    setShow(true)
                }}>Contact us</span>
            </UpgradeLabel>
            {show && <UpgradePlanNotice onClose={() => setShow(false)} noticeType={noticeType} />} */}
        </div>
    )
}

export default TaskType
