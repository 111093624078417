import React from "react";
import { useHistory } from "react-router-dom";

const Landing = ()=>{
    const history = useHistory();
    React.useEffect(()=>{
        history.push("/brand");
    },[history])
    return (
        <div>
            <h1>Welcome</h1>
        </div>
    )
}

export default Landing;