import React, { useEffect, useState } from 'react'
import { usePostsStore } from '../../postStore'
import { SectionBody, SectionHeader, SectionRoot } from '../styled'
import { SiteForTopThreads, ThreadForTopThreads, Total } from '../../interface'
import SitesPicker from './sitePicker'
import ThreadList from './ThreadList'
import { shorttxt } from '../../../../app/tools/string'
import { sortSite } from '../../helperFn/siteIconFn'
import SingleSelect from '../../../reusable/inputs/select/singleSelect'

function TopThreads() {

    const { overviewPost } = usePostsStore(state => ({
        overviewPost: state.overviewPost,
    }))

    const [sites, setSites] = useState<SiteForTopThreads[]>([])
    const [threads, setThreads] = useState<ThreadForTopThreads[]>([])

    const [viewBy, setViewBy] = useState("engagement")
    const viewOptions = [{ label: "Total Engagement", value: "engagement" }, { label: "No. of Comments", value: "comments" }]

    const selectedLabel = viewOptions.filter((option)=>(option.value === viewBy))[0].label
    
    useEffect(() => {
        const allSiteKeys = overviewPost && Object.keys(overviewPost?.aggregations.top_threads.site)
        console.log("allSiteKeys", allSiteKeys)
        const allSites = (overviewPost && allSiteKeys && allSiteKeys.map((site)=>(
            {
                name: sortSite(site),
                count: viewBy === 'engagement' ? (
                        overviewPost?.aggregations?.top_threads?.site?.[site]?.engagement?.length || 0
                    ):(
                        overviewPost?.aggregations?.top_threads?.site?.[site]?.comments?.length || 0
                    ),
                isActive: true
            }
            ))) || []
            
            allSites && allSites.length > 0 && setSites(allSites)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [overviewPost, viewBy])
    
    ///////////////////////////////////////////////////////////////////////////////
    
    console.log("overviewPost?.aggregations?.top_threads?.total?.[viewBy as keyof Total]", overviewPost?.aggregations?.top_threads?.total?.[viewBy as keyof Total])
    const allThreads = (overviewPost && overviewPost?.aggregations?.top_threads?.total?.[viewBy as keyof Total].map((thread)=>(
        {
            site: sortSite(thread.site),
            count: (viewBy === 'engagement' && thread.engagement) || thread.comment_count,
            url: thread.thread_link,
            title: shorttxt(thread.thread_title, 30),
        }
        )) as ThreadForTopThreads[]) || []
        
    useEffect(() => {
        console.log("allThreads", allThreads?.length)
        allThreads && allThreads.length > 0 && setThreads(allThreads)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overviewPost, viewBy, allThreads?.length])


    useEffect(() => {
        const activeSites = sites.filter((site)=>(
            site.isActive === true
            )).map((site)=> site.name)
            
            const filteredThreads = allThreads.filter((thread)=>(
                activeSites.includes(thread.site)
                ))
                setThreads(filteredThreads)
                
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [sites])
                
                

            console.log("threads", threads, allThreads)
    
    return (
        <SectionRoot>
            <SectionHeader>
                {/* <h3>Top {`${threads.length || ""} Threads`}</h3> */}
                <h3>Top Posts</h3>
                <div>
                    <SingleSelect
                        id="top-sites-view-by"
                        options={viewOptions}
                        value={selectedLabel}
                        setValue={(value) => setViewBy(value as string)}
                        width={180}
                        label="View by:"
                    />
                </div>
            </SectionHeader>
            <SectionBody expanded={true}>
                <SitesPicker 
                    sites={sites} 
                    setSites={setSites} 
                />
                <ThreadList 
                    threads={allThreads} 
                />
            </SectionBody>
        </SectionRoot>
    )
}

export default React.memo(TopThreads)
