import React from "react"
// import Button from "../../../reusable/buttons/Button"
// import AddIcon from "../../../reusable/icons/AddIcon"
import styles from "./button.module.scss"
import TaskEditor from "../../task/editor"
import { useSelector } from "react-redux"
import { selectCredits, selectUserConfig } from "../../../../app/store/userSlice"
import CreditExhaustWarning from "../../task/editor/notices/CreditExhaustWarning"

const CreateFeedButton = () => {
    const [showEditor, setShowEditor] = React.useState(false)
    const [showWarning, setShowWarning] = React.useState(false)
    const userCredits = useSelector(selectCredits)

    return (
        <div>
            <div className="field has-addons">
                <p className="control">
                    {/* <Button icon={<AddIcon fill="#FFFFFF" />} onClick={() => setShowEditor(true)}>
                        <p>New Feed</p>
                    </Button> */}
                    <button className="button" onClick={() => {
                        if(userCredits > 0){
                            setShowEditor(true)
                        } else {
                            setShowWarning(true)
                        }

                    }} id={styles.button}>
                        <span className="icon is-small">
                            <i className="fas fa-plus"></i>
                        </span>
                        <span>New Task</span>
                    </button>
                </p>
            </div>
            {showEditor && <TaskEditor type={0} onClose={() => setShowEditor(false)} />}
            {showWarning && <CreditExhaustWarning onClose={() => setShowWarning(false)} />}
        </div>
    )
}

export default CreateFeedButton