import { MediumCountLineDetails, Sentiments } from "../../../../../../interface"
import moment from "moment"
import {
	sortByTime,
	sortByTimestamp,
} from "../../../../../../helperFn/sortSiteRankingFn"

export const calculateTotalThread = (
	post: MediumCountLineDetails[],
	frequency: string
) => {
	const allMedium = post?.map((x) => x.points)
	let mappedPosts: any = []
	let reducedPosts: any = {}
	const timestampFormat =
		frequency === "Latest" || frequency === "Daily" ? "HH:mm" : "MMM DD"
		
	for (let i = 0; i < allMedium?.length; i++) {
		mappedPosts.push(
			allMedium?.[i].reduce(
				(obj, x) =>
					Object.assign(obj, {
						[x.unix_timestamp]: x.comment_count,
					}),
				{}
			)
		)
	}

	const timestampsAsKeys =
		(mappedPosts.length > 0 && Object.keys(mappedPosts?.[0])) || []

	for (let i = 0; i < timestampsAsKeys?.length || 0; i++) {
		mappedPosts.reduce(
			(a: number, b: ReducedPosts) => {
				let value = b[timestampsAsKeys[i]]
				if(!b[timestampsAsKeys[i]]){
					value = 0
				}
				return (reducedPosts[timestampsAsKeys[i]] = a + value)
				
			}
				,
			0
		)
	}

	let finalData: FinalDataThread[] = []

	for (const item in reducedPosts) {
		finalData.push({ dateTime: item, total: reducedPosts[item] })
	}

	finalData = finalData.sort(sortByTime).map((item) => ({
		...item,
		dateTime: moment(parseInt(item.dateTime)).format(timestampFormat),
	}))

	finalData.pop()

	return finalData
}

export const calculateMedium = (
	mediumCountLine: MediumCountLineDetails[],
	option: string,
	frequency: string,
	medium: string[]
) => {
	const timestampFormat =
		frequency === "Latest" || frequency === "Daily" ? "HH:mm" : "MMM DD"

	const sortedByTimestamp = mediumCountLine.map((item) => ({
		...item,
		points: item.points.sort(sortByTimestamp),
	}))

	const sortedByIndex: SortedByIndex[] = sortedByTimestamp.map((item) => ({
		medium: item.medium,
		dateTime: item.points.map((x) =>
			moment(x.unix_timestamp).format(timestampFormat)
		),
		thread_count: item.points.map((x) => x.comment_count),
		post_count: item.points.map((x) => x.thread_count + x.comment_count),
	}))

	//calculate total
	sortedByIndex.push({
		medium: "Total",
		dateTime: sortedByIndex?.[0]?.dateTime,
		thread_count: sortedByIndex.reduce(
			(r: number[], a) => a.thread_count.map((b, i) => (r[i] || 0) + b),
			[]
		),
		post_count: sortedByIndex.reduce(
			(r: number[], a) => a.post_count.map((b, i) => (r[i] || 0) + b),
			[]
		),
	})

	console.log("sortedByIndex", sortedByIndex)

	const finalData: FinalDataMedium[] =
		(sortedByIndex.length > 0 &&
			sortedByIndex?.[0]?.dateTime?.map((item) => ({
				dateTime: item,
			}))) ||
		[]

	for (let i = 0; i < sortedByIndex.length; i++) {
		for (let j = 0; j < finalData.length; j++) {
			if (
				medium.includes(sortedByIndex[i].medium as string) ||
				medium.length === 0
			) {
				finalData[j][sortedByIndex[i].medium] =
					option === "Threads"
						? sortedByIndex[i].thread_count[j]
						: sortedByIndex[i].post_count[j]
			}
		}
	}

	finalData.pop()

	return finalData
}

export const calculateSentiment = (
	sentiment: Sentiments
	// option: string
) => {
	const sentimentData = Object.keys(sentiment).map((key) => ({
		sentimentScores:
			key === "0" ? "Neutral" : key === "1" ? "Positive" : "Negative",
		sentimentCount: sentiment[parseInt(key)],
	}))

	return sentimentData
}

export interface FinalDataThread {
	dateTime: string
	total: number
}
export interface FinalDataMedium {
	[key: string]: number | string
}

interface SortedByIndex {
	medium: keyof FinalDataMedium
	dateTime: string[]
	thread_count: number[]
	post_count: number[]
}
export interface Points {
	unix_timestamp: number
	thread_count: number
}

interface ReducedPosts {
	[timestamp: string]: number
}
