import React, { useState } from 'react'
import FeedbackRow from '../../FeedbackRow'
import { usePostsStore } from '../../../../postStore'
import ContextSummary from './ContextSummary'
import Statistics from './Statistics'
import { ExpandedCardContainer, TabRow, TabButton } from './styled'
// import { allTopicData } from '../..'

const ExpandedCard = ({topicId, allTopicData}: ExpandedCardProps) => {
  const [active, setActive] = useState<number>(1)
  // const topicData = usePostsStore(state => state.allTopicData)?.filter(topic => topicId === topic.briefData.topic_id)[0]
  const topicData =allTopicData?.filter(topic => topicId === topic.briefData.topic_id)[0]


  return (
    <ExpandedCardContainer>
      <TabRow>
        <TabButton isActive={active===1} onClick={()=>setActive(1)}>Statistics</TabButton>
        <TabButton isActive={active===2} onClick={()=>setActive(2)}>Context Summary</TabButton>
      </TabRow>
      {active === 1 && <Statistics topicData={topicData!}/>}
      {active === 2 && <ContextSummary topicData={topicData!}/>}
      <FeedbackRow topicId={topicId} />
    </ExpandedCardContainer>
  )
}

export default React.memo(ExpandedCard)

interface ExpandedCardProps{
  topicId: string
  allTopicData: any
}