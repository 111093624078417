import {
  OverviewState,
  DateRange,
  displayOvdata,
  TopTrend,
  Hidden,
  TopSites,
  TopAuthors,
  Stats,
  PostCountChartPoint,
  WordCloudItem,
  TopEngagementThread,
  TopGrowthThread,
  Sentiment,
} from "../interfaces/overview";
import { PayloadAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store, RootState } from ".";
import uf from "usefuljs";
import { FeedData } from "../interfaces/feed";
import { removeUser } from "./userSlice";
import {
  mapHidden,
  mapPostCountChart,
  mapSentimentsFromAPI,
  mapStats,
  mapTopAuthors,
  mapTopEngagementThreads,
  mapTopGrowthThreads,
  mapTopSites,
  mapTopTrends,
  mapWordCloud,
  sequentialApiCalls,
  convert_facebook_keys_to_expected,
  convert_instagram_keys_to_expected,
  convert_youtube_keys_to_expected,
  convert_youtube_keys_to_kol,
  convert_instagram_keys_to_kol,
  convert_facebook_keys_to_kol,
  mapSites,
  mapTopChannels
} from "../tools/mapping/overviewMapping";
import {
  PostCountChartFromAPI,
  StatsFromAPI,
  TopAuthorsFromAPI,
  TopGrowthThreadsFromAPI,
  TopSitesFromAPI,
  TopEngagementThreadsFromAPI,
  WordCloudFromAPI,
  TopTrendsFromAPI,
  HiddenFromAPI,
  AuthorDataFromAPI,
  TopicDataFromAPI,
  LiveFeedsFromAPI,
  SentimentsFromAPI,
} from "../interfaces/ResponseFromAPIs";
import {
  generateTimeRangeUnix,
  calculateDefaultDateRangeCode,
} from "../tools/dateRangeConverter";
import { DateRangeFilter } from "../interfaces/misc";
import { ajax } from "usefuljs";
import { SortOption } from "../../components/feed/task/overview/gadgets/authors";
import moment from "moment";

let ac: AbortController | null = null; // abortcontroller cant be stored in redux state, had to be stored here

const initialState: OverviewState = {
  task_id: 0,
  filter: null,
  daterange: DateRange.Week,
  stats: null,
  postCountChart: null,
  topEngagementThreads: null,
  topGrowthThreads: null,
  topSites: null,
  topAuthors: null,
  wordCloud: null,
  toptrends: null,
  sentiments: null,
  sentimentPostData: [],
  sentimentType: null,
  authorData: [],
  threaddata: [],
  influencersData: [],
  selectedInfluencersData: [],
  topicdata: [],
  chartPointData: [],
  fetchingOvdata: false,
  displayOvData: null,
  topChannels: null,
  hide: {
    keywords: [],
    topics: [],
    sites: [],
  },
  compare: {
    task_id: 0,
    filter: null,
    daterange: DateRange.Week,
    stats: null,
    postCountChart: null,
    topEngagementThreads: null,
    topGrowthThreads: null,
    topSites: null,
    topAuthors: null,
    wordCloud: null,
    toptrends: null,
    sentiments: null,
    sentimentPostData: [],
    sentimentType: null,
    authorData: [],
    threaddata: [],
    topicdata: [],
    chartPointData: [],
    fetchingOvdata: false,
    displayOvData: null,
    hide: {
      keywords: [],
      topics: [],
      sites: [],
    },
  },
};



export const fetchOverviewData = createAsyncThunk(
  "feed/overview/fetch",
  async (dateRangeFilter: DateRangeFilter | null, thunk) => {
    try {
      const taskType = store.getState().feed.taskType;
      console.log("Task Type", taskType)
      // if (taskType === 0 && !dateRangeFilter) {
      //   const dateRange = store.getState().overview.daterange;
      //   const [from, to] = generateTimeRangeUnix(dateRange);
      //   const predefinedDateRangeFilter = { start: from, end: to };

      //   thunk.dispatch(fetchHidden());
      //   thunk.dispatch(
      //     fetchSentiments({
      //       ...predefinedDateRangeFilter,
      //       range: dateRange === 0 ? 0 : 1,
      //     })
      //   );
      //   thunk.dispatch(fetchTopSites(predefinedDateRangeFilter));
      //   thunk.dispatch(
      //     fetchTopAuthors({
      //       dateRangeFilter: predefinedDateRangeFilter,
      //       type: "threads",
      //     })
      //   );
      //   thunk.dispatch(fetchStats(predefinedDateRangeFilter));
      //   thunk.dispatch(
      //     fetchPostCountChart({
      //       ...predefinedDateRangeFilter,
      //       range: dateRange === 0 ? 0 : 1,
      //     })
      //   );
      //   thunk.dispatch(fetchWordCloud(predefinedDateRangeFilter));
      //   thunk.dispatch(fetchTopEngagementThreads(predefinedDateRangeFilter));
      //   thunk.dispatch(fetchTopGrowthThreads());
      //   thunk.dispatch(fetchTopTrends());
      // }
      // if (taskType === 0 && dateRangeFilter) {
      //   thunk.dispatch(fetchHidden());
      //   thunk.dispatch(
      //     fetchSentiments({
      //       ...dateRangeFilter,
      //       range: calculateDefaultDateRangeCode(dateRangeFilter),
      //     })
      //   );
      //   thunk.dispatch(fetchTopSites(dateRangeFilter));
      //   thunk.dispatch(fetchTopAuthors({ dateRangeFilter, type: "threads" }));
      //   thunk.dispatch(fetchStats(dateRangeFilter));
      //   thunk.dispatch(
      //     fetchPostCountChart({
      //       ...dateRangeFilter,
      //       range: calculateDefaultDateRangeCode(dateRangeFilter),
      //     })
      //   );
      //   thunk.dispatch(fetchWordCloud(dateRangeFilter));
      //   thunk.dispatch(fetchTopEngagementThreads(dateRangeFilter));
      //   thunk.dispatch(fetchTopGrowthThreads());
      //   thunk.dispatch(fetchTopTrends());
      // }
      if (taskType === 1 && dateRangeFilter) {
        // thunk.dispatch(fetchHidden());
        // thunk.dispatch(
        //   fetchSentiments({
        //     ...dateRangeFilter,
        //     range: calculateDefaultDateRangeCode(dateRangeFilter),
        //   })
        // );
        // thunk.dispatch(fetchTopSites(dateRangeFilter));
        // thunk.dispatch(fetchTopAuthors({ dateRangeFilter, type: "threads" }));
        thunk.dispatch(fetchStats(dateRangeFilter));
        // thunk.dispatch(
        //   fetchPostCountChart({
        //     ...dateRangeFilter,
        //     range: calculateDefaultDateRangeCode(dateRangeFilter),
        //   })
        // );
        thunk.dispatch(fetchWordCloud(dateRangeFilter));
        thunk.dispatch(fetchTopEngagementThreads(dateRangeFilter));
        // thunk.dispatch(fetchTopGrowthThreads());
      } else if(taskType === 2 &&  dateRangeFilter){
        thunk.dispatch(fetchTopChannels(dateRangeFilter));
      }
    } catch (err) {
      console.error("failed to fetch overview data");
    }
  }
);


export const fetchCompareOverviewData = createAsyncThunk(
  "feed/compare/overview/fetch",
  async (dateRangeFilter: DateRangeFilter | null, thunk) => {
    try {
      const taskType = store.getState().feed.compare.taskType;

      //   if (taskType === 0 && !dateRangeFilter) {
      //     const dateRange = store.getState().overview.daterange;
      //     const [from, to] = generateTimeRangeUnix(dateRange);
      //     const predefinedDateRangeFilter = { start: from, end: to };

      //     thunk.dispatch(fetchHidden());
      //     thunk.dispatch(
      //       fetchSentiments({
      //         ...predefinedDateRangeFilter,
      //         range: dateRange === 0 ? 0 : 1,
      //       })
      //     );
      //     thunk.dispatch(fetchTopSites(predefinedDateRangeFilter));
      //     thunk.dispatch(
      //       fetchTopAuthors({
      //         dateRangeFilter: predefinedDateRangeFilter,
      //         type: "threads",
      //       })
      //     );
      //     thunk.dispatch(fetchStats(predefinedDateRangeFilter));
      //     thunk.dispatch(
      //       fetchPostCountChart({
      //         ...predefinedDateRangeFilter,
      //         range: dateRange === 0 ? 0 : 1,
      //       })
      //     );
      //     thunk.dispatch(fetchWordCloud(predefinedDateRangeFilter));
      //     thunk.dispatch(fetchTopEngagementThreads(predefinedDateRangeFilter));
      //     thunk.dispatch(fetchTopGrowthThreads());
      //     thunk.dispatch(fetchTopTrends());
      //   }
      //   if (taskType === 0 && dateRangeFilter) {
      //     thunk.dispatch(fetchHidden());
      //     thunk.dispatch(
      //       fetchSentiments({
      //         ...dateRangeFilter,
      //         range: calculateDefaultDateRangeCode(dateRangeFilter),
      //       })
      //     );
      //     thunk.dispatch(fetchTopSites(dateRangeFilter));
      //     thunk.dispatch(fetchTopAuthors({ dateRangeFilter, type: "threads" }));
      //     thunk.dispatch(fetchStats(dateRangeFilter));
      //     thunk.dispatch(
      //       fetchPostCountChart({
      //         ...dateRangeFilter,
      //         range: calculateDefaultDateRangeCode(dateRangeFilter),
      //       })
      //     );
      //     thunk.dispatch(fetchWordCloud(dateRangeFilter));
      //     thunk.dispatch(fetchTopEngagementThreads(dateRangeFilter));
      //     thunk.dispatch(fetchTopGrowthThreads());
      //     thunk.dispatch(fetchTopTrends());
      //   }

      if (taskType === 1 && dateRangeFilter) {
        // thunk.dispatch(fetchHidden());
        // thunk.dispatch(
        //   fetchCompareSentiments({
        //     ...dateRangeFilter,
        //     range: calculateDefaultDateRangeCode(dateRangeFilter),
        //   })
        // );
        // thunk.dispatch(fetchCompareTopSites(dateRangeFilter));
        // thunk.dispatch(
        //   fetchCompareTopAuthors({ dateRangeFilter, type: "threads" })
        // );
        thunk.dispatch(fetchCompareStats(dateRangeFilter));
        // thunk.dispatch(
        //   fetchComparePostCountChart({
        //     ...dateRangeFilter,
        //     range: calculateDefaultDateRangeCode(dateRangeFilter),
        //   })
        // );
        // thunk.dispatch(fetchCompareWordCloud(dateRangeFilter));
        thunk.dispatch(fetchCompareTopEngagementThreads(dateRangeFilter));
        // thunk.dispatch(fetchTopGrowthThreads());
      }
    } catch (err) {
      console.error("failed to fetch overview data");
    }
  }
);

export const refetchTopAuthorsCommentsData = createAsyncThunk(
  "feed/overview/refetchTopAuthors",
  async (payload: any, thunk) => {
    const { dateRangeFilter, type }: any = payload;
    try {
      const taskType = store.getState().feed.taskType;
      if (taskType === 0 && !dateRangeFilter) {
        const dateRange = store.getState().overview.daterange;
        const [from, to] = generateTimeRangeUnix(dateRange);
        const predefinedDateRangeFilter = { start: from, end: to };
        thunk.dispatch(
          fetchTopAuthors({ dateRangeFilter: predefinedDateRangeFilter, type })
        );
      }
      if (dateRangeFilter) {
        thunk.dispatch(fetchTopAuthors({ dateRangeFilter, type }));
      }
    } catch (err) {
      console.error("failed to refetch top authors data");
    }
  }
);

export const refetchCompareTopAuthorsCommentsData = createAsyncThunk(
  "feed/compare/overview/refetchTopAuthors",
  async (payload: any, thunk) => {
    const { dateRangeFilter, type }: any = payload;
    try {
      const taskType = store.getState().feed.compare.taskType;
      if (taskType === 0 && !dateRangeFilter) {
        const dateRange = store.getState().overview.daterange;
        const [from, to] = generateTimeRangeUnix(dateRange);
        const predefinedDateRangeFilter = { start: from, end: to };
        thunk.dispatch(
          fetchCompareTopAuthors({
            dateRangeFilter: predefinedDateRangeFilter,
            type,
          })
        );
      }
      if (dateRangeFilter) {
        thunk.dispatch(fetchCompareTopAuthors({ dateRangeFilter, type }));
      }
    } catch (err) {
      console.error("failed to refetch top authors data");
    }
  }
);

// Stats cards
export const fetchStats = createAsyncThunk(
  "feed/overview/fetchStats",
  async (dateRangeFilter: DateRangeFilter) => {
    try {
      const taskId = store.getState().feed.task_id;
      const taskSettings = store.getState().feed.settings;
      const mediums = taskSettings?.mediums
      let result = mediums.map(item => `"${item}"`).join(",");
      // const response = {
      //   data: [
      //     {
      //       keyword: "[((香港)) OR ((Hong Kong) AND (China))]",
      //       time: [
      //         {
      //           comment: 35302,
      //           count: 5693,
      //           date: "2024-06-24",
      //           engagement_score: 36864.5,
      //           fb_comment_count: 35302,
      //           fb_engagement_score: 36864.5,
      //           fb_post_count: 5693,
      //           fb_post_negative_count: 0,
      //           fb_post_neutral_count: 0,
      //           fb_post_positive_count: 0,
      //           fb_reaction_all_count: 192133,
      //           fb_reaction_angry_count: 2924,
      //           fb_reaction_haha_count: 27206,
      //           fb_reaction_like_count: 140643,
      //           fb_reaction_love_count: 7053,
      //           fb_reaction_sad_count: 8392,
      //           fb_reaction_wow_count: 5915,
      //           fb_share_count: 14121,
      //           ig_comment_count: 11056,
      //           ig_like_count: 486150,
      //           ig_love_count: 486150,
      //           ig_mentions: 1175,
      //           ig_post_count: 1175,
      //           ig_post_negative_count: 2,
      //           ig_post_neutral_count: 80,
      //           ig_post_positive_count: 8,
      //           like: 140643,
      //           reaction_angry: 2924,
      //           reaction_count: 192133,
      //           reaction_haha: 27206,
      //           reaction_love: 7053,
      //           reaction_sad: 8392,
      //           reaction_wow: 5915,
      //           share: 14121,
      //           yt_comment_count: 15093,
      //           yt_dislike_count: 0,
      //           yt_like_count: 269315,
      //           yt_mentions: 814,
      //           yt_post_count: 814,
      //           yt_post_negative_count: 1,
      //           yt_post_neutral_count: 23,
      //           yt_post_positive_count: 0,
      //           yt_view_count: 5067806,
      //         },
      //         {
      //           comment: 28581,
      //           count: 4510,
      //           date: "2024-06-25",
      //           engagement_score: 25466.7,
      //           fb_comment_count: 28581,
      //           fb_engagement_score: 25466.7,
      //           fb_post_count: 4510,
      //           fb_post_negative_count: 0,
      //           fb_post_neutral_count: 0,
      //           fb_post_positive_count: 0,
      //           fb_reaction_all_count: 118626,
      //           fb_reaction_angry_count: 2715,
      //           fb_reaction_haha_count: 21789,
      //           fb_reaction_like_count: 79452,
      //           fb_reaction_love_count: 3330,
      //           fb_reaction_sad_count: 5816,
      //           fb_reaction_wow_count: 5524,
      //           fb_share_count: 10746,
      //           ig_comment_count: 7437,
      //           ig_like_count: 299931,
      //           ig_love_count: 299931,
      //           ig_mentions: 1157,
      //           ig_post_count: 1157,
      //           ig_post_negative_count: 0,
      //           ig_post_neutral_count: 54,
      //           ig_post_positive_count: 0,
      //           like: 79452,
      //           reaction_angry: 2715,
      //           reaction_count: 118626,
      //           reaction_haha: 21789,
      //           reaction_love: 3330,
      //           reaction_sad: 5816,
      //           reaction_wow: 5524,
      //           share: 10746,
      //           yt_comment_count: 3023,
      //           yt_dislike_count: 0,
      //           yt_like_count: 67500,
      //           yt_mentions: 774,
      //           yt_post_count: 774,
      //           yt_post_negative_count: 1,
      //           yt_post_neutral_count: 26,
      //           yt_post_positive_count: 1,
      //           yt_view_count: 734199,
      //         },
      //       ],
      //     },
      //   ],
      //   et: "2024-06-25",
      //   st: "2024-06-24",
      // };
      const response: StatsFromAPI = await uf.ajax({
        method: "get",
        headers: { Authorization: `Bearer ${store.getState().user.token}` },
        url: `${process.env.REACT_APP_ENDPOINT}/api/timeseries?st=${moment(dateRangeFilter.start).format("YYYY-MM-DD")}&et=${moment(dateRangeFilter.end).format("YYYY-MM-DD")}&nation=HK&q=[${taskSettings?.keywords?.advanced}]&channels=[${result}]`,
      });

      // console.log("Response from timeseries", response)

      // if (response.result !== "success") {
      //   throw new Error();
      // }

      return [mapStats(response?.data?.[0]?.time), mapPostCountChart(response?.data?.[0]?.time), mapSentimentsFromAPI(response?.data?.[0]?.time)];
    } catch (e) {
      return [];
    }
  }
);

export const fetchCompareStats = createAsyncThunk(
  "feed/compare/overview/fetchStats",
  async (dateRangeFilter: DateRangeFilter) => {
    try {
      const taskId = store.getState().feed.compare.task_id;
      const taskSettings = store.getState().feed.compare.settings;
      const response: StatsFromAPI = await uf.ajax({
        method: "get",
        headers: { Authorization: `Bearer ${store.getState().user.token}` },
        url: `${process.env.REACT_APP_ENDPOINT}/api/timeseries?st=${moment(dateRangeFilter.start).format("YYYY-MM-DD")}&et=${moment(dateRangeFilter.end).format("YYYY-MM-DD")}&nation=HK&q=[${taskSettings?.keywords?.advanced}]&channels=["FB","IG","YT"]`,
      });
      // const response: StatsFromAPI = await uf.ajax({
      //   method: "get",
      //   headers: { token: store.getState().user.token },
      //   url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/analytics/stats?from=${dateRangeFilter.start}&to=${dateRangeFilter.end}`,
      // });

      // if (response.result !== "success") {
      //   throw new Error();
      // }

      return [mapStats(response?.data?.[0]?.time), mapPostCountChart(response?.data?.[0]?.time), mapSentimentsFromAPI(response?.data?.[0]?.time)];
    } catch (e) {
      return [];
    }
  }
);

// Top threads
export const fetchTopEngagementThreads = createAsyncThunk(
  "feed/overview/topEngagementThreads",
  async (dateRangeFilter: DateRangeFilter) => {
    try {
      const taskId = store.getState().feed.task_id;
      const taskSettings = store.getState().feed.settings;
      console.log("Engagement getting called",taskSettings?.keywords?.advanced)
      const mediums = taskSettings?.mediums
      let result = mediums.map(item => `"${item}"`).join(",");
      const response: StatsFromAPI = await uf.ajax({
        method: "get",
        headers: { Authorization: `Bearer ${store.getState().user.token}` },
        url: `${process.env.REACT_APP_ENDPOINT}/api/posts?st=${moment(dateRangeFilter.start).format("YYYY-MM-DD")}&et=${moment(dateRangeFilter.end).format("YYYY-MM-DD")}&nation=HK&q=[${taskSettings?.keywords?.advanced}]&channels=[${result}]&offset=0&limit=100`,
      });
      // const response: TopEngagementThreadsFromAPI = await uf.ajax({
      //   method: "post",
      //   headers: { token: store.getState().user.token },
      //   url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/analytics/top_engagement`,
      //   data: {
      //     from: dateRangeFilter.start,
      //     to: dateRangeFilter.end,
      //   },
      // });

      // if (response.result !== "success") {
      //   throw new Error();
      // }
      const data = response?.data[0];
      const fbData = data?.fb_raw || []
      const igData = data?.ig_raw || []
      const ytData = data?.yt_raw || []
      let allConverertedData = []
      fbData.forEach((fbItem:any) => {
        allConverertedData.push(convert_facebook_keys_to_expected(fbItem))
      })
      igData?.forEach((igItem:any) => {
        allConverertedData.push(convert_instagram_keys_to_expected(igItem))
      })
      ytData?.forEach((ytItem:any) => {
        allConverertedData.push(convert_youtube_keys_to_expected(ytItem))
      })
      return [mapTopEngagementThreads(allConverertedData), mapTopSites(allConverertedData), mapTopAuthors(allConverertedData)];
    } catch (e) {
      return [];
    }
  }
);

// Top channels
export const fetchTopChannels = createAsyncThunk(
  "feed/overview/topChannels",
  async (dateRangeFilter: DateRangeFilter, thunk) => {
    try {
      const taskId = store.getState().feed.task_id;
      const taskSettings = store.getState().feed.settings;
      console.log("store.getState().feed", store.getState().feed)
      const keywordType = taskSettings.keywordType
      if(keywordType === 2){
        const keywordCategoryId = taskSettings.keywordCategoryId
      const response: any = await uf.ajax({
        method: "get",
        headers: { Authorization: `Bearer ${store.getState().user.token}` },
        url: `${process.env.REACT_APP_ENDPOINT}/kol-feeds/fetch/${keywordCategoryId}`,
      });
      const data = response
      const postsReponse: any = await uf.ajax({
        method: "get",
        headers: { Authorization: `Bearer ${store.getState().user.token}` },
        url: `${process.env.REACT_APP_ENDPOINT}/post-feeds/fetch/${keywordCategoryId}`,
      });
console.log("postsReponse", postsReponse)
      const postsData = postsReponse
      return [mapTopChannels(data), postsData];

      } else {
      // let keywords = taskSettings?.keywords?.advanced
      const keywords = `(seg:KOL) AND ${taskSettings?.keywords?.advanced}`
      const mediums = taskSettings?.mediums
      let result = mediums.map(item => `"${item}"`).join(",");
      const response: any = await uf.ajax({
        method: "get",
        headers: { Authorization: `Bearer ${store.getState().user.token}` },
        url: `${process.env.REACT_APP_ENDPOINT}/api/influencers?st=${moment(dateRangeFilter.start).format("YYYY-MM-DD")}&et=${moment(dateRangeFilter.end).format("YYYY-MM-DD")}&nation=HK&q=[${keywords}]&channels=[${result}]`,
      });
      console.log("Response Data", response?.data)
      const data = response?.data[0];
      const fbData = data?.fb_top_channels || []
      const igData = data?.ig_top_channels || []
      const ytData = data?.yt_top_channels || []
      
      let allConverertedData = []
      fbData.forEach((fbItem:any) => {
        if(fbItem['follower_count']){
          allConverertedData.push(convert_facebook_keys_to_kol(fbItem))
        }
       
      })
      igData?.forEach((igItem:any) => {
        allConverertedData.push(convert_instagram_keys_to_kol(igItem))
      })
      ytData?.forEach((ytItem:any) => {
        allConverertedData.push(convert_youtube_keys_to_kol(ytItem))
      })
      // thunk.dispatch(setDisplayOvdata(displayOvdata.ChannelsData));
      // const postsReponse: any = await uf.ajax({
      //   method: "get",
      //   headers: { Authorization: `Bearer ${store.getState().user.token}` },
      //   url: `${process.env.REACT_APP_ENDPOINT}/api/posts?st=${moment(dateRangeFilter.start).format("YYYY-MM-DD")}&et=${moment(dateRangeFilter.end).format("YYYY-MM-DD")}&nation=HK&q=[${taskSettings?.keywords?.advanced}]&channels=["FB","IG","YT"]`,
      // });

      // const postsData = postsReponse?.data[0];
      // const fbPostsData = postsData?.fb_raw
      // const igPostsData = postsData?.ig_raw
      // const youtubePostsData = postsData?.yt_raw
      // let allConverertedPostsData = []
      // fbPostsData.forEach((fbItem:any) => {
      //   if(fbItem['follower_count']){
      //     allConverertedPostsData.push(convert_facebook_keys_to_expected(fbItem))
      //   }
       
      // })
      // igPostsData?.forEach((igItem:any) => {
      //   allConverertedPostsData.push(convert_instagram_keys_to_expected(igItem))
      // })
      // youtubePostsData?.forEach((ytItem:any) => {
      //   allConverertedPostsData.push(convert_youtube_keys_to_expected(ytItem))
      // })

      console.log("All Converted Data", allConverertedData, igData)
      return [mapTopChannels(allConverertedData)];
      }

    } catch (e) {
      return [];
    }
  }
);

export const fetchCompareTopEngagementThreads = createAsyncThunk(
  "feed/compare/overview/topEngagementThreads",
  async (dateRangeFilter: DateRangeFilter) => {
    try {
      const taskId = store.getState().feed.compare.task_id;
      const taskSettings = store.getState().feed.compare.settings;
      const mediums = taskSettings?.mediums
      let result = mediums.map(item => `"${item}"`).join(",");
      const response: StatsFromAPI = await uf.ajax({
        method: "get",
        headers: { Authorization: `Bearer ${store.getState().user.token}` },
        url: `${process.env.REACT_APP_ENDPOINT}/api/posts?st=${moment(dateRangeFilter.start).format("YYYY-MM-DD")}&et=${moment(dateRangeFilter.end).format("YYYY-MM-DD")}&nation=HK&q=[${taskSettings?.keywords?.advanced}]&channels=[${result}]&offset=0&limit=100`,
      });
      // const response: TopEngagementThreadsFromAPI = await uf.ajax({
      //   method: "post",
      //   headers: { token: store.getState().user.token },
      //   url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/analytics/top_engagement`,
      //   data: {
      //     from: dateRangeFilter.start,
      //     to: dateRangeFilter.end,
      //   },
      // });

      // if (response.result !== "success") {
      //   throw new Error();
      // }
      const data = response?.data[0];
      const fbData = data?.fb_raw || []
      const igData = data?.ig_raw || []
      const ytData = data?.yt_raw || []
      let allConverertedData = []
      fbData.forEach((fbItem:any) => {
        allConverertedData.push(convert_facebook_keys_to_expected(fbItem))
      })
      igData?.forEach((igItem:any) => {
        allConverertedData.push(convert_instagram_keys_to_expected(igItem))
      })
      ytData?.forEach((ytItem:any) => {
        allConverertedData.push(convert_youtube_keys_to_expected(ytItem))
      })

      return [mapTopEngagementThreads(allConverertedData), mapTopSites(allConverertedData), mapTopAuthors(allConverertedData)];
    } catch (e) {
      return [];
    }
  }
);

// Post count line chart
export const fetchPostCountChart = createAsyncThunk(
  "feed/overview/postCountChart",
  async (dateRangeFilter: {
    start: number;
    end: number;
    range: 0 | 1 | 2 | 3;
  }) => {
    try {
      const taskId = store.getState().feed.task_id;
      const taskSettings = store.getState().feed.settings;
      const response = {
        data: [
          {
            keyword: "[((香港)) OR ((Hong Kong) AND (China))]",
            time: [
              {
                comment: 35302,
                count: 5693,
                date: "2024-06-24",
                engagement_score: 36864.5,
                fb_comment_count: 35302,
                fb_engagement_score: 36864.5,
                fb_post_count: 5693,
                fb_post_negative_count: 0,
                fb_post_neutral_count: 0,
                fb_post_positive_count: 0,
                fb_reaction_all_count: 192133,
                fb_reaction_angry_count: 2924,
                fb_reaction_haha_count: 27206,
                fb_reaction_like_count: 140643,
                fb_reaction_love_count: 7053,
                fb_reaction_sad_count: 8392,
                fb_reaction_wow_count: 5915,
                fb_share_count: 14121,
                ig_comment_count: 11056,
                ig_like_count: 486150,
                ig_love_count: 486150,
                ig_mentions: 1175,
                ig_post_count: 1175,
                ig_post_negative_count: 2,
                ig_post_neutral_count: 80,
                ig_post_positive_count: 8,
                like: 140643,
                reaction_angry: 2924,
                reaction_count: 192133,
                reaction_haha: 27206,
                reaction_love: 7053,
                reaction_sad: 8392,
                reaction_wow: 5915,
                share: 14121,
                yt_comment_count: 15093,
                yt_dislike_count: 0,
                yt_like_count: 269315,
                yt_mentions: 814,
                yt_post_count: 814,
                yt_post_negative_count: 1,
                yt_post_neutral_count: 23,
                yt_post_positive_count: 0,
                yt_view_count: 5067806,
              },
              {
                comment: 28581,
                count: 4510,
                date: "2024-06-25",
                engagement_score: 25466.7,
                fb_comment_count: 28581,
                fb_engagement_score: 25466.7,
                fb_post_count: 4510,
                fb_post_negative_count: 0,
                fb_post_neutral_count: 0,
                fb_post_positive_count: 0,
                fb_reaction_all_count: 118626,
                fb_reaction_angry_count: 2715,
                fb_reaction_haha_count: 21789,
                fb_reaction_like_count: 79452,
                fb_reaction_love_count: 3330,
                fb_reaction_sad_count: 5816,
                fb_reaction_wow_count: 5524,
                fb_share_count: 10746,
                ig_comment_count: 7437,
                ig_like_count: 299931,
                ig_love_count: 299931,
                ig_mentions: 1157,
                ig_post_count: 1157,
                ig_post_negative_count: 0,
                ig_post_neutral_count: 54,
                ig_post_positive_count: 0,
                like: 79452,
                reaction_angry: 2715,
                reaction_count: 118626,
                reaction_haha: 21789,
                reaction_love: 3330,
                reaction_sad: 5816,
                reaction_wow: 5524,
                share: 10746,
                yt_comment_count: 3023,
                yt_dislike_count: 0,
                yt_like_count: 67500,
                yt_mentions: 774,
                yt_post_count: 774,
                yt_post_negative_count: 1,
                yt_post_neutral_count: 26,
                yt_post_positive_count: 1,
                yt_view_count: 734199,
              },
            ],
          },
        ],
        et: "2024-06-25",
        st: "2024-06-24",
      };
      // const response: StatsFromAPI = await uf.ajax({
      //   method: "get",
      //   headers: { Authorization: `Bearer ${store.getState().user.token}` },
      //   url: `${process.env.REACT_APP_ENDPOINT}/api/timeseries?st=${moment(
      //     dateRangeFilter.start
      //   ).format("YYYY-MM-DD")}&et=${moment(dateRangeFilter.end).format(
      //     "YYYY-MM-DD"
      //   )}&nation=HK&q=[${
      //     taskSettings?.keywords?.advanced
      //   }]&channels=["FB","IG","YT"]`,
      // });

      // console.log("Response from timeseries", response);

      // if (response.result !== "success") {
      //   throw new Error();
      // }

      // return mapPostCountChart(response?.data?.[0]?.time);
    } catch (e) {
      return [];
    }
  }
);

export const fetchComparePostCountChart = createAsyncThunk(
  "feed/compare/overview/postCountChart",
  async (dateRangeFilter: {
    start: number;
    end: number;
    range: 0 | 1 | 2 | 3;
  }) => {
    try {
      const taskId = store.getState().feed.compare.task_id;
      const response: PostCountChartFromAPI = await uf.ajax({
        method: "get",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/analytics/linechart?from=${dateRangeFilter.start}&to=${dateRangeFilter.end}&range=${dateRangeFilter.range}`,
      });

      if (response.result !== "success") {
        throw new Error();
      }

      return mapPostCountChart(response);
    } catch (e) {
      return [];
    }
  }
);

export const fetchChartPointData = createAsyncThunk(
  "feed/overview/chartPointData",
  async (dateRangeFilter: DateRangeFilter) => {
    try {
      const res: LiveFeedsFromAPI = await uf.ajax({
        method: "post",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/search`,
        data: {
          task_id: store.getState().feed.task_id,
          from: dateRangeFilter.start,
          to: dateRangeFilter.end,
          sort: "comment_count",
        },
      });

      if (res.result !== "success") {
        throw new Error("failed to fetch chart point data");
      }

      return res.data;
    } catch (e) {
      return [];
    }
  }
);

export const showChartData = createAsyncThunk(
  "feed/overview/showThreadData",
  (dateRangeFilter: DateRangeFilter, thunk): void => {
    thunk.dispatch(setDisplayOvdata(displayOvdata.ChartPointData));
    thunk.dispatch(fetchChartPointData(dateRangeFilter));
  }
);

// Sentiment charts
export const fetchSentiments = createAsyncThunk(
  "feed/overview/sentiments",
  async (dateRangeFilter: {
    start: number;
    end: number;
    range: 0 | 1 | 2 | 3;
  }) => {
    try {
      const taskId = store.getState().feed.task_id;
      const taskSettings = store.getState().feed.settings;
      const response = {
        data: [
          {
            keyword: "[((香港)) OR ((Hong Kong) AND (China))]",
            time: [
              {
                comment: 35302,
                count: 5693,
                date: "2024-06-24",
                engagement_score: 36864.5,
                fb_comment_count: 35302,
                fb_engagement_score: 36864.5,
                fb_post_count: 5693,
                fb_post_negative_count: 0,
                fb_post_neutral_count: 0,
                fb_post_positive_count: 0,
                fb_reaction_all_count: 192133,
                fb_reaction_angry_count: 2924,
                fb_reaction_haha_count: 27206,
                fb_reaction_like_count: 140643,
                fb_reaction_love_count: 7053,
                fb_reaction_sad_count: 8392,
                fb_reaction_wow_count: 5915,
                fb_share_count: 14121,
                ig_comment_count: 11056,
                ig_like_count: 486150,
                ig_love_count: 486150,
                ig_mentions: 1175,
                ig_post_count: 1175,
                ig_post_negative_count: 2,
                ig_post_neutral_count: 80,
                ig_post_positive_count: 8,
                like: 140643,
                reaction_angry: 2924,
                reaction_count: 192133,
                reaction_haha: 27206,
                reaction_love: 7053,
                reaction_sad: 8392,
                reaction_wow: 5915,
                share: 14121,
                yt_comment_count: 15093,
                yt_dislike_count: 0,
                yt_like_count: 269315,
                yt_mentions: 814,
                yt_post_count: 814,
                yt_post_negative_count: 1,
                yt_post_neutral_count: 23,
                yt_post_positive_count: 0,
                yt_view_count: 5067806,
              },
              {
                comment: 28581,
                count: 4510,
                date: "2024-06-25",
                engagement_score: 25466.7,
                fb_comment_count: 28581,
                fb_engagement_score: 25466.7,
                fb_post_count: 4510,
                fb_post_negative_count: 0,
                fb_post_neutral_count: 0,
                fb_post_positive_count: 0,
                fb_reaction_all_count: 118626,
                fb_reaction_angry_count: 2715,
                fb_reaction_haha_count: 21789,
                fb_reaction_like_count: 79452,
                fb_reaction_love_count: 3330,
                fb_reaction_sad_count: 5816,
                fb_reaction_wow_count: 5524,
                fb_share_count: 10746,
                ig_comment_count: 7437,
                ig_like_count: 299931,
                ig_love_count: 299931,
                ig_mentions: 1157,
                ig_post_count: 1157,
                ig_post_negative_count: 0,
                ig_post_neutral_count: 54,
                ig_post_positive_count: 0,
                like: 79452,
                reaction_angry: 2715,
                reaction_count: 118626,
                reaction_haha: 21789,
                reaction_love: 3330,
                reaction_sad: 5816,
                reaction_wow: 5524,
                share: 10746,
                yt_comment_count: 3023,
                yt_dislike_count: 0,
                yt_like_count: 67500,
                yt_mentions: 774,
                yt_post_count: 774,
                yt_post_negative_count: 1,
                yt_post_neutral_count: 26,
                yt_post_positive_count: 1,
                yt_view_count: 734199,
              },
            ],
          },
        ],
        et: "2024-06-25",
        st: "2024-06-24",
      };
      // const res: SentimentsFromAPI = await uf.ajax({
      //   method: "get",
      //   headers: { token: store.getState().user.token },
      //   url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/analytics/sentiment_count?from=${dateRangeFilter.start}&to=${dateRangeFilter.end}&range=${dateRangeFilter.range}`,
      // });

      // if (res.result !== "success") {
      //   throw new Error("failed to fetch chart point data");
      // }

      // return mapSentimentsFromAPI(response?.data?.[0]?.time);
    } catch (e) {
      return [];
    }
  }
);

export const fetchCompareSentiments = createAsyncThunk(
  "feed/compare/overview/sentiments",
  async (dateRangeFilter: {
    start: number;
    end: number;
    range: 0 | 1 | 2 | 3;
  }) => {
    try {
      const taskId = store.getState().feed.compare.task_id;
      const res: SentimentsFromAPI = await uf.ajax({
        method: "get",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/analytics/sentiment_count?from=${dateRangeFilter.start}&to=${dateRangeFilter.end}&range=${dateRangeFilter.range}`,
      });

      if (res.result !== "success") {
        throw new Error("failed to fetch chart point data");
      }

      return mapSentimentsFromAPI(res);
    } catch (e) {
      return [];
    }
  }
);

// Sentiment post details
export const fetchSentimentPostData = createAsyncThunk(
  "feed/posts/fetch",
  async (clickedSentiment: string, thunkAPI): Promise<FeedData[]> => {
    const sentiment = (() => {
      switch (clickedSentiment) {
        case "negative":
          return -1;
        case "neutral":
          return 0;
        case "positive":
          return 1;
        default:
          return null;
      }
    })();
    thunkAPI.dispatch(setDisplayOvdata(displayOvdata.SentimentPostData));
    thunkAPI.dispatch(setSentimentType(sentiment));

    const currentFilter: DateRangeFilter | null =
      store.getState().overview.filter;
    const taskId: number = store.getState().feed.task_id;
    const taskStart: number | null = store.getState().overview.daterange;

    try {
      const res: LiveFeedsFromAPI = await ajax({
        method: "post",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/search`,
        data: {
          task_id: taskId,
          from: currentFilter?.start ?? generateTimeRangeUnix(taskStart)[0],
          to: currentFilter?.end ?? generateTimeRangeUnix(taskStart)[1],
          sites: null,
          mediums: null,
          is_comment: null,
          size: 20,
          offset: 0,
          query: null,
          sort: "unix_timestamp",
          spam: null,
          sentiment: [sentiment],
        },
      });

      if (res.result !== "success") {
        throw new Error("failed to fetch data");
      }

      return res.data;
    } catch (err) {
      throw new Error(err);
    }
  }
);

//on percentage chart
export const fetchSentimentTimelinePostData = createAsyncThunk(
  "feed/posts/fetch",
  async (
    clickedSentiment: { type: 0 | 1 | -1 | null; start: number; end: number },
    thunkAPI
  ): Promise<FeedData[]> => {
    thunkAPI.dispatch(setDisplayOvdata(displayOvdata.SentimentPostData));
    thunkAPI.dispatch(setSentimentType(clickedSentiment.type));

    const taskId: number = store.getState().feed.task_id;

    try {
      const res: LiveFeedsFromAPI = await ajax({
        method: "post",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/search`,
        data: {
          task_id: taskId,
          from: clickedSentiment.start,
          to: clickedSentiment.end,
          sites: null,
          mediums: null,
          is_comment: null,
          size: 20,
          offset: 0,
          query: null,
          sort: "unix_timestamp",
          spam: null,
          sentiment: [clickedSentiment.type],
        },
      });

      if (res.result !== "success") {
        throw new Error("failed to fetch data");
      }

      return res.data;
    } catch (err) {
      throw new Error(err);
    }
  }
);

//on percentage chart
export const fetchCompareSentimentTimelinePostData = createAsyncThunk(
  "feed/posts/fetch",
  async (
    clickedSentiment: { type: 0 | 1 | -1 | null; start: number; end: number },
    thunkAPI
  ): Promise<FeedData[]> => {
    thunkAPI.dispatch(setDisplayOvdata(displayOvdata.SentimentPostData));
    thunkAPI.dispatch(setSentimentType(clickedSentiment.type));

    const taskId: number = store.getState().feed.compare.task_id;

    try {
      const res: LiveFeedsFromAPI = await ajax({
        method: "post",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/search`,
        data: {
          task_id: taskId,
          from: clickedSentiment.start,
          to: clickedSentiment.end,
          sites: null,
          mediums: null,
          is_comment: null,
          size: 20,
          offset: 0,
          query: null,
          sort: "unix_timestamp",
          spam: null,
          sentiment: [clickedSentiment.type],
        },
      });

      if (res.result !== "success") {
        throw new Error("failed to fetch data");
      }

      return res.data;
    } catch (err) {
      throw new Error(err);
    }
  }
);

// Trending topic
export const fetchTopTrends = createAsyncThunk(
  "feed/overview/topTrends",
  async () => {
    try {
      const taskId = store.getState().feed.task_id;
      const dateRange = store.getState().overview.daterange;
      const response: TopTrendsFromAPI = await uf.ajax({
        method: "get",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/analytics/top_term?ide=${dateRange}`,
      });

      if (response.result !== "success") {
        throw new Error();
      }

      return mapTopTrends(response);
    } catch (e) {
      return [];
    }
  }
);

export const showTopicData = createAsyncThunk<
  void,
  { topics: string[]; taskId: number }
>(
  "feed/overview/showTopicData",
  ({ topics, taskId }: { topics: string[]; taskId: number }, thunk): void => {
    thunk.dispatch(setDisplayOvdata(displayOvdata.TopicData));
    thunk.dispatch(fetchTopicData({ topics, taskId }));
  }
);

export const fetchTopicData = createAsyncThunk<
  FeedData[],
  { topics: string[]; taskId: number }
>(
  "feed/overview/topicData",
  async ({ topics, taskId }: { topics: string[]; taskId: number }) => {
    try {
      const token = store.getState().user.token;

      const response: TopicDataFromAPI = await uf.ajax({
        method: "post",
        headers: { token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/search/phrases`,
        data: topics,
      });

      if (response.result !== "success")
        throw new Error("failed to fetch topic data");

      return response.data;
    } catch (err) {
      console.error(err.message);
      return [];
    }
  }
);



export const fetchTopGrowthThreads = createAsyncThunk(
  "feed/overview/topGrowthThreads",
  async () => {
    try {
      const taskId = store.getState().feed.task_id;
      const dateRange = store.getState().overview.daterange;
      const response: TopGrowthThreadsFromAPI = await uf.ajax({
        method: "get",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/analytics/top_growth?ide=${dateRange}`,
      });

      if (response.result !== "success") {
        throw new Error();
      }

      return mapTopGrowthThreads(response);
    } catch (e) {
      return [];
    }
  }
);

export const fetchCompareTopGrowthThreads = createAsyncThunk(
  "feed/compare/overview/topGrowthThreads",
  async () => {
    try {
      const taskId = store.getState().feed.compare.task_id;
      const dateRange = store.getState().overview.daterange;
      const response: TopGrowthThreadsFromAPI = await uf.ajax({
        method: "get",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/analytics/top_growth?ide=${dateRange}`,
      });

      if (response.result !== "success") {
        throw new Error();
      }

      return mapTopGrowthThreads(response);
    } catch (e) {
      return [];
    }
  }
);

export const showThreadData = createAsyncThunk<void, string>(
  "feed/overview/showThreadData",
  (thread_link: string, thunk): void => {
    thunk.dispatch(setDisplayOvdata(displayOvdata.ThreadData));
    thunk.dispatch(fetchThreadData(thread_link));
  }
);


export const fetchThreadData = createAsyncThunk<FeedData[], string>(
  "feed/overview/threaddata",
  async (thread_link: string, thunk) => {
    const token = store.getState().user.token;

    if (ac !== null) {
      ac.abort();
    }
    ac = new AbortController();
    let aborted = false;

    thunk.dispatch(setFetchingOVdata(true));

    const fetchd: Promise<FeedData[]> = await uf
      .ajax({
        method: "post",
        headers: { token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/thread`,
        signal: ac?.signal,
        data: {
          thread_link,
        },
      })
      .catch((err: Error) => {
        if (err.name === "AbortError") {
          // aborted
          aborted = true;
        }
      })
      .then((res: any) => {
        if (res.result !== "success") {
          throw new Error(res.error);
        }
        return res.data;
      })
      .finally(() => {
        if (!aborted) thunk.dispatch(setFetchingOVdata(false));
      });
    return fetchd;
  }
);

export const showInfluencersData = createAsyncThunk<void, string>(
  "feed/overview/showInfluencersData",
  (auhor_name: string, thunk): void => {
    thunk.dispatch(setDisplayOvdata(displayOvdata.ChannelsData));
  
     thunk.dispatch(fetchSelectedInfluencersData(auhor_name));
  }
);

export const fetchSelectedInfluencersData = createAsyncThunk<any, string>(
  "feed/overview/selectedInfluencersData",
  async (auhor_name: string, thunk) => {

    thunk.dispatch(setFetchingOVdata(true));

    const influencersData = store.getState().overview.influencersData;
    let list:any = []
    influencersData?.forEach(influencer => {
      if(influencer?.author_name === auhor_name){
        list.push(influencer)
      }
    })
    thunk.dispatch(setFetchingOVdata(false));
    return list

 
  }
);

// Word cloud
export const fetchWordCloud = createAsyncThunk(
  "feed/overview/wordCloud",
  async (dateRangeFilter: DateRangeFilter) => {
    try {
      const taskId = store.getState().feed.task_id;
      const response: WordCloudFromAPI = await uf.ajax({
        method: "get",
        headers: { Authorization: `Bearer ${store.getState().user.token}` },
        url: `${process.env.REACT_APP_ENDPOINT}/wordcloud/fetch/${taskId}`,
      });

      console.log("Word cloud Data", response)

      if (response.result !== "success") {
        throw new Error();
      }



      return mapWordCloud(response) as WordCloudItem[];
    } catch (e) {
      return [];
    }
  }
);

export const fetchCompareWordCloud = createAsyncThunk(
  "feed/compare/overview/wordCloud",
  async (dateRangeFilter: DateRangeFilter) => {
    try {
      const taskId = store.getState().feed.compare.task_id;
      const response: WordCloudFromAPI = await uf.ajax({
        method: "get",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/wordcloud?from=${dateRangeFilter.start}&to=${dateRangeFilter.end}`,
      });

      if (response.result !== "success") {
        throw new Error();
      }

      return mapWordCloud(response) as WordCloudItem[];
    } catch (e) {
      return [];
    }
  }
);

// Top authors
export const fetchTopAuthors: any = createAsyncThunk(
  "feed/overview/fetchTopAuthors",
  async (payload) => {
    const { dateRangeFilter, type }: any = payload;
    try {
      const taskId = store.getState().feed.task_id;
      const response: TopAuthorsFromAPI = await uf.ajax({
        method: "get",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/analytics/top_authors?from=${dateRangeFilter.start}&to=${dateRangeFilter.end}`,
      });

      if (response.result !== "success") {
        throw new Error();
      }
      if (response) {
        if (type === "threads") {
          const threadList = await sequentialApiCalls(
            response?.threads,
            store.getState().user.token,
            taskId
          );
          console.log("Thread List", threadList);
          response.threads = threadList.sort(
            (a, b) => b?.post_count - a?.post_count
          );
        }
        if (type === "comments") {
          const commentList = await sequentialApiCalls(
            response?.comments,
            store.getState().user.token,
            taskId
          );
          response.comments = commentList.sort(
            (a, b) => b?.post_count - a?.post_count
          );
        }
        if (type === "threads and comments") {
          const totalList = await sequentialApiCalls(
            response?.total,
            store.getState().user.token,
            taskId
          );
          response.total = totalList.sort(
            (a, b) => b?.post_count - a?.post_count
          );
        }

        return mapTopAuthors(response);
      }
    } catch (e) {
      return null;
    }
  }
);

export const fetchCompareTopAuthors: any = createAsyncThunk(
  "feed/compare/overview/fetchTopAuthors",
  async (payload) => {
    const { dateRangeFilter, type }: any = payload;
    try {
      const taskId = store.getState().feed.compare.task_id;
      const response: TopAuthorsFromAPI = await uf.ajax({
        method: "get",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/analytics/top_authors?from=${dateRangeFilter.start}&to=${dateRangeFilter.end}`,
      });

      if (response.result !== "success") {
        throw new Error();
      }
      if (response) {
        if (type === "threads") {
          const threadList = await sequentialApiCalls(
            response?.threads,
            store.getState().user.token,
            taskId
          );
          response.threads = threadList.sort(
            (a, b) => b?.post_count - a?.post_count
          );
        }
        if (type === "comments") {
          const commentList = await sequentialApiCalls(
            response?.comments,
            store.getState().user.token,
            taskId
          );
          response.comments = commentList.sort(
            (a, b) => b?.post_count - a?.post_count
          );
        }
        if (type === "threads and comments") {
          const totalList = await sequentialApiCalls(
            response?.total,
            store.getState().user.token,
            taskId
          );
          response.total = totalList.sort(
            (a, b) => b?.post_count - a?.post_count
          );
        }

        return mapTopAuthors(response);
      }
    } catch (e) {
      return null;
    }
  }
);

export const showAuthorData = createAsyncThunk<void, string>(
  "feed/overview/showAuthorData",
  (author_id: string, thunk): void => {
    thunk.dispatch(setDisplayOvdata(displayOvdata.AuthorData));
    thunk.dispatch(fetchAuthorData(author_id));
  }
);

export const fetchAuthorData = createAsyncThunk(
  "feed/overview/authorData",
  async (authorId: string) => {
    try {
      const token = store.getState().user.token;
      const taskId = store.getState().feed.task_id;

      const response: AuthorDataFromAPI = await uf.ajax({
        method: "get",
        headers: { token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/author?task_id=${taskId}&author_id=${authorId}`,
      });

      if (response.result !== "success")
        throw new Error("failed to fetch author data");
      return response.data as FeedData[];
    } catch (err) {
      console.error(err.message);
      return [];
    }
  }
);

// Top sites
export const fetchTopSites = createAsyncThunk(
  "feed/overview/fetchTopSites",
  async (dateRangeFilter: DateRangeFilter) => {
    try {
      const taskId = store.getState().feed.task_id;
      const response: TopSitesFromAPI = await uf.ajax({
        method: "get",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/analytics/top_sites?from=${dateRangeFilter.start}&to=${dateRangeFilter.end}`,
      });

      if (response.result !== "success") {
        throw new Error();
      }

      return mapTopSites(response);
    } catch (e) {
      return null;
    }
  }
);

export const fetchCompareTopSites = createAsyncThunk(
  "feed/compare/overview/fetchTopSites",
  async (dateRangeFilter: DateRangeFilter) => {
    try {
      const taskId = store.getState().feed.compare.task_id;
      const response: TopSitesFromAPI = await uf.ajax({
        method: "get",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/analytics/top_sites?from=${dateRangeFilter.start}&to=${dateRangeFilter.end}`,
      });

      if (response.result !== "success") {
        throw new Error();
      }

      return mapTopSites(response);
    } catch (e) {
      return null;
    }
  }
);

export const fetchHidden = createAsyncThunk(
  "feed/overview/hidden",
  async () => {
    try {
      const taskId = store.getState().feed.task_id;
      const response: HiddenFromAPI = await uf.ajax({
        method: "get",
        headers: { token: store.getState().user.token },
        url: `${process.env.REACT_APP_ENDPOINT}/api/task/${taskId}/overview/hide`,
      });

      if (response.result !== "success") {
        throw new Error();
      }

      return mapHidden(response);
    } catch (e) {
      return {
        keywords: [],
        topics: [],
        sites: [],
      };
    }
  }
);

export const setTask = createAsyncThunk(
  "feed/overview/setTaskid",
  (taskid: number, thunk) => {
    thunk.dispatch(setTaskid(taskid));
  }
);

export const setDateRange = createAsyncThunk(
  "feed/overview/setDaterange",
  (dr: DateRange, thunk) => {
    thunk.dispatch(setDaterange(dr));
    thunk.dispatch(fetchOverviewData(null));
  }
);

// slice
export const OverviewSlice = createSlice({
  name: "feed/overview",
  initialState,
  reducers: {
    setTaskid: (state, action: PayloadAction<number>) => {
      state.task_id = action.payload;
    },
    setDaterange: (state, action: PayloadAction<DateRange>) => {
      state.daterange = action.payload;
    },
    setTopTrends: (state, action: PayloadAction<TopTrend[]>) => {
      state.toptrends = action.payload ?? [];
    },
    setFetchingOVdata: (state, action: PayloadAction<boolean>) => {
      state.fetchingOvdata = action.payload;
    },
    setDisplayOvdata: (state, action: PayloadAction<displayOvdata | null>) => {
      state.displayOvData = action.payload;
    },
    setSentimentType: (state, action: PayloadAction<null | 0 | 1 | -1>) => {
      state.sentimentType = action.payload;
    },
    setHidden: (state, action: PayloadAction<Hidden>) => {
      state.hide.keywords = action.payload.keywords;
      state.hide.topics = action.payload.topics;
      state.hide.sites = action.payload.sites;
    },
    clearOverviewData: (state) => {
      state.stats = null;
      state.postCountChart = null;
      state.topSites = null;
      state.topAuthors = null;
      state.wordCloud = null;
      state.topEngagementThreads = null;
      state.topGrowthThreads = null;
      state.toptrends = null;
      state.sentiments = null;
      state.sentimentPostData = [];
      state.sentimentType = null;
      state.authorData = [];
      state.threaddata = [];
      state.influencersData = [];
      state.selectedInfluencersData = [];
      state.topicdata = [];
      state.topChannels = null;
      state.hide = {
        keywords: [],
        topics: [],
        sites: [],
      };
    },
    clearAuthorData: (state) => {
      state.topAuthors = null;
      state.compare.topAuthors = null;
    },
    clearModalData: (state) => {
      switch (state.displayOvData) {
        case displayOvdata.AuthorData:
          state.authorData = [];
          break;
        case displayOvdata.ThreadData:
          state.threaddata = [];
          break;
        case displayOvdata.TopicData:
          state.topicdata = [];
          break;
        case displayOvdata.SentimentPostData:
          state.sentimentPostData = [];
          state.sentimentType = null;
          break;
      }
    },
    setOverviewFilter: (state, action: PayloadAction<DateRangeFilter>) => {
      state.filter = { start: action.payload.start, end: action.payload.end };
    },
  },
  extraReducers: {
    // FETCH AUTHOR OVERVIEW DATA
    [fetchAuthorData.pending.toString()]: (state, action) => {
      state.fetchingOvdata = true;
    },
    [fetchAuthorData.fulfilled.toString()]: (
      state,
      action: PayloadAction<FeedData[]>
    ) => {
      state.authorData = action.payload;
      state.fetchingOvdata = false;
    },
    [fetchAuthorData.rejected.toString()]: (state, action) => {
      state.fetchingOvdata = false;
    },
    // FETCH THREAD OVERVIEW DATA
    [fetchThreadData.fulfilled.toString()]: (
      state,
      action: PayloadAction<FeedData[]>
    ) => {
      state.threaddata = action.payload;
    },
    // FETCH THREAD OVERVIEW DATA
    [fetchTopicData.pending.toString()]: (state) => {
      state.fetchingOvdata = true;
    },
    [fetchTopicData.fulfilled.toString()]: (
      state,
      action: PayloadAction<FeedData[]>
    ) => {
      state.topicdata = action.payload;
      state.fetchingOvdata = false;
    },
    [fetchTopicData.rejected.toString()]: (state) => {
      state.fetchingOvdata = false;
    },
    [removeUser.fulfilled.toString()]: (state) => {
      Object.assign(state, initialState);
    },
    [fetchTopSites.fulfilled.toString()]: (
      state,
      action: PayloadAction<TopSites>
    ) => {
      state.topSites = action.payload;
    },
    [fetchCompareTopSites.fulfilled.toString()]: (
      state,
      action: PayloadAction<TopSites>
    ) => {
      state.compare.topSites = action.payload;
    },
    [fetchTopAuthors.fulfilled.toString()]: (
      state,
      action: PayloadAction<TopAuthors>
    ) => {
      state.topAuthors = action.payload;
    },
    [fetchCompareTopAuthors.fulfilled.toString()]: (
      state,
      action: PayloadAction<TopAuthors>
    ) => {
      state.compare.topAuthors = action.payload;
    },
    [fetchStats.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.stats = action.payload[0];
      state.postCountChart = action.payload[1];
      state.sentiments = action.payload[2];
    },
    [fetchCompareStats.fulfilled.toString()]: (
      state,
      action: PayloadAction<Stats>
    ) => {
      state.compare.stats = action.payload[0];
      state.compare.postCountChart = action.payload[1];
      state.compare.sentiments = action.payload[2];
    },
    [fetchPostCountChart.pending.toString()]: (
      state,
      action: PayloadAction<PostCountChartPoint[]>
    ) => {
      state.postCountChart = null;
    },
    [fetchPostCountChart.fulfilled.toString()]: (
      state,
      action: PayloadAction<PostCountChartPoint[]>
    ) => {
      // state.postCountChart = action.payload;
    },
    [fetchComparePostCountChart.pending.toString()]: (
      state,
      action: PayloadAction<PostCountChartPoint[]>
    ) => {
      state.compare.postCountChart = null;
    },
    [fetchComparePostCountChart.fulfilled.toString()]: (
      state,
      action: PayloadAction<PostCountChartPoint[]>
    ) => {
      state.compare.postCountChart = action.payload;
    },
    [fetchWordCloud.fulfilled.toString()]: (
      state,
      action: PayloadAction<WordCloudItem[]>
    ) => {
      state.wordCloud = action.payload;
    },
    [fetchCompareWordCloud.fulfilled.toString()]: (
      state,
      action: PayloadAction<WordCloudItem[]>
    ) => {
      state.compare.wordCloud = action.payload;
    },
    [fetchTopEngagementThreads.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.topEngagementThreads = action.payload[0];
      state.topSites = action.payload[1];
      state.topAuthors = action.payload[2];
    },
    [fetchTopChannels.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      console.log("Top Channels12", action.payload)
      state.topChannels = action.payload[0];
      if(action?.payload?.length > 1){
        state.influencersData = action.payload[1]
      }
     
    },
    [fetchSelectedInfluencersData.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.selectedInfluencersData = action.payload
    },
    [fetchCompareTopEngagementThreads.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.compare.topEngagementThreads = action.payload[0];
      state.compare.topSites = action.payload[1];
      state.compare.topAuthors = action.payload[2];
    },
    [fetchTopGrowthThreads.fulfilled.toString()]: (
      state,
      action: PayloadAction<TopGrowthThread[]>
    ) => {
      state.topGrowthThreads = action.payload;
    },
    [fetchCompareTopGrowthThreads.fulfilled.toString()]: (
      state,
      action: PayloadAction<TopGrowthThread[]>
    ) => {
      state.compare.topGrowthThreads = action.payload;
    },
    [fetchTopTrends.fulfilled.toString()]: (
      state,
      action: PayloadAction<TopTrend[]>
    ) => {
      state.toptrends = action.payload;
    },
    [fetchHidden.fulfilled.toString()]: (
      state,
      action: PayloadAction<Hidden>
    ) => {
      state.hide.keywords = action.payload.keywords;
      state.hide.sites = action.payload.sites;
      state.hide.topics = action.payload.topics;
    },
    [fetchChartPointData.pending.toString()]: (state) => {
      state.fetchingOvdata = true;
    },
    [fetchChartPointData.fulfilled.toString()]: (
      state,
      action: PayloadAction<FeedData[]>
    ) => {
      state.chartPointData = action.payload;
      state.fetchingOvdata = false;
    },
    [fetchChartPointData.rejected.toString()]: (state) => {
      state.fetchingOvdata = false;
    },
    [fetchSentiments.fulfilled.toString()]: (
      state,
      action: PayloadAction<Sentiment[]>
    ) => {
      // state.sentiments = action.payload;
    },
    [fetchCompareSentiments.fulfilled.toString()]: (
      state,
      action: PayloadAction<Sentiment[]>
    ) => {
      state.compare.sentiments = action.payload;
    },
    [fetchSentimentPostData.pending.toString()]: (state) => {
      state.fetchingOvdata = true;
    },
    [fetchSentimentPostData.fulfilled.toString()]: (
      state,
      action: PayloadAction<FeedData[]>
    ) => {
      state.sentimentPostData = action.payload;
      state.fetchingOvdata = false;
    },
    [fetchSentimentPostData.rejected.toString()]: (state, action) => {
      state.fetchingOvdata = false;
    },
  },
});

// actions
export const {
  setTaskid,
  setDaterange,
  setTopTrends,
  setHidden,
  setFetchingOVdata,
  setDisplayOvdata,
  setSentimentType,
  clearOverviewData,
  clearAuthorData,
  clearModalData,
  setOverviewFilter,
} = OverviewSlice.actions;

// selector
export const selectDateRange = (state: RootState) => state.overview.daterange;
export const selectStats = (state: RootState) => state.overview.stats;
export const selectCompareStats = (state: RootState) =>
  state.overview.compare.stats;
export const selectPostCountChart = (state: RootState) =>
  state.overview.postCountChart;
export const selectComparePostCountChart = (state: RootState) =>
  state.overview.compare.postCountChart;
export const SelectAuthors = (state: RootState) => state.overview.topAuthors;
export const SelectCompareAuthors = (state: RootState) =>
  state.overview.compare.topAuthors;
export const selectTopEngagementThreads = (state: RootState) =>
  state.overview.topEngagementThreads;
export const selectTopChannels = (state: RootState) =>
  state.overview.topChannels;
export const selectCompareTopEngagementThreads = (state: RootState) =>
  state.overview.compare.topEngagementThreads;
export const selectTopGrowthThreads = (state: RootState) =>
  state.overview.topGrowthThreads;
export const selectCompareTopGrowthThreads = (state: RootState) =>
  state.overview.compare.topGrowthThreads;
export const selectTopSites = (state: RootState) => state.overview.topSites;
export const selectCompareTopSites = (state: RootState) =>
  state.overview.compare.topSites;
export const selectWordCloud = (state: RootState) => state.overview.wordCloud;
export const selectCompareWordCloud = (state: RootState) =>
  state.overview.compare.wordCloud;
export const selectTopTrends = (state: RootState) => state.overview.toptrends;
export const selectDisplayOvdata = (state: RootState) =>
  state.overview.displayOvData;
export const selectFetchingOVdata = (state: RootState) =>
  state.overview.fetchingOvdata;
export const selectHiddenKeywords = (state: RootState) =>
  state.overview.hide?.keywords;
export const selectCompareHiddenKeywords = (state: RootState) =>
  state.overview.compare?.hide?.keywords;
export const selectHiddenTopics = (state: RootState) =>
  state.overview.hide?.topics;
export const selectHiddenSites = (state: RootState) =>
  state.overview.hide?.sites;
export const selectSentiments = (state: RootState) => state.overview.sentiments;
export const selectCompareSentiments = (state: RootState) =>
  state.overview.compare.sentiments;
export const selectSentimentType = (state: RootState) =>
  state.overview.sentimentType;
export const selectOvdata = (state: RootState) => {
  switch (state.overview.displayOvData) {
    case displayOvdata.AuthorData:
      return state.overview.authorData;
    case displayOvdata.ThreadData:
      return state.overview.threaddata;
    case displayOvdata.TopicData:
      return state.overview.topicdata;
    case displayOvdata.ChartPointData:
      return state.overview.chartPointData;
    case displayOvdata.SentimentPostData:
      return state.overview.sentimentPostData;
    case displayOvdata.ChannelsData:
      return state.overview.selectedInfluencersData
  }
};
export const selectOverviewFilter = (state: RootState) => state.overview.filter;

export default OverviewSlice.reducer;
