import React, { useContext } from "react";
import styles from "./sources.module.scss";
import { useSelector } from "react-redux";
import { selectMediums } from "../../../../../app/store/miscSlice";
import Ctx, { Context } from "../ctx";
import { setTaskMediums } from "../ctx/action";
import uf from "usefuljs";
import Switch from "../../../../reusable/switch";

const Sources = () => {
  const mediums: string[] = useSelector(selectMediums);

  const ctx = useContext(Ctx) as Context;

  const pickedMediums: string[] = ctx.editor.detail.mediums
    ? ctx.editor.detail.mediums
    : mediums;

  const socialMediumList: Record<string, string> = {
    FB: "Facebook",
    "IG": "Instagram",
    YT: "Youtube",
  };
  const otherChannelList: Record<string, string> = React.useMemo(() => {
    const otherMediums: Record<string, string> = {};
    for (let medium of mediums) {
      if (!socialMediumList[medium]) {
        otherMediums[medium] = medium;
      }
    }
    return otherMediums;
  }, [socialMediumList, mediums]);

  const onPickMedium = (med: string) => {
    if (pickedMediums.includes(med)) {
      setTaskMediums(
        [...pickedMediums].filter((medium) => medium !== med),
        ctx.setEditor
      );
    } else {
      setTaskMediums([...pickedMediums, med], ctx.setEditor);
    }
  };

  const onToggleSocial = React.useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        //turn all on
        setTaskMediums(
          uf.arr_dedup([...pickedMediums, ...Object.keys(socialMediumList)]),
          ctx.setEditor
        );
      } else {
        //turn all off
        const socialMediumListExcludedMediums = [...pickedMediums].filter(
          (medium) => {
            return !Object.keys(socialMediumList).includes(medium);
          }
        );
        setTaskMediums(socialMediumListExcludedMediums, ctx.setEditor);
      }
    },
    [ctx.setEditor, socialMediumList, pickedMediums]
  );

  const onToggleOth = React.useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        setTaskMediums(
          uf.arr_dedup([...pickedMediums, ...Object.keys(otherChannelList)]),
          ctx.setEditor
        );
      } else {
        const otherListExcludedMediums = [...pickedMediums].filter((medium) => {
          return !Object.keys(otherChannelList).includes(medium);
        });
        setTaskMediums(otherListExcludedMediums, ctx.setEditor);
      }
    },
    [ctx.setEditor, otherChannelList, pickedMediums]
  );

  const getMediumDscrp = React.useCallback((medium: string) => {
    switch (medium.toLowerCase()) {
      case "fb":
        return "Facebook post,page and group";
      case "media":
        return "Other social sites";
      case "ig":
        return "Instagram posts";
      case "telegram":
        return "100+ channels";
      case "yt":
        return "Youtube,etc";
      case "forum":
        return "LIHKG, HKGolden, Babykingdom, Babydiscuss, etc.";
      case "news":
        return "HK01, Mingpao etc.";
      case "blog":
        return "SHE.com, BeautyExchange etc.";
      default:
        return "";
    }
  }, []);


  console.log("ctx.editor.detail", ctx.editor.detail)

  return (
    <div className={styles.sources}>
      <p>Select sources to collect posts from</p>
      <div className={styles.mediumpicker}>
        <div className={styles.header}>
          <h3>Social</h3>
          <label>
            <Switch
              checked={Object.keys(socialMediumList).every((socialMedium) =>
                pickedMediums.includes(socialMedium)
              )}
              handleChange={onToggleSocial}
            />
            <span>All</span>
          </label>
        </div>
        {/* <div className={styles.header}>
          <h3>Other Channels</h3>
          <label>
            <Switch
              checked={Object.keys(otherChannelList).every((otherMedium) =>
                pickedMediums.includes(otherMedium)
              )}
              handleChange={onToggleOth}
            />
            <span>All</span>
          </label>
        </div> */}
        <div className={styles.list}>
          {Object.keys(socialMediumList).map((s) => (
            <label key={s} className={styles.item}>
              <span>{socialMediumList[s]}</span>
              <Switch
                checked={pickedMediums.includes(s)}
                handleChange={() => onPickMedium(s)}
              />
              <span className={styles.dscrp}>{getMediumDscrp(s)}</span>
            </label>
          ))}
        </div>
        {/* <div className={styles.list}>
          {Object.keys(otherChannelList).map((s) => (
            <label key={s} className={styles.item}>
              <span>{otherChannelList[s]}</span>
              <Switch
                checked={pickedMediums.includes(s)}
                handleChange={() => onPickMedium(s)}
              />
              <span className={styles.dscrp}>{getMediumDscrp(s)}</span>
            </label>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default Sources;
