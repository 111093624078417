import React from 'react'
import SummaryCard from '../../../../../../../reusable/cards/summaryCard/SummaryCard'
import ArrowUp from '../../../../../../../reusable/icons/ArrowUp'
import ArrowUpWithCalendar from '../../../../../../../reusable/icons/ArrowUpWithCalendar'
// import ArrowUpWithCalendar from '../../../../../../../reusable/icons/ArrowUpWithCalendar'
import AuthorWithPen from '../../../../../../../reusable/icons/AuthorWithPen'
import ShareIcon from '../../../../../../../reusable/icons/ShareIcon'
import ThumbsUp from '../../../../../../../reusable/icons/ThumbsUp'
import Skeleton from '../../../../../../../reusable/utils/Skeleton'
import { getHighestChance } from '../../../../../../helperFn/trendForecastText'
import { Frequency, TopicData, TrendPeriod } from '../../../../../../interface'
import { SummaryCardsRowContainer, SummaryCardContainer } from '../styled'

const SummaryCardsRow = ({frequency, topicData}: SummaryCardsRowProps) => {

  const comparedTotal = (topicData.detailData && Math.round(
    Math.abs((
      topicData.detailData.results?.[frequency]?.aggregations?.top_stats?.total - 
      topicData.detailData.results?.[frequency]?.aggregations?.p_stats?.avg_count
    ) / Math.ceil(topicData.detailData?.results?.[frequency]?.aggregations?.p_stats?.avg_count)
    ) * 100)) || 0

  const higherOrLower = (topicData.detailData && (topicData.detailData.results?.[frequency]?.aggregations?.top_stats?.total > 
    topicData.detailData.results?.[frequency]?.aggregations?.p_stats?.avg_count)) ? 'higher': 'lower'

  const highestTrendForecastPeriod = topicData.detailData && getHighestChance(topicData.detailData.topic.period)

  const trendForecastPeriodInWeeks = (topicData.detailData?.topic?.period && (
    highestTrendForecastPeriod === '2' ? 
      '2 weeks':(highestTrendForecastPeriod=== '1'? (
        '1 week'
        ) : (
          highestTrendForecastPeriod === '0' ? (
            '< 1 week'
            ): (
              'N/A'
            )
        )
      )
  )) || 'N/A'

  const periodText = topicData.detailData?.topic?.period && 
    trendForecastPeriodInWeeks !== 'N/A' ? (
      `${Math.round((topicData.detailData?.topic.period[highestTrendForecastPeriod as keyof TrendPeriod])*100)}% chance this will last`
      ):(
    'Trend Forecast Not Available'
  )


  return (
    <SummaryCardsRowContainer>
      {/* <SummaryCardContainer>
      {!topicData.detailData ? (
        <Skeleton height="100px"/>
      ):(
        <SummaryCard 
          icon={<ArrowUp style={{transform: higherOrLower === 'lower' && 'scaleY(-1)'}} />}
          title={`${comparedTotal.toLocaleString()}%`}
          subtitle={`${higherOrLower} than previous period`}
          appearance="dark"
          titleTextMarginLeft="1rem"
          pWidth="100%"
        />        
      )
      }
      </SummaryCardContainer>

      <SummaryCardContainer>
        {!topicData.detailData ? (
          <Skeleton height="100px"/>
        ):(
          <SummaryCard 
            icon={<ArrowUpWithCalendar />}
            title={trendForecastPeriodInWeeks}
            subtitle={periodText}
            appearance="dark"
            titleTextMarginLeft="1rem"
            pWidth="100%"
          />
        )}
      </SummaryCardContainer> */}

      <SummaryCardContainer>
        {!topicData.detailData ? (
          <Skeleton />
        ):(
          <SummaryCard 
            icon={<AuthorWithPen />}
            title={`${topicData.detailData?.results[frequency]?.aggregations?.top_stats.distinct_author}` || '0'}
            subtitle="Unique authors"
            appearance="dark"
            titleTextMarginLeft="1rem"
          />
        )}
      </SummaryCardContainer>
      
      <SummaryCardContainer>
        {!topicData.detailData ? (
          <Skeleton />
        ):(
          <SummaryCard 
            icon={<ShareIcon />}
            title={`${topicData.detailData?.results[frequency]?.aggregations?.top_stats.distinct_channel}` || '0'}
            subtitle="Total channel spread"
            appearance="dark"
            titleTextMarginLeft="1rem"
          />
        )}
      </SummaryCardContainer>

      <SummaryCardContainer>
        {!topicData.detailData ? (
          <Skeleton />
        ):(
          <SummaryCard 
            icon={<ThumbsUp />}
            title={topicData.detailData?.results[frequency]?.aggregations?.top_stats.sum_engagement.toLocaleString() || '0'}
            subtitle="Total engagement"
            appearance="dark"
            titleTextMarginLeft="1rem"
          />
        )}
      </SummaryCardContainer>
    </SummaryCardsRowContainer>
  )
}

export default SummaryCardsRow

interface SummaryCardsRowProps{
  topicData: TopicData
  frequency: Frequency
}