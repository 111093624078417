import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { RouteComponentProps } from "react-router-dom"
import PreviousResultTimeframeSelector from './PreviousResultTimeframeSelector'
import { timeFrameSelections } from '../components/menuSelections'
// import { FeedData } from "../../../app/interfaces/feed"
import { usePostsStore } from '../postStore'
import { fetchTrendOvData } from '../queryFn'
// import AIDashboard from "./aiDashboard"
import TopicSummary from "./topicSummary"
import PostCountChart from "./postCountChart"
import SentimentChart from "./sentimentChart"
import StatCards from "./statCards"
import { RowContainer, LayoutGrid, OverviewLayoutContainer } from "./styled"
import TopAuthors from './topAuthors'
import TopicPicker from "./topicPicker"
import TopChannels from './topChannels'
import TopThreads from './topThreads'
// import RelatedCategory from './relatedCategory'
import RelatedTopics from './relatedTopics'
import { Frequency, TrendAccess } from '../interface'
import AngleLeft from '../../reusable/icons/AngleLeft'
import CurrentResultTimeframeSelectRow from './currentResultTimeframeSelectRow'
import ReactTooltip from 'react-tooltip'
import { parseQueryString } from '../helperFn/querystring'
import { useHistory } from 'react-router'
import { destructuredQueryFn } from '../../../app/query/client'
// import { allTopicData } from '../home/Cards'
import axios from 'axios'

interface TrendOverviewProp extends RouteComponentProps<{ topic: string }> { }


export const overviewData = {
  "topic": {
      "word": "Jaedon",
      "related_word": [
        "Jiwoo",
        "Lee Jaesuk",
        "Connect",
        "범규 BEOMGYU"
    ],
      "doc_freq": 0.0060893097,
      "ai_score": 6.188575,
      "period": {
          "0": 0.36082092,
          "1": 0.54842716,
          "2": 0.8434147,
          "-1": 0.5160587
      },
      "maturity": "dormant",
      "rank": 3
  },
  "sub_topics": [],
  "aggregations": {
    "top_stats": {
      "distinct_author": 1,
      "sum_engagement": 130738,
      "distinct_channel": 1,
      "total_threads": 6,
      "total": 6
  },
      "p_stats": {
          "avg_author": 16.065933,
          "avg_channel": 17.912088,
          "avg_count": 33.934067,
          "avg_eng": 1884.3077,
          "avg_thread": 31.32967
      },
      "medium_count_line": [
        {
          "medium": "YouTube",
          "total": 0,
          "points": [{'unix_timestamp': 1715763595000, 'comment_count': 567, 'thread_count': 0}, {'unix_timestamp': 1715763605000, 'comment_count': 606, 'thread_count': 0}, {'unix_timestamp': 1715763601000, 'comment_count': 375, 'thread_count': 0}, {'unix_timestamp': 1715763608000, 'comment_count': 367, 'thread_count': 0}, {'unix_timestamp': 1715763617000, 'comment_count': 385, 'thread_count': 0}, {'unix_timestamp': 1715763612000, 'comment_count': 310, 'thread_count': 0}]
      },
          // {
          //     "medium": "Facebook",
          //     "total": 0,
          //     "points": [
          //         {
          //             "unix_timestamp": 1661911200000,
          //             "comment_count": 0,
          //             "thread_count": 1
          //         },
          //         {
          //             "unix_timestamp": 1661914800000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661918400000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661922000000,
          //             "comment_count": 0,
          //             "thread_count": 1
          //         },
          //         {
          //             "unix_timestamp": 1661925600000,
          //             "comment_count": 0,
          //             "thread_count": 1
          //         },
          //         {
          //             "unix_timestamp": 1661929200000,
          //             "comment_count": 0,
          //             "thread_count": 1
          //         },
          //         {
          //             "unix_timestamp": 1661932800000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661936400000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661940000000,
          //             "comment_count": 0,
          //             "thread_count": 1
          //         },
          //         {
          //             "unix_timestamp": 1661943600000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661947200000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661950800000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661954400000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661958000000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661961600000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661965200000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661968800000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661972400000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661976000000,
          //             "comment_count": 0,
          //             "thread_count": 1
          //         },
          //         {
          //             "unix_timestamp": 1661979600000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661983200000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661986800000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661990400000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661994000000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661997600000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         }
          //     ]
          // },
          // {
          //     "medium": "Forum",
          //     "total": 0,
          //     "points": [
          //         {
          //             "unix_timestamp": 1661911200000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661914800000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661918400000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661922000000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661925600000,
          //             "comment_count": 0,
          //             "thread_count": 2
          //         },
          //         {
          //             "unix_timestamp": 1661929200000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661932800000,
          //             "comment_count": 0,
          //             "thread_count": 1
          //         },
          //         {
          //             "unix_timestamp": 1661936400000,
          //             "comment_count": 0,
          //             "thread_count": 1
          //         },
          //         {
          //             "unix_timestamp": 1661940000000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661943600000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661947200000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661950800000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661954400000,
          //             "comment_count": 0,
          //             "thread_count": 1
          //         },
          //         {
          //             "unix_timestamp": 1661958000000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661961600000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661965200000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661968800000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661972400000,
          //             "comment_count": 0,
          //             "thread_count": 1
          //         },
          //         {
          //             "unix_timestamp": 1661976000000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661979600000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661983200000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661986800000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661990400000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661994000000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         },
          //         {
          //             "unix_timestamp": 1661997600000,
          //             "comment_count": 0,
          //             "thread_count": 0
          //         }
          //     ]
          // }
      ],
      "top_channels": {
          "threads": [
              {
                  "channel_name": "新聞",
                  "channel_link": "https://today.line.me/hk/v2/tab/news",
                  "site": "LineToday",
                  "count": 5
              },
              {
                  "channel_name": "時事台",
                  "channel_link": "https://lihkg.com/category/5",
                  "site": "LIHKG",
                  "count": 4
              },
              {
                  "channel_name": "即時-港聞",
                  "channel_link": "https://hd.stheadline.com/news/realtime/hk/%E5%8D%B3%E6%99%82-%E6%B8%AF%E8%81%9E/",
                  "site": "Headline",
                  "count": 3
              },
              {
                  "channel_name": "港澳",
                  "channel_link": "https://hk.on.cc/hk/news/index.html",
                  "site": "Oncc",
                  "count": 3
              },
              {
                  "channel_name": "新聞",
                  "channel_link": "https://topick.hket.com/srat006/",
                  "site": "TOPick",
                  "count": 3
              },
              {
                  "channel_name": "即時-港聞",
                  "channel_link": "https://std.stheadline.com/realtime/hongkong/%E5%8D%B3%E6%99%82-%E6%B8%AF%E8%81%9E",
                  "site": "SingTao",
                  "count": 2
              },
              {
                  "channel_name": "時聞香港",
                  "channel_link": "https://www.facebook.com/512700315470576",
                  "site": "Facebook Page",
                  "count": 2
              },
              {
                  "channel_name": "地產討論",
                  "channel_link": "https://discuss.com.hk/forumdisplay.php?fid=110",
                  "site": "HKDiscuss",
                  "count": 1
              },
              {
                  "channel_name": "時事台",
                  "channel_link": "https://forum.hkgolden.com/channel/CA",
                  "site": "Golden",
                  "count": 1
              },
              {
                  "channel_name": "政事",
                  "channel_link": "https://www.bastillepost.com/hongkong/category/1-%E6%94%BF%E4%BA%8B?variant=zh-hk",
                  "site": "Bastille",
                  "count": 1
              },
              {
                  "channel_name": "社會事",
                  "channel_link": "https://www.bastillepost.com/hongkong/category/3-%E7%A4%BE%E6%9C%83%E4%BA%8B?variant=zh-hk",
                  "site": "Bastille",
                  "count": 1
              },
              {
                  "channel_name": "BossMind",
                  "channel_link": "https://www.facebook.com/102378628805204",
                  "site": "Facebook Page",
                  "count": 1
              },
              {
                  "channel_name": "香港同心總會",
                  "channel_link": "https://www.facebook.com/1087464571604372",
                  "site": "Facebook Group",
                  "count": 1
              },
              {
                  "channel_name": "黃屍毒果打手",
                  "channel_link": "https://www.facebook.com/1099775456712485",
                  "site": "Facebook Group",
                  "count": 1
              },
              {
                  "channel_name": "拾玖傳媒 NineTeen Media",
                  "channel_link": "https://www.facebook.com/111059377201732",
                  "site": "Facebook Page",
                  "count": 1
              },
              {
                  "channel_name": "社會新聞",
                  "channel_link": "https://www.hk01.com/channel/2",
                  "site": "HK01",
                  "count": 1
              }
          ],
          "comments": [],
          "total": [
              {
                  "channel_name": "新聞",
                  "channel_link": "https://today.line.me/hk/v2/tab/news",
                  "site": "LineToday",
                  "count": 5
              },
              {
                  "channel_name": "時事台",
                  "channel_link": "https://lihkg.com/category/5",
                  "site": "LIHKG",
                  "count": 4
              },
              {
                  "channel_name": "即時-港聞",
                  "channel_link": "https://hd.stheadline.com/news/realtime/hk/%E5%8D%B3%E6%99%82-%E6%B8%AF%E8%81%9E/",
                  "site": "Headline",
                  "count": 3
              },
              {
                  "channel_name": "港澳",
                  "channel_link": "https://hk.on.cc/hk/news/index.html",
                  "site": "Oncc",
                  "count": 3
              },
              {
                  "channel_name": "新聞",
                  "channel_link": "https://topick.hket.com/srat006/",
                  "site": "TOPick",
                  "count": 3
              },
              {
                  "channel_name": "即時-港聞",
                  "channel_link": "https://std.stheadline.com/realtime/hongkong/%E5%8D%B3%E6%99%82-%E6%B8%AF%E8%81%9E",
                  "site": "SingTao",
                  "count": 2
              },
              {
                  "channel_name": "時聞香港",
                  "channel_link": "https://www.facebook.com/512700315470576",
                  "site": "Facebook Page",
                  "count": 2
              },
              {
                  "channel_name": "地產討論",
                  "channel_link": "https://discuss.com.hk/forumdisplay.php?fid=110",
                  "site": "HKDiscuss",
                  "count": 1
              },
              {
                  "channel_name": "時事台",
                  "channel_link": "https://forum.hkgolden.com/channel/CA",
                  "site": "Golden",
                  "count": 1
              },
              {
                  "channel_name": "政事",
                  "channel_link": "https://www.bastillepost.com/hongkong/category/1-%E6%94%BF%E4%BA%8B?variant=zh-hk",
                  "site": "Bastille",
                  "count": 1
              },
              {
                  "channel_name": "社會事",
                  "channel_link": "https://www.bastillepost.com/hongkong/category/3-%E7%A4%BE%E6%9C%83%E4%BA%8B?variant=zh-hk",
                  "site": "Bastille",
                  "count": 1
              },
              {
                  "channel_name": "BossMind",
                  "channel_link": "https://www.facebook.com/102378628805204",
                  "site": "Facebook Page",
                  "count": 1
              },
              {
                  "channel_name": "香港同心總會",
                  "channel_link": "https://www.facebook.com/1087464571604372",
                  "site": "Facebook Group",
                  "count": 1
              },
              {
                  "channel_name": "黃屍毒果打手",
                  "channel_link": "https://www.facebook.com/1099775456712485",
                  "site": "Facebook Group",
                  "count": 1
              },
              {
                  "channel_name": "拾玖傳媒 NineTeen Media",
                  "channel_link": "https://www.facebook.com/111059377201732",
                  "site": "Facebook Page",
                  "count": 1
              },
              {
                  "channel_name": "社會新聞",
                  "channel_link": "https://www.hk01.com/channel/2",
                  "site": "HK01",
                  "count": 1
              }
          ],
          "engagement": [
              {
                  "channel_name": "BossMind",
                  "channel_link": "https://www.facebook.com/102378628805204",
                  "site": "Facebook Page",
                  "count": 1,
                  "engagement_score": 504
              },
              {
                  "channel_name": "時事台",
                  "channel_link": "https://lihkg.com/category/5",
                  "site": "LIHKG",
                  "count": 4,
                  "engagement_score": 232
              },
              {
                  "channel_name": "即時-港聞",
                  "channel_link": "https://hd.stheadline.com/news/realtime/hk/%E5%8D%B3%E6%99%82-%E6%B8%AF%E8%81%9E/",
                  "site": "Headline",
                  "count": 3,
                  "engagement_score": 18
              },
              {
                  "channel_name": "時聞香港",
                  "channel_link": "https://www.facebook.com/512700315470576",
                  "site": "Facebook Page",
                  "count": 2,
                  "engagement_score": 16
              },
              {
                  "channel_name": "時事台",
                  "channel_link": "https://forum.hkgolden.com/channel/CA",
                  "site": "Golden",
                  "count": 1,
                  "engagement_score": 12
              },
              {
                  "channel_name": "香港同心總會",
                  "channel_link": "https://www.facebook.com/1087464571604372",
                  "site": "Facebook Group",
                  "count": 1,
                  "engagement_score": 7
              },
              {
                  "channel_name": "拾玖傳媒 NineTeen Media",
                  "channel_link": "https://www.facebook.com/111059377201732",
                  "site": "Facebook Page",
                  "count": 1,
                  "engagement_score": 2
              },
              {
                  "channel_name": "港澳",
                  "channel_link": "https://hk.on.cc/hk/news/index.html",
                  "site": "Oncc",
                  "count": 3,
                  "engagement_score": 1
              },
              {
                  "channel_name": "新聞",
                  "channel_link": "https://today.line.me/hk/v2/tab/news",
                  "site": "LineToday",
                  "count": 5,
                  "engagement_score": 1
              },
              {
                  "channel_name": "地產討論",
                  "channel_link": "https://discuss.com.hk/forumdisplay.php?fid=110",
                  "site": "HKDiscuss",
                  "count": 1,
                  "engagement_score": 0
              },
              {
                  "channel_name": "即時-港聞",
                  "channel_link": "https://std.stheadline.com/realtime/hongkong/%E5%8D%B3%E6%99%82-%E6%B8%AF%E8%81%9E",
                  "site": "SingTao",
                  "count": 2,
                  "engagement_score": 0
              },
              {
                  "channel_name": "新聞",
                  "channel_link": "https://topick.hket.com/srat006/",
                  "site": "TOPick",
                  "count": 3,
                  "engagement_score": 0
              },
              {
                  "channel_name": "政事",
                  "channel_link": "https://www.bastillepost.com/hongkong/category/1-%E6%94%BF%E4%BA%8B?variant=zh-hk",
                  "site": "Bastille",
                  "count": 1,
                  "engagement_score": 0
              },
              {
                  "channel_name": "社會事",
                  "channel_link": "https://www.bastillepost.com/hongkong/category/3-%E7%A4%BE%E6%9C%83%E4%BA%8B?variant=zh-hk",
                  "site": "Bastille",
                  "count": 1,
                  "engagement_score": 0
              },
              {
                  "channel_name": "黃屍毒果打手",
                  "channel_link": "https://www.facebook.com/1099775456712485",
                  "site": "Facebook Group",
                  "count": 1,
                  "engagement_score": 0
              },
              {
                  "channel_name": "社會新聞",
                  "channel_link": "https://www.hk01.com/channel/2",
                  "site": "HK01",
                  "count": 1,
                  "engagement_score": 0
              }
          ]
      },
      "top_authors": {
          "threads": [
              {
                  "author_id": "headline_headline",
                  "author_name": "headline",
                  "site": "Headline",
                  "count": 3
              },
              {
                  "author_id": "oncc_oncc",
                  "author_name": "oncc",
                  "site": "Oncc",
                  "count": 3
              },
              {
                  "author_id": "topick_topick",
                  "author_name": "topick",
                  "site": "TOPick",
                  "count": 3
              },
              {
                  "author_id": "bastille_bastille",
                  "author_name": "bastille",
                  "site": "Bastille",
                  "count": 2
              },
              {
                  "author_id": "fb_512700315470576",
                  "author_name": "時聞香港",
                  "author_image": "https://graph.facebook.com/512700315470576/picture?type=square",
                  "site": "Facebook Page",
                  "count": 2
              },
              {
                  "author_id": "fb_Citysearchhk",
                  "author_name": "城市大搜查 Citysearch",
                  "author_image": "https://scontent.fhkg1-1.fna.fbcdn.net/v/t1.6435-1/167838983_525814798816119_8688753341788979222_n.jpg?stp=cp0_dst-jpg_p40x40&_nc_cat=111&ccb=1-7&_nc_sid=7206a8&_nc_ohc=S7qeATU7qA0AX9AnuKT&_nc_ht=scontent.fhkg1-1.fna&oh=00_AT_-wyMHMGeKqZ5fgGbE8OdhIvk27XHY-0_r-ePXzwhAAw&oe=6336C7BF",
                  "site": "Facebook Group",
                  "count": 2
              },
              {
                  "author_id": "lihkg_240362",
                  "author_name": "極速神驅",
                  "site": "LIHKG",
                  "count": 2
              },
              {
                  "author_id": "linetoday_56c1b707",
                  "author_name": "on.cc 東網",
                  "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                  "site": "LineToday",
                  "count": 2
              },
              {
                  "author_id": "singtao_singtao",
                  "author_name": "singtao",
                  "site": "SingTao",
                  "count": 2
              },
              {
                  "author_id": "fb_102378628805204",
                  "author_name": "BossMind",
                  "author_image": "https://graph.facebook.com/102378628805204/picture?type=square",
                  "site": "Facebook Page",
                  "count": 1
              },
              {
                  "author_id": "fb_111059377201732",
                  "author_name": "拾玖傳媒 NineTeen Media",
                  "author_image": "https://graph.facebook.com/111059377201732/picture?type=square",
                  "site": "Facebook Page",
                  "count": 1
              },
              {
                  "author_id": "golden_188529",
                  "author_name": "王憐花",
                  "site": "Golden",
                  "count": 1
              },
              {
                  "author_id": "hk01_6c9e2e97",
                  "author_name": "郭顥添",
                  "site": "HK01",
                  "count": 1
              },
              {
                  "author_id": "hkdiscuss_3022564",
                  "author_name": "carters",
                  "author_image": "https://finance.discuss.com.hk/images/avatars/noavatar2.png",
                  "site": "HKDiscuss",
                  "count": 1
              },
              {
                  "author_id": "lihkg_107417",
                  "author_name": "打柒陳民亮的袋熊",
                  "site": "LIHKG",
                  "count": 1
              },
              {
                  "author_id": "lihkg_184679",
                  "author_name": "孔雀大師",
                  "site": "LIHKG",
                  "count": 1
              },
              {
                  "author_id": "linetoday_3fdf6465",
                  "author_name": "獨立媒體 inmediahk.net",
                  "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                  "site": "LineToday",
                  "count": 1
              },
              {
                  "author_id": "linetoday_df7950a2",
                  "author_name": "BossMind",
                  "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                  "site": "LineToday",
                  "count": 1
              },
              {
                  "author_id": "linetoday_eaa09024",
                  "author_name": "星島日報",
                  "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                  "site": "LineToday",
                  "count": 1
              }
          ],
          "comments": [],
          "total": [
              {
                  "author_id": "headline_headline",
                  "author_name": "headline",
                  "site": "Headline",
                  "count": 3
              },
              {
                  "author_id": "oncc_oncc",
                  "author_name": "oncc",
                  "site": "Oncc",
                  "count": 3
              },
              {
                  "author_id": "topick_topick",
                  "author_name": "topick",
                  "site": "TOPick",
                  "count": 3
              },
              {
                  "author_id": "bastille_bastille",
                  "author_name": "bastille",
                  "site": "Bastille",
                  "count": 2
              },
              {
                  "author_id": "fb_512700315470576",
                  "author_name": "時聞香港",
                  "author_image": "https://graph.facebook.com/512700315470576/picture?type=square",
                  "site": "Facebook Page",
                  "count": 2
              },
              {
                  "author_id": "fb_Citysearchhk",
                  "author_name": "城市大搜查 Citysearch",
                  "author_image": "https://scontent.fhkg1-1.fna.fbcdn.net/v/t1.6435-1/167838983_525814798816119_8688753341788979222_n.jpg?stp=cp0_dst-jpg_p40x40&_nc_cat=111&ccb=1-7&_nc_sid=7206a8&_nc_ohc=S7qeATU7qA0AX9AnuKT&_nc_ht=scontent.fhkg1-1.fna&oh=00_AT_-wyMHMGeKqZ5fgGbE8OdhIvk27XHY-0_r-ePXzwhAAw&oe=6336C7BF",
                  "site": "Facebook Group",
                  "count": 2
              },
              {
                  "author_id": "lihkg_240362",
                  "author_name": "極速神驅",
                  "site": "LIHKG",
                  "count": 2
              },
              {
                  "author_id": "linetoday_56c1b707",
                  "author_name": "on.cc 東網",
                  "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                  "site": "LineToday",
                  "count": 2
              },
              {
                  "author_id": "singtao_singtao",
                  "author_name": "singtao",
                  "site": "SingTao",
                  "count": 2
              },
              {
                  "author_id": "fb_102378628805204",
                  "author_name": "BossMind",
                  "author_image": "https://graph.facebook.com/102378628805204/picture?type=square",
                  "site": "Facebook Page",
                  "count": 1
              },
              {
                  "author_id": "fb_111059377201732",
                  "author_name": "拾玖傳媒 NineTeen Media",
                  "author_image": "https://graph.facebook.com/111059377201732/picture?type=square",
                  "site": "Facebook Page",
                  "count": 1
              },
              {
                  "author_id": "golden_188529",
                  "author_name": "王憐花",
                  "site": "Golden",
                  "count": 1
              },
              {
                  "author_id": "hk01_6c9e2e97",
                  "author_name": "郭顥添",
                  "site": "HK01",
                  "count": 1
              },
              {
                  "author_id": "hkdiscuss_3022564",
                  "author_name": "carters",
                  "author_image": "https://finance.discuss.com.hk/images/avatars/noavatar2.png",
                  "site": "HKDiscuss",
                  "count": 1
              },
              {
                  "author_id": "lihkg_107417",
                  "author_name": "打柒陳民亮的袋熊",
                  "site": "LIHKG",
                  "count": 1
              },
              {
                  "author_id": "lihkg_184679",
                  "author_name": "孔雀大師",
                  "site": "LIHKG",
                  "count": 1
              },
              {
                  "author_id": "linetoday_3fdf6465",
                  "author_name": "獨立媒體 inmediahk.net",
                  "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                  "site": "LineToday",
                  "count": 1
              },
              {
                  "author_id": "linetoday_df7950a2",
                  "author_name": "BossMind",
                  "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                  "site": "LineToday",
                  "count": 1
              },
              {
                  "author_id": "linetoday_eaa09024",
                  "author_name": "星島日報",
                  "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                  "site": "LineToday",
                  "count": 1
              }
          ],
          "medium_author": {
              "Facebook": {
                  "medium": "Facebook",
                  "threads": [
                      {
                          "author_id": "fb_512700315470576",
                          "author_name": "時聞香港",
                          "author_image": "https://graph.facebook.com/512700315470576/picture?type=square",
                          "site": "Facebook Page",
                          "count": 2
                      },
                      {
                          "author_id": "fb_Citysearchhk",
                          "author_name": "城市大搜查 Citysearch",
                          "author_image": "https://scontent.fhkg1-1.fna.fbcdn.net/v/t1.6435-1/167838983_525814798816119_8688753341788979222_n.jpg?stp=cp0_dst-jpg_p40x40&_nc_cat=111&ccb=1-7&_nc_sid=7206a8&_nc_ohc=S7qeATU7qA0AX9AnuKT&_nc_ht=scontent.fhkg1-1.fna&oh=00_AT_-wyMHMGeKqZ5fgGbE8OdhIvk27XHY-0_r-ePXzwhAAw&oe=6336C7BF",
                          "site": "Facebook Group",
                          "count": 2
                      },
                      {
                          "author_id": "fb_102378628805204",
                          "author_name": "BossMind",
                          "author_image": "https://graph.facebook.com/102378628805204/picture?type=square",
                          "site": "Facebook Page",
                          "count": 1
                      },
                      {
                          "author_id": "fb_111059377201732",
                          "author_name": "拾玖傳媒 NineTeen Media",
                          "author_image": "https://graph.facebook.com/111059377201732/picture?type=square",
                          "site": "Facebook Page",
                          "count": 1
                      }
                  ],
                  "comments": [],
                  "total": [
                      {
                          "author_id": "fb_512700315470576",
                          "author_name": "時聞香港",
                          "author_image": "https://graph.facebook.com/512700315470576/picture?type=square",
                          "site": "Facebook Page",
                          "count": 2
                      },
                      {
                          "author_id": "fb_Citysearchhk",
                          "author_name": "城市大搜查 Citysearch",
                          "author_image": "https://scontent.fhkg1-1.fna.fbcdn.net/v/t1.6435-1/167838983_525814798816119_8688753341788979222_n.jpg?stp=cp0_dst-jpg_p40x40&_nc_cat=111&ccb=1-7&_nc_sid=7206a8&_nc_ohc=S7qeATU7qA0AX9AnuKT&_nc_ht=scontent.fhkg1-1.fna&oh=00_AT_-wyMHMGeKqZ5fgGbE8OdhIvk27XHY-0_r-ePXzwhAAw&oe=6336C7BF",
                          "site": "Facebook Group",
                          "count": 2
                      },
                      {
                          "author_id": "fb_102378628805204",
                          "author_name": "BossMind",
                          "author_image": "https://graph.facebook.com/102378628805204/picture?type=square",
                          "site": "Facebook Page",
                          "count": 1
                      },
                      {
                          "author_id": "fb_111059377201732",
                          "author_name": "拾玖傳媒 NineTeen Media",
                          "author_image": "https://graph.facebook.com/111059377201732/picture?type=square",
                          "site": "Facebook Page",
                          "count": 1
                      }
                  ],
                  "engagement": [
                      {
                          "author_id": "fb_102378628805204",
                          "author_name": "BossMind",
                          "author_image": "https://graph.facebook.com/102378628805204/picture?type=square",
                          "site": "Facebook Page",
                          "count": 1,
                          "engagement": 0
                      },
                      {
                          "author_id": "fb_512700315470576",
                          "author_name": "時聞香港",
                          "author_image": "https://graph.facebook.com/512700315470576/picture?type=square",
                          "site": "Facebook Page",
                          "count": 2,
                          "engagement": 0
                      },
                      {
                          "author_id": "fb_Citysearchhk",
                          "author_name": "城市大搜查 Citysearch",
                          "author_image": "https://scontent.fhkg1-1.fna.fbcdn.net/v/t1.6435-1/167838983_525814798816119_8688753341788979222_n.jpg?stp=cp0_dst-jpg_p40x40&_nc_cat=111&ccb=1-7&_nc_sid=7206a8&_nc_ohc=S7qeATU7qA0AX9AnuKT&_nc_ht=scontent.fhkg1-1.fna&oh=00_AT_-wyMHMGeKqZ5fgGbE8OdhIvk27XHY-0_r-ePXzwhAAw&oe=6336C7BF",
                          "site": "Facebook Group",
                          "count": 2,
                          "engagement": 0
                      },
                      {
                          "author_id": "fb_111059377201732",
                          "author_name": "拾玖傳媒 NineTeen Media",
                          "author_image": "https://graph.facebook.com/111059377201732/picture?type=square",
                          "site": "Facebook Page",
                          "count": 1,
                          "engagement": 0
                      }
                  ],
                  "total_count": 6
              },
              "Forum": {
                  "medium": "Forum",
                  "threads": [
                      {
                          "author_id": "lihkg_240362",
                          "author_name": "極速神驅",
                          "site": "LIHKG",
                          "count": 2
                      },
                      {
                          "author_id": "golden_188529",
                          "author_name": "王憐花",
                          "site": "Golden",
                          "count": 1
                      },
                      {
                          "author_id": "hkdiscuss_3022564",
                          "author_name": "carters",
                          "author_image": "https://finance.discuss.com.hk/images/avatars/noavatar2.png",
                          "site": "HKDiscuss",
                          "count": 1
                      },
                      {
                          "author_id": "lihkg_107417",
                          "author_name": "打柒陳民亮的袋熊",
                          "site": "LIHKG",
                          "count": 1
                      },
                      {
                          "author_id": "lihkg_184679",
                          "author_name": "孔雀大師",
                          "site": "LIHKG",
                          "count": 1
                      }
                  ],
                  "comments": [],
                  "total": [
                      {
                          "author_id": "lihkg_240362",
                          "author_name": "極速神驅",
                          "site": "LIHKG",
                          "count": 2
                      },
                      {
                          "author_id": "golden_188529",
                          "author_name": "王憐花",
                          "site": "Golden",
                          "count": 1
                      },
                      {
                          "author_id": "hkdiscuss_3022564",
                          "author_name": "carters",
                          "author_image": "https://finance.discuss.com.hk/images/avatars/noavatar2.png",
                          "site": "HKDiscuss",
                          "count": 1
                      },
                      {
                          "author_id": "lihkg_107417",
                          "author_name": "打柒陳民亮的袋熊",
                          "site": "LIHKG",
                          "count": 1
                      },
                      {
                          "author_id": "lihkg_184679",
                          "author_name": "孔雀大師",
                          "site": "LIHKG",
                          "count": 1
                      }
                  ],
                  "engagement": [
                      {
                          "author_id": "lihkg_240362",
                          "author_name": "極速神驅",
                          "site": "LIHKG",
                          "count": 2,
                          "engagement": 0
                      },
                      {
                          "author_id": "lihkg_184679",
                          "author_name": "孔雀大師",
                          "site": "LIHKG",
                          "count": 1,
                          "engagement": 0
                      },
                      {
                          "author_id": "golden_188529",
                          "author_name": "王憐花",
                          "site": "Golden",
                          "count": 1,
                          "engagement": 0
                      },
                      {
                          "author_id": "lihkg_107417",
                          "author_name": "打柒陳民亮的袋熊",
                          "site": "LIHKG",
                          "count": 1,
                          "engagement": 0
                      },
                      {
                          "author_id": "hkdiscuss_3022564",
                          "author_name": "carters",
                          "author_image": "https://finance.discuss.com.hk/images/avatars/noavatar2.png",
                          "site": "HKDiscuss",
                          "count": 1,
                          "engagement": 0
                      }
                  ],
                  "total_count": 6
              },
              "News": {
                  "medium": "News",
                  "threads": [
                      {
                          "author_id": "headline_headline",
                          "author_name": "headline",
                          "site": "Headline",
                          "count": 3
                      },
                      {
                          "author_id": "oncc_oncc",
                          "author_name": "oncc",
                          "site": "Oncc",
                          "count": 3
                      },
                      {
                          "author_id": "topick_topick",
                          "author_name": "topick",
                          "site": "TOPick",
                          "count": 3
                      },
                      {
                          "author_id": "bastille_bastille",
                          "author_name": "bastille",
                          "site": "Bastille",
                          "count": 2
                      },
                      {
                          "author_id": "linetoday_56c1b707",
                          "author_name": "on.cc 東網",
                          "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                          "site": "LineToday",
                          "count": 2
                      },
                      {
                          "author_id": "singtao_singtao",
                          "author_name": "singtao",
                          "site": "SingTao",
                          "count": 2
                      },
                      {
                          "author_id": "hk01_6c9e2e97",
                          "author_name": "郭顥添",
                          "site": "HK01",
                          "count": 1
                      },
                      {
                          "author_id": "linetoday_3fdf6465",
                          "author_name": "獨立媒體 inmediahk.net",
                          "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                          "site": "LineToday",
                          "count": 1
                      },
                      {
                          "author_id": "linetoday_df7950a2",
                          "author_name": "BossMind",
                          "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                          "site": "LineToday",
                          "count": 1
                      },
                      {
                          "author_id": "linetoday_eaa09024",
                          "author_name": "星島日報",
                          "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                          "site": "LineToday",
                          "count": 1
                      }
                  ],
                  "comments": [],
                  "total": [
                      {
                          "author_id": "headline_headline",
                          "author_name": "headline",
                          "site": "Headline",
                          "count": 3
                      },
                      {
                          "author_id": "oncc_oncc",
                          "author_name": "oncc",
                          "site": "Oncc",
                          "count": 3
                      },
                      {
                          "author_id": "topick_topick",
                          "author_name": "topick",
                          "site": "TOPick",
                          "count": 3
                      },
                      {
                          "author_id": "bastille_bastille",
                          "author_name": "bastille",
                          "site": "Bastille",
                          "count": 2
                      },
                      {
                          "author_id": "linetoday_56c1b707",
                          "author_name": "on.cc 東網",
                          "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                          "site": "LineToday",
                          "count": 2
                      },
                      {
                          "author_id": "singtao_singtao",
                          "author_name": "singtao",
                          "site": "SingTao",
                          "count": 2
                      },
                      {
                          "author_id": "hk01_6c9e2e97",
                          "author_name": "郭顥添",
                          "site": "HK01",
                          "count": 1
                      },
                      {
                          "author_id": "linetoday_3fdf6465",
                          "author_name": "獨立媒體 inmediahk.net",
                          "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                          "site": "LineToday",
                          "count": 1
                      },
                      {
                          "author_id": "linetoday_df7950a2",
                          "author_name": "BossMind",
                          "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                          "site": "LineToday",
                          "count": 1
                      },
                      {
                          "author_id": "linetoday_eaa09024",
                          "author_name": "星島日報",
                          "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                          "site": "LineToday",
                          "count": 1
                      }
                  ],
                  "engagement": [
                      {
                          "author_id": "headline_headline",
                          "author_name": "headline",
                          "site": "Headline",
                          "count": 3,
                          "engagement": 0
                      },
                      {
                          "author_id": "linetoday_df7950a2",
                          "author_name": "BossMind",
                          "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                          "site": "LineToday",
                          "count": 1,
                          "engagement": 0
                      },
                      {
                          "author_id": "oncc_oncc",
                          "author_name": "oncc",
                          "site": "Oncc",
                          "count": 3,
                          "engagement": 0
                      },
                      {
                          "author_id": "bastille_bastille",
                          "author_name": "bastille",
                          "site": "Bastille",
                          "count": 2,
                          "engagement": 0
                      },
                      {
                          "author_id": "hk01_6c9e2e97",
                          "author_name": "郭顥添",
                          "site": "HK01",
                          "count": 1,
                          "engagement": 0
                      },
                      {
                          "author_id": "linetoday_3fdf6465",
                          "author_name": "獨立媒體 inmediahk.net",
                          "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                          "site": "LineToday",
                          "count": 1,
                          "engagement": 0
                      },
                      {
                          "author_id": "linetoday_56c1b707",
                          "author_name": "on.cc 東網",
                          "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                          "site": "LineToday",
                          "count": 2,
                          "engagement": 0
                      },
                      {
                          "author_id": "linetoday_eaa09024",
                          "author_name": "星島日報",
                          "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                          "site": "LineToday",
                          "count": 1,
                          "engagement": 0
                      },
                      {
                          "author_id": "singtao_singtao",
                          "author_name": "singtao",
                          "site": "SingTao",
                          "count": 2,
                          "engagement": 0
                      },
                      {
                          "author_id": "topick_topick",
                          "author_name": "topick",
                          "site": "TOPick",
                          "count": 3,
                          "engagement": 0
                      }
                  ],
                  "total_count": 19
              }
          },
          "engagement": [
              {
                  "author_id": "fb_102378628805204",
                  "author_name": "BossMind",
                  "author_image": "https://graph.facebook.com/102378628805204/picture?type=square",
                  "site": "Facebook Page",
                  "count": 1,
                  "engagement": 0
              },
              {
                  "author_id": "lihkg_240362",
                  "author_name": "極速神驅",
                  "site": "LIHKG",
                  "count": 2,
                  "engagement": 0
              },
              {
                  "author_id": "lihkg_184679",
                  "author_name": "孔雀大師",
                  "site": "LIHKG",
                  "count": 1,
                  "engagement": 0
              },
              {
                  "author_id": "headline_headline",
                  "author_name": "headline",
                  "site": "Headline",
                  "count": 3,
                  "engagement": 0
              },
              {
                  "author_id": "fb_512700315470576",
                  "author_name": "時聞香港",
                  "author_image": "https://graph.facebook.com/512700315470576/picture?type=square",
                  "site": "Facebook Page",
                  "count": 2,
                  "engagement": 0
              },
              {
                  "author_id": "golden_188529",
                  "author_name": "王憐花",
                  "site": "Golden",
                  "count": 1,
                  "engagement": 0
              },
              {
                  "author_id": "lihkg_107417",
                  "author_name": "打柒陳民亮的袋熊",
                  "site": "LIHKG",
                  "count": 1,
                  "engagement": 0
              },
              {
                  "author_id": "fb_Citysearchhk",
                  "author_name": "城市大搜查 Citysearch",
                  "author_image": "https://scontent.fhkg1-1.fna.fbcdn.net/v/t1.6435-1/167838983_525814798816119_8688753341788979222_n.jpg?stp=cp0_dst-jpg_p40x40&_nc_cat=111&ccb=1-7&_nc_sid=7206a8&_nc_ohc=S7qeATU7qA0AX9AnuKT&_nc_ht=scontent.fhkg1-1.fna&oh=00_AT_-wyMHMGeKqZ5fgGbE8OdhIvk27XHY-0_r-ePXzwhAAw&oe=6336C7BF",
                  "site": "Facebook Group",
                  "count": 2,
                  "engagement": 0
              },
              {
                  "author_id": "fb_111059377201732",
                  "author_name": "拾玖傳媒 NineTeen Media",
                  "author_image": "https://graph.facebook.com/111059377201732/picture?type=square",
                  "site": "Facebook Page",
                  "count": 1,
                  "engagement": 0
              },
              {
                  "author_id": "linetoday_df7950a2",
                  "author_name": "BossMind",
                  "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                  "site": "LineToday",
                  "count": 1,
                  "engagement": 0
              },
              {
                  "author_id": "oncc_oncc",
                  "author_name": "oncc",
                  "site": "Oncc",
                  "count": 3,
                  "engagement": 0
              },
              {
                  "author_id": "bastille_bastille",
                  "author_name": "bastille",
                  "site": "Bastille",
                  "count": 2,
                  "engagement": 0
              },
              {
                  "author_id": "hk01_6c9e2e97",
                  "author_name": "郭顥添",
                  "site": "HK01",
                  "count": 1,
                  "engagement": 0
              },
              {
                  "author_id": "hkdiscuss_3022564",
                  "author_name": "carters",
                  "author_image": "https://finance.discuss.com.hk/images/avatars/noavatar2.png",
                  "site": "HKDiscuss",
                  "count": 1,
                  "engagement": 0
              },
              {
                  "author_id": "linetoday_3fdf6465",
                  "author_name": "獨立媒體 inmediahk.net",
                  "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                  "site": "LineToday",
                  "count": 1,
                  "engagement": 0
              },
              {
                  "author_id": "linetoday_56c1b707",
                  "author_name": "on.cc 東網",
                  "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                  "site": "LineToday",
                  "count": 2,
                  "engagement": 0
              },
              {
                  "author_id": "linetoday_eaa09024",
                  "author_name": "星島日報",
                  "author_image": "https://today.line.me/dist/a654f2c3/static/meta/icon192.png",
                  "site": "LineToday",
                  "count": 1,
                  "engagement": 0
              },
              {
                  "author_id": "singtao_singtao",
                  "author_name": "singtao",
                  "site": "SingTao",
                  "count": 2,
                  "engagement": 0
              },
              {
                  "author_id": "topick_topick",
                  "author_name": "topick",
                  "site": "TOPick",
                  "count": 3,
                  "engagement": 0
              }
          ]
      },
      "top_threads": {
          "total": {
              "comments": [
                  {
                      "comment_count": 143,
                      "site": "LIHKG",
                      "thread_link": "https://lihkg.com/thread/3130138",
                      "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日"
                  },
                  {
                      "comment_count": 91,
                      "site": "LIHKG",
                      "thread_link": "https://lihkg.com/thread/3130307",
                      "thread_title": "兩日乘同一巴士非禮同一女乘客　24歲中国專才認罪囚21日"
                  },
                  {
                      "comment_count": 75,
                      "site": "HKDiscuss",
                      "thread_link": "https://www.discuss.com.hk/viewthread.php?tid=30743949",
                      "thread_title": "有違操守｜地產代理「食價」欺詐買家多付1,500萬 終判監6年半"
                  },
                  {
                      "comment_count": 54,
                      "site": "Facebook Page",
                      "thread_link": "https://www.facebook.com/pfbid02eTQxMxtyJJLV4scvbs1zszE9FtvrDGLgUQb6FiUZKNGAcvVhVF9R1n4dMU4yDtfAl",
                      "thread_title": "【有違操守】一名今年35歲的地產代理公司男董事，於2014年..."
                  },
                  {
                      "comment_count": 42,
                      "site": "Golden",
                      "thread_link": "https://forum.hkgolden.com/thread/7604325",
                      "thread_title": "被告: 個女仔著短裙好吸引，控制唔到自己"
                  },
                  {
                      "comment_count": 20,
                      "site": "LIHKG",
                      "thread_link": "https://lihkg.com/thread/3130238",
                      "thread_title": "助夫婦洽購單位「食夾棍 」逾1千萬 地產公司男董事判囚6年半"
                  },
                  {
                      "comment_count": 19,
                      "site": "LIHKG",
                      "thread_link": "https://lihkg.com/thread/3130680",
                      "thread_title": "2021 年剷屯門巴士站撞傷 4 人　2 人永久傷殘　寶馬司機危駕罪成還押 9.15 判刑"
                  },
                  {
                      "comment_count": 5,
                      "site": "LineToday",
                      "thread_link": "https://today.line.me/hk/v2/article/vXzYlJE",
                      "thread_title": "失蹤近2年後落網　青年認2019年「6.12」金鐘非法集結"
                  },
                  {
                      "comment_count": 3,
                      "site": "Facebook Page",
                      "thread_link": "https://www.facebook.com/pfbid02axjy8TPT8oqXnwK1WgCi2hfiBsMK2FE6eQZ1Wckjrzij9cLnw3DY3pjUGsz9Ak6l",
                      "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日24..."
                  },
                  {
                      "comment_count": 2,
                      "site": "LineToday",
                      "thread_link": "https://today.line.me/hk/v2/article/YanB3wp",
                      "thread_title": "有違操守｜地產代理「食價」欺詐買家多付1,500萬 終判監6年半"
                  },
                  {
                      "comment_count": 0,
                      "site": "Facebook Page",
                      "thread_link": "https://www.facebook.com/pfbid02meABi7Kr7knCVhZvsbtTXc4US3aDzrnPuosLauCryUZeYyZXW7cR6JisHNGJV84Cl",
                      "thread_title": "【 8月22日香港人要知道的事 — 法庭篇】【四男兩女被告事..."
                  },
                  {
                      "comment_count": 0,
                      "site": "LineToday",
                      "thread_link": "https://today.line.me/hk/v2/article/QwEmGgV",
                      "thread_title": "地產代理售兩物業 「食價」1500萬囚6年半"
                  },
                  {
                      "comment_count": 0,
                      "site": "LineToday",
                      "thread_link": "https://today.line.me/hk/v2/article/ZayJyeQ",
                      "thread_title": "青年認6.12非法集結及藏大麻 須待另一案終極上訴結果後判刑 辯方憂「坐凸咗」"
                  },
                  {
                      "comment_count": 0,
                      "site": "Headline",
                      "thread_link": "https://hd.stheadline.com/news/realtime/hk/2368933",
                      "thread_title": "修例風波｜19歲男生涉金鐘非法集結潛逃近2年 案件押至明年再訊"
                  },
                  {
                      "comment_count": 0,
                      "site": "SingTao",
                      "thread_link": "https://std.stheadline.com/realtime/article/1868933",
                      "thread_title": "修例風波｜19歲男生涉金鐘非法集結潛逃近2年 案件押至明年再訊"
                  },
                  {
                      "comment_count": 0,
                      "site": "Headline",
                      "thread_link": "https://hd.stheadline.com/news/realtime/hk/2368905",
                      "thread_title": "助夫婦洽購單位「食夾棍 」逾1千萬 地產公司男董事判囚6年半"
                  },
                  {
                      "comment_count": 0,
                      "site": "HK01",
                      "thread_link": "https://www.hk01.com/article/809926",
                      "thread_title": "測量員乘巴士兩非禮短裙女　手放大腿用手背觸碰女方肌膚　囚21日"
                  },
                  {
                      "comment_count": 0,
                      "site": "Facebook Page",
                      "thread_link": "https://www.facebook.com/pfbid0E9WePWXbdvRfmVuKf8L2MPrcHg1Sc9ZpAsk9hpTFiZKgKtjVVKxLemM1ghEwKYxcl",
                      "thread_title": "蟻先生欺詐罪成。---【欺詐判囚】涉欺詐買家1500萬兩欺詐..."
                  },
                  {
                      "comment_count": 0,
                      "site": "Facebook Group",
                      "thread_link": "https://www.facebook.com/1742354172782072",
                      "thread_title": "【庭外襲警亂吐口水 搞事男判囚10周】黎智英及一眾政客因「8..."
                  },
                  {
                      "comment_count": 0,
                      "site": "SingTao",
                      "thread_link": "https://std.stheadline.com/realtime/article/1868840",
                      "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日"
                  },
                  {
                      "comment_count": 0,
                      "site": "Headline",
                      "thread_link": "https://hd.stheadline.com/news/realtime/hk/2368840",
                      "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日"
                  },
                  {
                      "comment_count": 0,
                      "site": "LineToday",
                      "thread_link": "https://today.line.me/hk/v2/article/7NjpBOl",
                      "thread_title": "秘購物業轉售買家食價　地產代理公司東主囚78個月"
                  },
                  {
                      "comment_count": 0,
                      "site": "Facebook Group",
                      "thread_link": "https://www.facebook.com/5579692415387411",
                      "thread_title": "【庭外襲警亂吐口水 搞事男判囚10周】\n黎智英及一眾政客因「..."
                  }
              ],
              "engagement": [
                  {
                      "site": "Facebook Page",
                      "thread_link": "https://www.facebook.com/pfbid02eTQxMxtyJJLV4scvbs1zszE9FtvrDGLgUQb6FiUZKNGAcvVhVF9R1n4dMU4yDtfAl",
                      "thread_title": "【有違操守】一名今年35歲的地產代理公司男董事，於2014年...",
                      "engagement": 504
                  },
                  {
                      "site": "LIHKG",
                      "thread_link": "https://lihkg.com/thread/3130138",
                      "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日",
                      "engagement": 115
                  },
                  {
                      "site": "LIHKG",
                      "thread_link": "https://lihkg.com/thread/3130307",
                      "thread_title": "兩日乘同一巴士非禮同一女乘客　24歲中国專才認罪囚21日",
                      "engagement": 101
                  },
                  {
                      "site": "Headline",
                      "thread_link": "https://hd.stheadline.com/news/realtime/hk/2368840",
                      "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日",
                      "engagement": 18
                  },
                  {
                      "site": "Golden",
                      "thread_link": "https://forum.hkgolden.com/thread/7604325",
                      "thread_title": "被告: 個女仔著短裙好吸引，控制唔到自己",
                      "engagement": 12
                  },
                  {
                      "site": "Facebook Page",
                      "thread_link": "https://www.facebook.com/pfbid02axjy8TPT8oqXnwK1WgCi2hfiBsMK2FE6eQZ1Wckjrzij9cLnw3DY3pjUGsz9Ak6l",
                      "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日24...",
                      "engagement": 12
                  },
                  {
                      "site": "LIHKG",
                      "thread_link": "https://lihkg.com/thread/3130238",
                      "thread_title": "助夫婦洽購單位「食夾棍 」逾1千萬 地產公司男董事判囚6年半",
                      "engagement": 8
                  },
                  {
                      "site": "LIHKG",
                      "thread_link": "https://lihkg.com/thread/3130680",
                      "thread_title": "2021 年剷屯門巴士站撞傷 4 人　2 人永久傷殘　寶馬司機危駕罪成還押 9.15 判刑",
                      "engagement": 8
                  },
                  {
                      "site": "Facebook Group",
                      "thread_link": "https://www.facebook.com/1742354172782072",
                      "thread_title": "【庭外襲警亂吐口水 搞事男判囚10周】黎智英及一眾政客因「8...",
                      "engagement": 7
                  },
                  {
                      "site": "Facebook Page",
                      "thread_link": "https://www.facebook.com/pfbid0E9WePWXbdvRfmVuKf8L2MPrcHg1Sc9ZpAsk9hpTFiZKgKtjVVKxLemM1ghEwKYxcl",
                      "thread_title": "蟻先生欺詐罪成。---【欺詐判囚】涉欺詐買家1500萬兩欺詐...",
                      "engagement": 4
                  },
                  {
                      "site": "Facebook Page",
                      "thread_link": "https://www.facebook.com/pfbid02meABi7Kr7knCVhZvsbtTXc4US3aDzrnPuosLauCryUZeYyZXW7cR6JisHNGJV84Cl",
                      "thread_title": "【 8月22日香港人要知道的事 — 法庭篇】【四男兩女被告事...",
                      "engagement": 2
                  },
                  {
                      "site": "Oncc",
                      "thread_link": "https://hk.on.cc/cnt/news/20220831/bkn-20220831162728751-0831_00822_001.html",
                      "thread_title": "失蹤近2年後落網　青年認2019年「6.12」金鐘非法集結",
                      "engagement": 1
                  },
                  {
                      "site": "LineToday",
                      "thread_link": "https://today.line.me/hk/v2/article/YanB3wp",
                      "thread_title": "有違操守｜地產代理「食價」欺詐買家多付1,500萬 終判監6年半",
                      "engagement": 1
                  },
                  {
                      "site": "Headline",
                      "thread_link": "https://hd.stheadline.com/news/realtime/hk/2368905",
                      "thread_title": "助夫婦洽購單位「食夾棍 」逾1千萬 地產公司男董事判囚6年半",
                      "engagement": 0
                  },
                  {
                      "site": "Headline",
                      "thread_link": "https://hd.stheadline.com/news/realtime/hk/2368933",
                      "thread_title": "修例風波｜19歲男生涉金鐘非法集結潛逃近2年 案件押至明年再訊",
                      "engagement": 0
                  },
                  {
                      "site": "Oncc",
                      "thread_link": "https://hk.on.cc/cnt/news/20220831/bkn-20220831131840309-0831_00822_001.html",
                      "thread_title": "秘購物業轉售買家食價　地產代理公司東主囚78個月",
                      "engagement": 0
                  },
                  {
                      "site": "Oncc",
                      "thread_link": "https://hk.on.cc/cnt/news/20220831/bkn-20220831132535211-0831_00822_001.html",
                      "thread_title": "4天內兩度非禮短裙女乘客　男測量員認2項非禮罪判囚21日",
                      "engagement": 0
                  },
                  {
                      "site": "SingTao",
                      "thread_link": "https://std.stheadline.com/realtime/article/1868840",
                      "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日",
                      "engagement": 0
                  },
                  {
                      "site": "SingTao",
                      "thread_link": "https://std.stheadline.com/realtime/article/1868933",
                      "thread_title": "修例風波｜19歲男生涉金鐘非法集結潛逃近2年 案件押至明年再訊",
                      "engagement": 0
                  },
                  {
                      "site": "LineToday",
                      "thread_link": "https://today.line.me/hk/v2/article/7NjpBOl",
                      "thread_title": "秘購物業轉售買家食價　地產代理公司東主囚78個月",
                      "engagement": 0
                  },
                  {
                      "site": "LineToday",
                      "thread_link": "https://today.line.me/hk/v2/article/QwEmGgV",
                      "thread_title": "地產代理售兩物業 「食價」1500萬囚6年半",
                      "engagement": 0
                  },
                  {
                      "site": "LineToday",
                      "thread_link": "https://today.line.me/hk/v2/article/ZayJyeQ",
                      "thread_title": "青年認6.12非法集結及藏大麻 須待另一案終極上訴結果後判刑 辯方憂「坐凸咗」",
                      "engagement": 0
                  },
                  {
                      "site": "LineToday",
                      "thread_link": "https://today.line.me/hk/v2/article/vXzYlJE",
                      "thread_title": "失蹤近2年後落網　青年認2019年「6.12」金鐘非法集結",
                      "engagement": 0
                  },
                  {
                      "site": "TOPick",
                      "thread_link": "https://topick.hket.com/article/3342491",
                      "thread_title": "【欺詐判囚】涉欺詐買家1500萬兩欺詐罪成　地產代理判囚6年半官斥有計劃行事",
                      "engagement": 0
                  },
                  {
                      "site": "TOPick",
                      "thread_link": "https://topick.hket.com/article/3342705",
                      "thread_title": "【巴士非禮】涉4天內巴士上兩度非禮同一女生　24歲測量員認罪囚21天",
                      "engagement": 0
                  },
                  {
                      "site": "TOPick",
                      "thread_link": "https://topick.hket.com/article/3342852",
                      "thread_title": "【罪成候判】涉6.12包圍立法會　19歲男生潛逃兩年認非法集結候判",
                      "engagement": 0
                  },
                  {
                      "site": "Bastille",
                      "thread_link": "https://www.bastillepost.com/hongkong/article/11248743",
                      "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日",
                      "engagement": 0
                  },
                  {
                      "site": "Bastille",
                      "thread_link": "https://www.bastillepost.com/hongkong/article/11249938",
                      "thread_title": "涉修例風波期間於金鐘非法集結 19歲男潛逃近2年被捕押明年訊",
                      "engagement": 0
                  },
                  {
                      "site": "HKDiscuss",
                      "thread_link": "https://www.discuss.com.hk/viewthread.php?tid=30743949",
                      "thread_title": "有違操守｜地產代理「食價」欺詐買家多付1,500萬 終判監6年半",
                      "engagement": 0
                  },
                  {
                      "site": "Facebook Group",
                      "thread_link": "https://www.facebook.com/5579692415387411",
                      "thread_title": "【庭外襲警亂吐口水 搞事男判囚10周】\n黎智英及一眾政客因「...",
                      "engagement": 0
                  },
                  {
                      "site": "HK01",
                      "thread_link": "https://www.hk01.com/article/809926",
                      "thread_title": "測量員乘巴士兩非禮短裙女　手放大腿用手背觸碰女方肌膚　囚21日",
                      "engagement": 0
                  }
              ]
          },
          "site": {
              "Bastille": {
                  "comments": null,
                  "engagement": [
                      {
                          "site": "Bastille",
                          "thread_link": "https://www.bastillepost.com/hongkong/article/11248743",
                          "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日",
                          "engagement": 0
                      },
                      {
                          "site": "Bastille",
                          "thread_link": "https://www.bastillepost.com/hongkong/article/11249938",
                          "thread_title": "涉修例風波期間於金鐘非法集結 19歲男潛逃近2年被捕押明年訊",
                          "engagement": 0
                      }
                  ]
              },
              "Facebook Group": {
                  "comments": [
                      {
                          "comment_count": 0,
                          "site": "Facebook Group",
                          "thread_link": "https://www.facebook.com/1742354172782072",
                          "thread_title": "【庭外襲警亂吐口水 搞事男判囚10周】黎智英及一眾政客因「8..."
                      },
                      {
                          "comment_count": 0,
                          "site": "Facebook Group",
                          "thread_link": "https://www.facebook.com/5579692415387411",
                          "thread_title": "【庭外襲警亂吐口水 搞事男判囚10周】\n黎智英及一眾政客因「..."
                      }
                  ],
                  "engagement": [
                      {
                          "site": "Facebook Group",
                          "thread_link": "https://www.facebook.com/1742354172782072",
                          "thread_title": "【庭外襲警亂吐口水 搞事男判囚10周】黎智英及一眾政客因「8...",
                          "engagement": 7
                      },
                      {
                          "site": "Facebook Group",
                          "thread_link": "https://www.facebook.com/5579692415387411",
                          "thread_title": "【庭外襲警亂吐口水 搞事男判囚10周】\n黎智英及一眾政客因「...",
                          "engagement": 0
                      }
                  ]
              },
              "Facebook Page": {
                  "comments": [
                      {
                          "comment_count": 54,
                          "site": "Facebook Page",
                          "thread_link": "https://www.facebook.com/pfbid02eTQxMxtyJJLV4scvbs1zszE9FtvrDGLgUQb6FiUZKNGAcvVhVF9R1n4dMU4yDtfAl",
                          "thread_title": "【有違操守】一名今年35歲的地產代理公司男董事，於2014年..."
                      },
                      {
                          "comment_count": 3,
                          "site": "Facebook Page",
                          "thread_link": "https://www.facebook.com/pfbid02axjy8TPT8oqXnwK1WgCi2hfiBsMK2FE6eQZ1Wckjrzij9cLnw3DY3pjUGsz9Ak6l",
                          "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日24..."
                      },
                      {
                          "comment_count": 0,
                          "site": "Facebook Page",
                          "thread_link": "https://www.facebook.com/pfbid02meABi7Kr7knCVhZvsbtTXc4US3aDzrnPuosLauCryUZeYyZXW7cR6JisHNGJV84Cl",
                          "thread_title": "【 8月22日香港人要知道的事 — 法庭篇】【四男兩女被告事..."
                      },
                      {
                          "comment_count": 0,
                          "site": "Facebook Page",
                          "thread_link": "https://www.facebook.com/pfbid0E9WePWXbdvRfmVuKf8L2MPrcHg1Sc9ZpAsk9hpTFiZKgKtjVVKxLemM1ghEwKYxcl",
                          "thread_title": "蟻先生欺詐罪成。---【欺詐判囚】涉欺詐買家1500萬兩欺詐..."
                      }
                  ],
                  "engagement": [
                      {
                          "site": "Facebook Page",
                          "thread_link": "https://www.facebook.com/pfbid02eTQxMxtyJJLV4scvbs1zszE9FtvrDGLgUQb6FiUZKNGAcvVhVF9R1n4dMU4yDtfAl",
                          "thread_title": "【有違操守】一名今年35歲的地產代理公司男董事，於2014年...",
                          "engagement": 504
                      },
                      {
                          "site": "Facebook Page",
                          "thread_link": "https://www.facebook.com/pfbid02axjy8TPT8oqXnwK1WgCi2hfiBsMK2FE6eQZ1Wckjrzij9cLnw3DY3pjUGsz9Ak6l",
                          "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日24...",
                          "engagement": 12
                      },
                      {
                          "site": "Facebook Page",
                          "thread_link": "https://www.facebook.com/pfbid0E9WePWXbdvRfmVuKf8L2MPrcHg1Sc9ZpAsk9hpTFiZKgKtjVVKxLemM1ghEwKYxcl",
                          "thread_title": "蟻先生欺詐罪成。---【欺詐判囚】涉欺詐買家1500萬兩欺詐...",
                          "engagement": 4
                      },
                      {
                          "site": "Facebook Page",
                          "thread_link": "https://www.facebook.com/pfbid02meABi7Kr7knCVhZvsbtTXc4US3aDzrnPuosLauCryUZeYyZXW7cR6JisHNGJV84Cl",
                          "thread_title": "【 8月22日香港人要知道的事 — 法庭篇】【四男兩女被告事...",
                          "engagement": 2
                      }
                  ]
              },
              "Golden": {
                  "comments": [
                      {
                          "comment_count": 42,
                          "site": "Golden",
                          "thread_link": "https://forum.hkgolden.com/thread/7604325",
                          "thread_title": "被告: 個女仔著短裙好吸引，控制唔到自己"
                      }
                  ],
                  "engagement": [
                      {
                          "site": "Golden",
                          "thread_link": "https://forum.hkgolden.com/thread/7604325",
                          "thread_title": "被告: 個女仔著短裙好吸引，控制唔到自己",
                          "engagement": 12
                      }
                  ]
              },
              "Headline": {
                  "comments": [
                      {
                          "comment_count": 0,
                          "site": "Headline",
                          "thread_link": "https://hd.stheadline.com/news/realtime/hk/2368933",
                          "thread_title": "修例風波｜19歲男生涉金鐘非法集結潛逃近2年 案件押至明年再訊"
                      },
                      {
                          "comment_count": 0,
                          "site": "Headline",
                          "thread_link": "https://hd.stheadline.com/news/realtime/hk/2368905",
                          "thread_title": "助夫婦洽購單位「食夾棍 」逾1千萬 地產公司男董事判囚6年半"
                      },
                      {
                          "comment_count": 0,
                          "site": "Headline",
                          "thread_link": "https://hd.stheadline.com/news/realtime/hk/2368840",
                          "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日"
                      }
                  ],
                  "engagement": [
                      {
                          "site": "Headline",
                          "thread_link": "https://hd.stheadline.com/news/realtime/hk/2368840",
                          "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日",
                          "engagement": 18
                      },
                      {
                          "site": "Headline",
                          "thread_link": "https://hd.stheadline.com/news/realtime/hk/2368905",
                          "thread_title": "助夫婦洽購單位「食夾棍 」逾1千萬 地產公司男董事判囚6年半",
                          "engagement": 0
                      },
                      {
                          "site": "Headline",
                          "thread_link": "https://hd.stheadline.com/news/realtime/hk/2368933",
                          "thread_title": "修例風波｜19歲男生涉金鐘非法集結潛逃近2年 案件押至明年再訊",
                          "engagement": 0
                      }
                  ]
              },
              "LIHKG": {
                  "comments": [
                      {
                          "comment_count": 143,
                          "site": "LIHKG",
                          "thread_link": "https://lihkg.com/thread/3130138",
                          "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日"
                      },
                      {
                          "comment_count": 91,
                          "site": "LIHKG",
                          "thread_link": "https://lihkg.com/thread/3130307",
                          "thread_title": "兩日乘同一巴士非禮同一女乘客　24歲中国專才認罪囚21日"
                      },
                      {
                          "comment_count": 20,
                          "site": "LIHKG",
                          "thread_link": "https://lihkg.com/thread/3130238",
                          "thread_title": "助夫婦洽購單位「食夾棍 」逾1千萬 地產公司男董事判囚6年半"
                      },
                      {
                          "comment_count": 19,
                          "site": "LIHKG",
                          "thread_link": "https://lihkg.com/thread/3130680",
                          "thread_title": "2021 年剷屯門巴士站撞傷 4 人　2 人永久傷殘　寶馬司機危駕罪成還押 9.15 判刑"
                      }
                  ],
                  "engagement": [
                      {
                          "site": "LIHKG",
                          "thread_link": "https://lihkg.com/thread/3130138",
                          "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日",
                          "engagement": 115
                      },
                      {
                          "site": "LIHKG",
                          "thread_link": "https://lihkg.com/thread/3130307",
                          "thread_title": "兩日乘同一巴士非禮同一女乘客　24歲中国專才認罪囚21日",
                          "engagement": 101
                      },
                      {
                          "site": "LIHKG",
                          "thread_link": "https://lihkg.com/thread/3130238",
                          "thread_title": "助夫婦洽購單位「食夾棍 」逾1千萬 地產公司男董事判囚6年半",
                          "engagement": 8
                      },
                      {
                          "site": "LIHKG",
                          "thread_link": "https://lihkg.com/thread/3130680",
                          "thread_title": "2021 年剷屯門巴士站撞傷 4 人　2 人永久傷殘　寶馬司機危駕罪成還押 9.15 判刑",
                          "engagement": 8
                      }
                  ]
              },
              "LineToday": {
                  "comments": [
                      {
                          "comment_count": 5,
                          "site": "LineToday",
                          "thread_link": "https://today.line.me/hk/v2/article/vXzYlJE",
                          "thread_title": "失蹤近2年後落網　青年認2019年「6.12」金鐘非法集結"
                      },
                      {
                          "comment_count": 2,
                          "site": "LineToday",
                          "thread_link": "https://today.line.me/hk/v2/article/YanB3wp",
                          "thread_title": "有違操守｜地產代理「食價」欺詐買家多付1,500萬 終判監6年半"
                      },
                      {
                          "comment_count": 0,
                          "site": "LineToday",
                          "thread_link": "https://today.line.me/hk/v2/article/QwEmGgV",
                          "thread_title": "地產代理售兩物業 「食價」1500萬囚6年半"
                      },
                      {
                          "comment_count": 0,
                          "site": "LineToday",
                          "thread_link": "https://today.line.me/hk/v2/article/ZayJyeQ",
                          "thread_title": "青年認6.12非法集結及藏大麻 須待另一案終極上訴結果後判刑 辯方憂「坐凸咗」"
                      },
                      {
                          "comment_count": 0,
                          "site": "LineToday",
                          "thread_link": "https://today.line.me/hk/v2/article/7NjpBOl",
                          "thread_title": "秘購物業轉售買家食價　地產代理公司東主囚78個月"
                      }
                  ],
                  "engagement": [
                      {
                          "site": "LineToday",
                          "thread_link": "https://today.line.me/hk/v2/article/YanB3wp",
                          "thread_title": "有違操守｜地產代理「食價」欺詐買家多付1,500萬 終判監6年半",
                          "engagement": 1
                      },
                      {
                          "site": "LineToday",
                          "thread_link": "https://today.line.me/hk/v2/article/7NjpBOl",
                          "thread_title": "秘購物業轉售買家食價　地產代理公司東主囚78個月",
                          "engagement": 0
                      },
                      {
                          "site": "LineToday",
                          "thread_link": "https://today.line.me/hk/v2/article/QwEmGgV",
                          "thread_title": "地產代理售兩物業 「食價」1500萬囚6年半",
                          "engagement": 0
                      },
                      {
                          "site": "LineToday",
                          "thread_link": "https://today.line.me/hk/v2/article/ZayJyeQ",
                          "thread_title": "青年認6.12非法集結及藏大麻 須待另一案終極上訴結果後判刑 辯方憂「坐凸咗」",
                          "engagement": 0
                      },
                      {
                          "site": "LineToday",
                          "thread_link": "https://today.line.me/hk/v2/article/vXzYlJE",
                          "thread_title": "失蹤近2年後落網　青年認2019年「6.12」金鐘非法集結",
                          "engagement": 0
                      }
                  ]
              },
              "Oncc": {
                  "comments": null,
                  "engagement": [
                      {
                          "site": "Oncc",
                          "thread_link": "https://hk.on.cc/cnt/news/20220831/bkn-20220831162728751-0831_00822_001.html",
                          "thread_title": "失蹤近2年後落網　青年認2019年「6.12」金鐘非法集結",
                          "engagement": 1
                      },
                      {
                          "site": "Oncc",
                          "thread_link": "https://hk.on.cc/cnt/news/20220831/bkn-20220831131840309-0831_00822_001.html",
                          "thread_title": "秘購物業轉售買家食價　地產代理公司東主囚78個月",
                          "engagement": 0
                      },
                      {
                          "site": "Oncc",
                          "thread_link": "https://hk.on.cc/cnt/news/20220831/bkn-20220831132535211-0831_00822_001.html",
                          "thread_title": "4天內兩度非禮短裙女乘客　男測量員認2項非禮罪判囚21日",
                          "engagement": 0
                      }
                  ]
              },
              "SingTao": {
                  "comments": [
                      {
                          "comment_count": 0,
                          "site": "SingTao",
                          "thread_link": "https://std.stheadline.com/realtime/article/1868933",
                          "thread_title": "修例風波｜19歲男生涉金鐘非法集結潛逃近2年 案件押至明年再訊"
                      },
                      {
                          "comment_count": 0,
                          "site": "SingTao",
                          "thread_link": "https://std.stheadline.com/realtime/article/1868840",
                          "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日"
                      }
                  ],
                  "engagement": [
                      {
                          "site": "SingTao",
                          "thread_link": "https://std.stheadline.com/realtime/article/1868840",
                          "thread_title": "4日內兩度非禮同一巴士女乘客 24歲測量員認罪判囚21日",
                          "engagement": 0
                      },
                      {
                          "site": "SingTao",
                          "thread_link": "https://std.stheadline.com/realtime/article/1868933",
                          "thread_title": "修例風波｜19歲男生涉金鐘非法集結潛逃近2年 案件押至明年再訊",
                          "engagement": 0
                      }
                  ]
              },
              "TOPick": {
                  "comments": null,
                  "engagement": [
                      {
                          "site": "TOPick",
                          "thread_link": "https://topick.hket.com/article/3342491",
                          "thread_title": "【欺詐判囚】涉欺詐買家1500萬兩欺詐罪成　地產代理判囚6年半官斥有計劃行事",
                          "engagement": 0
                      },
                      {
                          "site": "TOPick",
                          "thread_link": "https://topick.hket.com/article/3342705",
                          "thread_title": "【巴士非禮】涉4天內巴士上兩度非禮同一女生　24歲測量員認罪囚21天",
                          "engagement": 0
                      },
                      {
                          "site": "TOPick",
                          "thread_link": "https://topick.hket.com/article/3342852",
                          "thread_title": "【罪成候判】涉6.12包圍立法會　19歲男生潛逃兩年認非法集結候判",
                          "engagement": 0
                      }
                  ]
              }
          }
      },
      "summary": "",
      "preview_threads": [
          null,
          null,
          null,
          null,
          null
      ]
  },
  "ai_results": {
      "sentiment": {
          "-1": 8
      },
      "impact": {
          "0": 8
      }
  },
  "summary": "被告林楚健(24歲，測量員)，承認2項非禮罪，指他於2022年8月26及29日，在58M巴士上，駛經荃灣眾安街及荃景圍近天橋時，非禮女子X。 兩人分別穿短裙及短褲 案情指，被告與女事主X並不相識，26日早上7時許，X與被告乘坐同一輛58M巴士，被告坐在X的右手邊。巴士駛至眾安街時，穿著短裙的X感覺她的右大腿碰到穿短褲的被告的左大腿，她便移開一點，但被告再移近她，被告並將左手放在左大腿外側，並用左手背觸碰X的右大腿，歷時約10分鐘，X感到驚慌離開。 第二次被非禮認出是同一人所為 同月29日，事主X再乘坐同一路線巴士時，又遇上被告，同樣的事再次發生，被告再以手背觸碰X的大腿肌膚，歷時約1分鐘，X認得被告就是數天前以同樣手法觸碰她的人，便將事件告知車長及報警。被告在警誡下稱：「我認罪，我控制唔到自己。」 求情稱剛入職場壓力大 辯方律師求情時指，被告剛在廣州畢業回來，踏入職場約1個月，精神壓力大才會犯案。裁判官指，本案案情絕不輕微，被告2次直接接觸到X的皮膚，X已主動躲開，但被告仍進一步行動，認為須判處監禁式刑罰，考慮被告認罪等，判他入獄21天。 案件編號：WKCC3092/2022"
}

function TrendOverview(props: TrendOverviewProp) {
  const topicId = props.match.params.topic
  const reqFrequency = parseQueryString(props.location.search).f
  const reqIndustry = parseQueryString(props.location.search).label
  const [allTopicData, setAllTopicData] = useState<any>([])

  const { 
    frequency,
    setFrequency,
    setFrequencyLabel,
    // setIsLoadingOverview,
    setOverviewPost,
    // labelId,
    // setLabelId,
    // isOpen,
    // toggleOpen,
  } = usePostsStore(state => ({
    frequency: state.frequency,
    setFrequency: state.setFrequency,
    setFrequencyLabel: state.setFrequencyLabel,
    // setIsLoadingOverview: state.setIsLoadingOverview, 
    setOverviewPost: state.setOverviewPost,
    // labelId: state.labelId,
    // setLabelId: state.setLabelId,
    // isOpen: state.isOpen,
    // toggleOpen: state.toggleOpen,
  }))

  // const {isFetching, isLoading, data: ovData} = useQuery(['fetchTrendOvData', topicId, frequency], fetchTrendOvData, {
  //   refetchOnWindowFocus: false
  // })

  // const {data: accessRightsWithFreqDetails} = useQuery<TrendAccess | null>(['/api/users/trendaccess'], destructuredQueryFn)

  // const frequencyLabels = labelId && accessRightsWithFreqDetails && (
  //   timeFrameSelections.map(item => (
  //     {
  //       ...item,
  //       disabled: !accessRightsWithFreqDetails[labelId.toString()]?.freq.includes(item.value) ?? true
  //     }
  //   )).filter(item => item.show)
  // )

  // useEffect(()=>{
  //   (isFetching || isLoading) ? 
  //     setIsLoadingOverview(isFetching || isLoading) : setIsLoadingOverview(false)
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[isFetching, isLoading])


//   useEffect(() => {
//     if(allTopicData){
//         const ovData = allTopicData.find(topic => topic?.briefData?.topic_id === topicId)
//         setOverviewPost(ovData?.detailData?.results?.Latest)
//     }
 
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [allTopicData])

  useEffect(() => {
    const mainDiv = document.getElementById('main-content')
    mainDiv && (mainDiv.scrollTop = 0)
    setFrequency(reqFrequency as Frequency)
    setFrequencyLabel(timeFrameSelections.find((selection)=>(selection.value === reqFrequency))!.title)
    // setLabelId(+reqIndustry)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const history = useHistory()

  useEffect(() => {
   let config = {
     method: 'get',
     // maxBodyLength: Infinity,
     url: `https://ask.lenx.app/get-trend-analysis-dashboard?trend_result_date=2024-5-15&frequency=${frequency}`,
     headers: {
       // Accept: 'text/event-stream',
       'Content-Type': 'application/json',
       // Authorization:
       //   'Bearer eyJhbGciOiJIUzI1NiIsImtpZCI6Ik84N0FJOVhOOENIVmwvQ3QiLCJ0eXAiOiJKV1QifQ.eyJhdWQiOiJhdXRoZW50aWNhdGVkIiwiZXhwIjoxNjk3NjExMzQ2LCJpYXQiOjE2OTc2MDc3NDYsImlzcyI6Imh0dHBzOi8vdHdrb2FhcG5peWdqcnlrYXJhbXcuc3VwYWJhc2UuY28vYXV0aC92MSIsInN1YiI6IjRkNmU1ZWU5LWExMjktNGMxZC04NjU0LWIxMzljMGFmNzhjOCIsImVtYWlsIjoibmlraXRhLm1pdHRhbEBmYXN0YS5haSIsInBob25lIjoiIiwiYXBwX21ldGFkYXRhIjp7InByb3ZpZGVyIjoiZW1haWwiLCJwcm92aWRlcnMiOlsiZW1haWwiXX0sInVzZXJfbWV0YWRhdGEiOnt9LCJyb2xlIjoiYXV0aGVudGljYXRlZCIsImFhbCI6ImFhbDEiLCJhbXIiOlt7Im1ldGhvZCI6InBhc3N3b3JkIiwidGltZXN0YW1wIjoxNjk3NTIyNTQzfV0sInNlc3Npb25faWQiOiIwNDkwMTY4YS01MDNiLTQxY2ItYWEwNi1iMmJhYmUxMGVkYTgifQ.7KUIhJk2_SO-URP1_qH5ElQ9YU4lbncTyhbd8unAVng',
     },
   };
    axios.request(config)
   .then((response:any) => {
     console.log("Response", response)
     if(response?.data?.length){
       setAllTopicData(response?.data)
       const ovData = response?.data.find((topic:any) => topic?.briefData?.topic_id === parseInt(topicId))
       console.log("ovData", ovData, topicId, response?.data)
       setOverviewPost(ovData?.detailData?.results?.[frequency])
     }
   })
 }, [frequency])

  return (
    <OverviewLayoutContainer>
      <ReactTooltip html={true} />
      {/* <button onClick={toggleOpen}>open</button> */}
      <RowContainer>
        <span>
          <span style={{cursor:'pointer'}} onClick={()=>history.goBack()}>
            <AngleLeft marginRight='10px' paddingTop='2px' />
            <span style={{textDecoration:'underline'}}>Back</span>
          </span>
           {` `} to Trend Results of 15 May 2024
        </span>
        {/* for previous page */}
        {/* {frequencyLabels && (
          <PreviousResultTimeframeSelector
            selections={frequencyLabels}
            selected={reqFrequency as Frequency}
            selectLabelAction={setFrequencyLabel}
            selectValueAction={setFrequency}
            id="trend-overview-timeframe-filter" 
            labelId={labelId!}
          />
        )} */}
      </RowContainer>
      <RowContainer>
        <span>View analysis for </span>
        <CurrentResultTimeframeSelectRow 
            selections={timeFrameSelections.filter(item => item.show)} 
            frequency={frequency} 
            selectLabelAction={setFrequencyLabel}
            selectValueAction={setFrequency}
            fromFrequency={reqFrequency}
        />
      </RowContainer>
      <TopicPicker />
      {/* <AIDashboard /> */}
      <StatCards />
      <RowContainer>
        <PostCountChart />
        <SentimentChart />
      </RowContainer>
      <RowContainer>
   
        <TopicSummary />
      </RowContainer>
      <LayoutGrid>
        <div>
          {/* <RelatedTopics /> */}
          <TopChannels />
          <TopAuthors />
        </div>
        <div>
          <TopThreads />
        </div>
      </LayoutGrid>
    </OverviewLayoutContainer>
  )
}

export default TrendOverview
